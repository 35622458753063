import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import { FilledStar } from 'components/icons'
import useAuth from 'hooks/auth'
import { SUBSCRIPTION } from 'routes'

const FreeTrial: React.FC = () => {
  const { user } = useAuth()

  const location = useLocation()

  const getFreeTrialDaysRemaining = () => {
    if (user && user.createdAt) {
      const startDateUTC = new Date(user.createdAt + 'Z')
      const endDateUTC = new Date(user.createdAt + 'Z')

      // Calculate end date of trial
      endDateUTC.setDate(startDateUTC.getDate() + 30)
      const todayUTC = new Date(new Date().toISOString().slice(0, 16) + 'Z')
      const diff = endDateUTC.getTime() - todayUTC.getTime()
      const differenceInDays = diff / 86400000 // 1000 * 60 * 60 * 24

      // Return only int part of differenceInDays
      return Math.trunc(differenceInDays)
    }
  }

  return (
    <div
      className={`absolute ${
        user && user.isOnFreeTrial && location.pathname !== SUBSCRIPTION ? 'visible' : 'invisible'
      } bg-alert rounded transition-all`}
      style={{
        bottom: 0,
        left: 0,
        right: 0,
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 'fit-content',
        padding: '8px 20px',
        border: '1px solid #f0e68c',
        zIndex: 2000
      }}
    >
      <FilledStar style={{ marginRight: '10px', height: '12px', width: '12px' }} />
      You have <b>{getFreeTrialDaysRemaining()} days left</b> of your free trial. To upgrade your account email{' '}
      <Link to={SUBSCRIPTION}>go to your subscriptions page</Link>
      <FilledStar style={{ marginLeft: '10px', height: '12px', width: '12px' }} />
    </div>
  )
}

export default FreeTrial
