import React, { useEffect, useState, useRef } from 'react'
import { useDataContext } from 'components/spreadsheet/contexts/data'
import useApplicationStore from 'hooks/application'
import useProject from 'hooks/project'
import { updateColumn } from 'components/spreadsheet/contexts/data/actions'
import Button from 'components/button'
import constants from 'style/constants.module.scss'
import { ISpreadsheetContextMenuState } from 'components/spreadsheet/types'
import { INITIAL_SPREADSHEET_CONTEXT_MENU_STATE } from 'components/spreadsheet/constants/const'
import { IFormulaHelper } from 'types'
import api from 'helpers/api'
import { AI } from 'components/icons'
import Flag from 'components/flag'

interface FormulaProps {
  columnPublicId: string
  formula: string | null
  setEditMenu: (value: ISpreadsheetContextMenuState) => void
}

const Formula: React.FC<FormulaProps> = ({ columnPublicId, formula, setEditMenu }) => {
  const { project } = useProject()
  const { spreadsheetData, setSpreadsheetData } = useDataContext()
  const { setSnackbarMessage, displayErrorMessage } = useApplicationStore()
  const [hoveredHelper, setHoveredHelper] = useState<IFormulaHelper | null>(null)
  const newFormulaInput = useRef<HTMLTextAreaElement>(null)
  const [formulaHelper, setFormulaHelper] = useState<IFormulaHelper[]>()
  const [search, setSearch] = useState<string>('')
  const [formulaHelperQuestion, setFormulaHelperQuestion] = useState<string>('')
  const [loadingFormulaSuggestion, setLoadingFormulaSuggestion] = useState<boolean>(false)

  const filteredFormulaHelper = formulaHelper
    ? formulaHelper.filter((helper) => helper.name.toLowerCase().includes(search.toLowerCase()))
    : []

  useEffect(() => {
    api
      .getFormulaHelper(spreadsheetData.viewDetails.publicId, columnPublicId)
      .then((response) => {
        setFormulaHelper(response.data)
      })
      .catch((error) => {
        displayErrorMessage(error)
      })
  }, [columnPublicId])

  const handleAiFormulaHelper = (question: string) => {
    setLoadingFormulaSuggestion(true)

    api
      .getAiFormulaHelper(spreadsheetData.viewDetails.publicId, columnPublicId, question)
      .then((response) => {
        const suggestedFormula = response.data.formula
        if (newFormulaInput.current) {
          newFormulaInput.current.value = suggestedFormula
        }
      })
      .catch((error) => {
        displayErrorMessage(error)
      })
      .finally(() => {
        setLoadingFormulaSuggestion(false)
      })
  }

  const handleFormulaChange = () => {
    if (newFormulaInput.current) {
      const currentValue = newFormulaInput.current.value
      updateColumn(
        project.publicId,
        'formula',
        spreadsheetData,
        setSpreadsheetData,
        columnPublicId,
        currentValue,
        () => {
          setSnackbarMessage({ status: 'success', message: 'The column was updated successfully!' })
          setEditMenu(INITIAL_SPREADSHEET_CONTEXT_MENU_STATE)
        },
        (error) => {
          displayErrorMessage(error)
        },
        {
          runScriptOnAllCells: true
        }
      )
    }
  }

  // Get unique categories from formula helper.
  // Sort the category with name "Fields" first.
  const formulaCategories = filteredFormulaHelper
    .reduce((acc: string[], helper) => {
      if (!acc.includes(helper.category)) {
        acc.push(helper.category)
      }
      return acc
    }, [])
    .sort((a, b) => (a === 'Fields' ? -1 : b === 'Fields' ? (a > b ? 1 : a < b ? -1 : 0) : 1))

  return (
    <div className="mb-4">
      <div
        className="flex flex-column"
        style={{ padding: '15px', backgroundColor: constants.aiBackground, gap: '20px' }}
      >
        <div className="flex font-bold items-center">
          <AI style={{ marginRight: '5px' }} /> Morta AI Powered Formula Helper
          <Flag text="Beta" color={constants.red} />
        </div>
        <textarea value={formulaHelperQuestion} onChange={(event) => setFormulaHelperQuestion(event.target.value)} />
        <Button
          internalType="gradient"
          style={{ width: '200px', padding: '10px', marginLeft: 'auto' }}
          onClick={() => handleAiFormulaHelper(formulaHelperQuestion)}
          disabled={loadingFormulaSuggestion || formulaHelperQuestion === ''}
          isLoading={loadingFormulaSuggestion}
        >
          <AI style={{ marginRight: '5px' }} />
          Ask Morta AI
        </Button>
      </div>

      <div className="mt-4" style={{ padding: '0px 15px 0px 15px' }}>
        <textarea
          ref={newFormulaInput}
          defaultValue={formula || ''}
          className="bg-light-grey"
          rows={5}
          style={{
            fontFamily: 'monospace',
            resize: 'vertical',
            marginBottom: 0,
            border: `1px solid ${constants.grey}`,
            borderRadius: '0px'
          }}
        />
      </div>

      <div className="flex items-center mb-4" style={{ padding: '0px 15px' }}>
        <Button
          internalType="accept"
          style={{ width: 'fit-content', marginLeft: 'auto', marginTop: '5px' }}
          onClick={handleFormulaChange}
          disabled={!newFormulaInput.current || newFormulaInput.current.value === ''}
        >
          Save Formula
        </Button>
      </div>

      {/* Fields and Functions */}
      <div style={{ padding: '0px 15px' }}>
        <div className="flex border-1px border-solid border-grey rounded" style={{ maxHeight: '302px' }}>
          <div
            className="flex flex-column overflow-y-auto"
            style={{
              padding: '15px',
              borderRight: `1px solid ${constants.grey}`,
              flex: '0 0 300px'
            }}
          >
            <input
              className="mb-4"
              type="text"
              placeholder="Search"
              onChange={(event) => setSearch(event.target.value)}
            />

            {formulaCategories.map((category, categoryIndex) => {
              // For the 'Fields' category, sort alphabetically (case-insensitive)
              const helpersForCategory = filteredFormulaHelper.filter((helper) => helper.category === category)
              const sortedHelpers =
                category === 'Fields'
                  ? helpersForCategory.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                  : helpersForCategory

              return (
                <div key={categoryIndex} className="mb-8">
                  <div className="text-sm w-full text-secondary text-left" style={{ marginBottom: '7px' }}>
                    {category}
                  </div>
                  {sortedHelpers.map((helper, helperIndex) => (
                    <div
                      key={helperIndex}
                      className="flex w-full items-center justify-start cursor-pointer hover-bg-light-grey transition-all rounded"
                      style={{ gap: '5px', padding: '5px' }}
                      onMouseEnter={() => setHoveredHelper(helper)}
                      onClick={() => {
                        const textarea = newFormulaInput.current
                        if (textarea) {
                          let insertText = helper.syntax
                          textarea.focus()
                          // If the current content is exactly "=EMPTY()", select it so the new text replaces it.
                          if (textarea.value === '=EMPTY()') {
                            // Remove a starting '=' if present.
                            textarea.select()
                          } else {
                            if (insertText.startsWith('=')) {
                              insertText = insertText.substring(1)
                            }
                          }
                          // Use the execCommand API to insert text so that undo history is preserved.
                          document.execCommand('insertText', false, insertText)
                        }
                      }}
                    >
                      <div className="text-sm truncate">{helper.name}</div>
                    </div>
                  ))}
                </div>
              )
            })}
          </div>

          {hoveredHelper && (
            <div
              className="overflow-y-auto"
              style={{ whiteSpace: 'normal', wordBreak: 'break-word', padding: '15px', width: '100%' }}
            >
              <div className="truncate whitespace-pre-wrap text-lg font-bold mb-4">{hoveredHelper.name}</div>
              <div className="truncate whitespace-pre-wrap text-sm">{hoveredHelper.description}</div>
              <div className="font-bold mt-8 mb-4">Syntax</div>
              <div
                className="whitespace-pre-wrap text-sm bg-really-light-grey rounded mb-4"
                style={{ padding: '10px', lineHeight: '1.7rem' }}
              >
                <pre>{hoveredHelper.syntax}</pre>
              </div>
              <div className="font-bold mt-8 mb-4">Examples</div>
              {hoveredHelper.examples.map((example, index) => (
                <pre
                  key={index}
                  className="whitespace-pre-wrap text-sm bg-really-light-grey rounded mb-4"
                  style={{ padding: '10px', lineHeight: '1.7rem' }}
                >
                  {example}
                </pre>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Formula
