import React, { useEffect, useState } from 'react'
import api, { APIError, ApiErrorCodes } from 'helpers/api'
import { ILogObject, OptionProps, IProjectMember } from 'types'
import { useApplicationStore } from 'hooks/application'
import { useProject } from 'hooks/project'
import { Table } from 'components/table'
import { Copy } from 'components/icons'
import { dateTime } from 'helpers/date'
import Button from 'components/button'
import Select from 'components/select'
import { AUDIT_RED_KEYWORDS, AUDIT_GREEN_KEYWORDS, PROJECT } from 'app-constants'
import constants from 'style/constants.module.scss'
import { capitaliseFirstLetter } from 'helpers/utils'
import { HUB_BASE, DOCUMENT_BASE } from 'routes'
import useAuth from 'hooks/auth'
import Subscription from 'components/subscription'

interface AuditProps {
  type?: string
  resourceId?: string
  searchTerm?: string
  verbs?: string[]
}

const Audit: React.FC<AuditProps> = ({ type, resourceId, searchTerm, verbs }: AuditProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [config, setConfig] = useState<{
    search: string
    page: number
    users: string[]
    email: string
    verbs: string[]
    startDate: string
    endDate: string
  }>({
    search: searchTerm ? searchTerm : '',
    page: 1,
    email: '',
    users: [],
    verbs: verbs ? verbs : [],
    startDate: '',
    endDate: ''
  })

  const [logs, setLogs] = useState<ILogObject[]>([])
  const [availableEventNames, setAvailableEventNames] = useState<string[]>([])
  const [users, setUsers] = useState<OptionProps[]>([])
  const [noMoreLogs, setNoMoreLogs] = useState<boolean>(false)

  const { user, onAdvancedPlan } = useAuth()
  const { displayErrorMessage, setSnackbarMessage } = useApplicationStore()
  const { project, projectOwner } = useProject()

  useEffect(() => {
    fetchAvailabileEventTypes()
    if (resourceId && type && !project.loading && projectOwner) {
      api
        .getProjectMembers(project.publicId)
        .then((response) => {
          setUsers(
            response.data
              .sort((a: IProjectMember, b: IProjectMember) => {
                if (a.user.name.toLowerCase() > b.user.name.toLowerCase()) return 1
                else if (a.user.name.toLowerCase() < b.user.name.toLowerCase()) return -1
                else return 0
              })
              .map((member: IProjectMember) => {
                return { label: `${member.user.name} (${member.user.email})`, value: member.user.publicId }
              })
          )
        })
        .catch((error) => {
          displayErrorMessage(error)
          setUsers([])
        })
    }
  }, [])

  const fetchAvailabileEventTypes = async () => {
    try {
      const response = await api.getNotificationEventTypes()
      let eventTypes: string[] = response.data
      if (type === 'process') {
        eventTypes = response.data.filter(
          (eventType: string) =>
            eventType.startsWith('process') ||
            eventType.startsWith('table.cell') ||
            eventType.startsWith('table.row') ||
            eventType.startsWith('table.sync_completed') ||
            eventType.startsWith('table.sync_error') ||
            eventType.startsWith('table.script_error') ||
            eventType.includes('comment') ||
            eventType.includes('thread') ||
            eventType.includes('csv') ||
            eventType.includes('policy')
        )
      } else if (type === 'table') {
        eventTypes = response.data.filter(
          (eventType: string) =>
            !(eventType.startsWith('project') || eventType.startsWith('member') || eventType.startsWith('process'))
        )
      }
      setAvailableEventNames(eventTypes)
    } catch (e) {
      displayErrorMessage(e)
    }
  }

  useEffect(() => {
    setLoading(true)
    let newLogs: ILogObject[] = logs
    if (config.page === 1) newLogs = []

    const users = config.users
    const verbs = config.verbs
    const startDate =
      config.startDate === '' || isNaN(Date.parse(config.startDate)) ? null : new Date(config.startDate).toISOString()
    const endDate =
      config.endDate === '' || isNaN(Date.parse(config.endDate)) ? null : new Date(config.endDate).toISOString()
    const search = config.search === '' ? null : config.search
    if (resourceId && type) {
      api
        .getEvents(resourceId, type, config.page, users, verbs, startDate, endDate, search)
        .then((response) => {
          const additionalLogs: ILogObject[] = response.data
          setLogs(newLogs.concat(additionalLogs))
          setLoading(false)
          if (additionalLogs.length < 50) setNoMoreLogs(true)
          else if (additionalLogs.length === 50) setNoMoreLogs(false)
        })
        .catch((error) => {
          displayErrorMessage(error)
          if (error instanceof APIError) {
            if (error.code == ApiErrorCodes.SUBSCRIPTION_LEVEL) {
              setLogs(
                Array(10)
                  .fill(0)
                  .map(() => {
                    return {
                      publicId: 'YYYY',
                      user: {
                        firebaseUserId: 'YYYY',
                        email: 'AAAA',
                        profilePicture: '/assets/images/user.png',
                        name: 'System',
                        kind: 'User'
                      },
                      contextProject: null,
                      contextProcess: null,
                      contextProcessSection: null,
                      contextProcessResponse: null,
                      contextTable: null,
                      contextTableView: null,
                      contextTableColumn: null,
                      verb: 'created',
                      resource: 'table',
                      change: error.message,
                      createdAt: new Date().toISOString(),
                      context: null
                    }
                  })
              )
            }
          }
          setLoading(false)
          setNoMoreLogs(true)
        })
    }
  }, [config])

  const handleUserChange = (user: string) => {
    let new_users: string[] = []
    if (user === '') {
      new_users = []
    } else if (config.users.includes(user)) {
      new_users = config.users.filter((v) => v !== user)
    } else {
      new_users = [...config.users, user]
    }
    setConfig({
      ...config,
      page: 1,
      users: new_users
    })
  }

  const handleVerbChange = (verb: string) => {
    let new_verbs: string[] = []
    if (verb === '') {
      new_verbs = []
    } else if (config.verbs.includes(verb)) {
      new_verbs = config.verbs.filter((v) => v !== verb)
    } else {
      new_verbs = [...config.verbs, verb]
    }

    setConfig({
      ...config,
      page: 1,
      verbs: new_verbs
    })
  }

  const handleStartDateChange = (startDate: string) => {
    setConfig({
      ...config,
      page: 1,
      startDate
    })
  }

  const handleEndDateChange = (endDate: string) => {
    setConfig({
      ...config,
      page: 1,
      endDate
    })
  }

  const handleChangeCopy = (change: string) => {
    try {
      navigator.clipboard.writeText(change)
      setSnackbarMessage({
        status: 'success',
        message: `Copied change value to clipboard`
      })
    } catch (error) {
      displayErrorMessage(error)
    }
  }

  const blurWrapper = (content: React.ReactNode, shouldBlur: boolean) => {
    return (
      <div style={shouldBlur ? { filter: 'blur(7px)', pointerEvents: 'none', cursor: 'default' } : {}}>{content}</div>
    )
  }

  return (
    <div className="flex flex-column w-full overflow-hidden" style={{ maxHeight: '100%' }}>
      <div className="flex text-sm mb-2" style={{ height: '100px', alignItems: 'flex-end' }}>
        <div className="mr-2" style={{ flex: 1 }}>
          <Select
            options={users}
            onOptionClick={(option) => handleUserChange(option)}
            optionsSelected={config.users}
            multiselect={true}
            placeholder="All Users"
          />
        </div>

        <div className="mr-2" style={{ flex: 1 }}>
          <Select
            options={availableEventNames.map((eventTypeName) => ({ label: eventTypeName, value: eventTypeName }))}
            onOptionClick={(option) => handleVerbChange(option)}
            optionsSelected={config.verbs}
            multiselect={true}
            placeholder="All event types"
          />
        </div>

        <div className="mr-2" style={{ flex: 1 }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label htmlFor="start-date" style={{ marginBottom: '2px' }}>
              From:
            </label>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type="date"
                id="start-date"
                name="start-date"
                value={config.startDate}
                max={config.endDate}
                onChange={(event) => handleStartDateChange(event.target.value)}
                onClick={(event) => event.stopPropagation()}
                style={{ userSelect: 'none', cursor: 'pointer' }}
              />
              <div style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => handleStartDateChange('')}>
                ❌
              </div>
            </div>
          </div>
        </div>

        <div className="mr-2" style={{ flex: 1 }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label htmlFor="end-date" style={{ marginBottom: '2px' }}>
              To:
            </label>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type="date"
                id="end-date"
                name="end-date"
                value={config.endDate}
                min={config.startDate}
                onChange={(event) => handleEndDateChange(event.target.value)}
                onClick={(event) => event.stopPropagation()}
                style={{ userSelect: 'none', cursor: 'pointer' }}
              />
              <div style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => handleEndDateChange('')}>
                ❌
              </div>
            </div>
          </div>
        </div>
      </div>

      <Subscription type="scale" message="view audit logs older than 30 days" />
      <Subscription type="advanced" message="view audit logs" />

      <Table
        data={logs.map((log) => {
          const logDate = new Date(Date.parse(log.createdAt))
          const now = new Date()
          const diffDays = (now.getTime() - logDate.getTime()) / (1000 * 60 * 60 * 24)
          const shouldBlurLog = (user && !user.onScalePlan && diffDays > 30) || !onAdvancedPlan

          return {
            name: {
              label: blurWrapper(
                <div className="flex items-center">
                  <img
                    src={log.user && log.user.profilePicture ? log.user.profilePicture : '/assets/images/user.png'}
                    style={{
                      borderRadius: '50%',
                      width: '40px',
                      height: '40px',
                      objectFit: 'cover',
                      marginRight: '10px'
                    }}
                    alt={`${log.user && log.user.name ? log.user.name : 'Anonymous'} profile picture`}
                  />
                  {log.user && log.user.name && log.user.firebaseUserId ? (
                    <a
                      href={`/profile/${log.user.firebaseUserId}/information`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {log.user.name}
                    </a>
                  ) : (
                    <div>Anonymous</div>
                  )}
                </div>,
                shouldBlurLog
              ),
              value: log.user && log.user.name ? log.user.name : 'Anonymous'
            },
            project: {
              label: blurWrapper(
                log.contextProject ? (
                  <a href={`${HUB_BASE}${log.contextProject.publicId}`} target="_blank" rel="noopener noreferrer">
                    {log.contextProject.name}
                  </a>
                ) : (
                  ''
                ),
                shouldBlurLog
              ),
              value: log.contextProject ? log.contextProject.name : ''
            },
            createdAt: {
              label: blurWrapper(dateTime(new Date(Date.parse(log.createdAt))).toLocaleString(), shouldBlurLog),
              value: log.createdAt
            },
            action: {
              label: blurWrapper(
                <span
                  className="font-bold rounded"
                  style={{
                    backgroundColor:
                      AUDIT_RED_KEYWORDS.findIndex((keyword) => log.verb.includes(keyword)) !== -1
                        ? constants.lightRed
                        : AUDIT_GREEN_KEYWORDS.findIndex((keyword) => log.verb.includes(keyword)) !== -1
                        ? constants.green
                        : constants.lightBlue,
                    padding: '5px'
                  }}
                >
                  {log.resource}.{log.verb}
                </span>,
                shouldBlurLog
              ),
              value: `${log.resource}.${log.verb}`
            },
            process: {
              label: blurWrapper(
                log.contextProject && log.contextProcess ? (
                  <a
                    href={
                      log.contextProcessSection && log.contextProcessSection.publicId
                        ? `${HUB_BASE}${log.contextProject.publicId}${DOCUMENT_BASE}${log.contextProcess.publicId}?section=${log.contextProcessSection.publicId}`
                        : `${HUB_BASE}${log.contextProject.publicId}${DOCUMENT_BASE}${log.contextProcess.publicId}`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {log.contextProcess.name}
                  </a>
                ) : (
                  'n/a'
                ),
                shouldBlurLog
              ),
              value: log.contextProcess ? log.contextProcess.name : ''
            },
            processSection: {
              label: blurWrapper(log.contextProcessSection ? log.contextProcessSection.name : 'n/a', shouldBlurLog),
              value: log.contextProcessSection ? log.contextProcessSection.name : ''
            },
            table: {
              label: blurWrapper(
                log.contextTable?.publicId ? (
                  <a
                    href={`${HUB_BASE}${log.contextProject?.publicId}/table/${log.contextTable?.publicId}/view/${
                      log.contextTableView?.publicId ? log.contextTableView?.publicId : log.contextTable?.defaultViewId
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {log.contextTable?.name}
                  </a>
                ) : (
                  'n/a'
                ),
                shouldBlurLog
              ),
              value: log.contextTable ? log.contextTable.name : ''
            },
            column: {
              label: blurWrapper(log.contextTableColumn ? log.contextTableColumn.name : 'n/a', shouldBlurLog),
              value: log.contextTableColumn ? log.contextTableColumn.name : ''
            },
            change: {
              label: blurWrapper(
                log.change && log.change !== 'null' && log.change !== '{}' ? (
                  <div
                    className="cursor-pointer hover-bg-light-grey transition-all rounded"
                    style={{ padding: '5px', maxWidth: 'fit-content' }}
                    onClick={() => handleChangeCopy(log.change ? log.change : '')}
                  >
                    <Copy />
                  </div>
                ) : (
                  ''
                ),
                shouldBlurLog
              ),
              value: ''
            }
          }
        })}
        include={[
          { header: 'User', id: 'name' },
          { header: `${capitaliseFirstLetter(PROJECT)}`, id: 'project' },
          { header: 'Date', id: 'createdAt' },
          { header: 'Action', id: 'action' },
          { header: 'Document', id: 'process' },
          { header: 'Document Section', id: 'processSection' },
          { header: 'Table', id: 'table' },
          { header: 'Column', id: 'column' },
          { header: 'Change', id: 'change' }
        ]}
        defaultSort={'createdAt'}
        defaultSortAscending={true}
        sort={false}
        loading={false}
      />
      {!noMoreLogs && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Button
            style={{ width: '150px' }}
            onClick={() => setConfig({ ...config, page: config.page + 1 })}
            isLoading={loading}
          >
            Get More Logs
          </Button>
        </div>
      )}
    </div>
  )
}

export default Audit
