import React, { useState } from 'react'
import Button from 'components/button'
import useApplicationStore from 'hooks/application'
import { Link } from 'react-router-dom'
import useAuth from 'hooks/auth'

export interface IFormValues {
  name: string
  email: string
  password1: string
  password2: string
}

interface Props {
  onSubmit: (values: IFormValues) => void
  template?: string
}

const SignupForm: React.FC<Props> = ({ onSubmit, template }) => {
  const { setSnackbarMessage } = useApplicationStore()
  const { startMicrosoftAuth, startGoogleAuth } = useAuth()

  const [submitting, setSubmitting] = useState<boolean>(false)
  const [values, setValues] = useState<IFormValues>({
    name: '',
    email: '',
    password1: '',
    password2: ''
  })

  const nameError = values.name === ''
  const emailError = !values.email
    .toLowerCase()
    .match(
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    )

  const password8CharactersError = !values.password1.match(/.{8,}/)
  const passwordLowercaseError = !values.password1.match(/^(?=.*[a-z])/)
  const passwordUppercaseError = !values.password1.match(/^(?=.*[A-Z])/)
  const passwordNumericError = !values.password1.match(/^(?=.*[0-9])/)
  const passwordSpecialError = !values.password1.match(/^(?=.*[!@#$%^&*])/)
  const confirmPasswordError = values.password1 !== values.password2
  const passwordError =
    password8CharactersError ||
    passwordLowercaseError ||
    passwordUppercaseError ||
    passwordNumericError ||
    passwordSpecialError ||
    confirmPasswordError

  const handleSubmit = async () => {
    if (nameError) {
      setSnackbarMessage({
        status: 'error',
        message: 'Please enter your name'
      })
      return
    }

    if (emailError) {
      setSnackbarMessage({
        status: 'error',
        message: 'Please enter a valid email address'
      })
      return
    }

    if (passwordError) {
      setSnackbarMessage({
        status: 'error',
        message: 'Please enter a valid password - 8 characters, 1 lowercase, 1 uppercase, 1 number, 1 special character'
      })
      return
    }

    if (confirmPasswordError) {
      setSnackbarMessage({
        status: 'error',
        message: 'Please confirm your password'
      })
      return
    }

    setSubmitting(true)
    await onSubmit(values)
    setSubmitting(false)
  }

  const handleSignUpWithProvider = async (name: string) => {
    switch (name) {
      case 'microsoft':
        startMicrosoftAuth(template)
        break
      case 'google':
        startGoogleAuth(template)
        break
      default:
    }
  }

  return (
    <div className="w-full">
      <Button
        internalType="outline"
        className="w-full text-base"
        style={{
          marginBottom: '10px'
        }}
        onClick={() => handleSignUpWithProvider('microsoft')}
      >
        <img style={{ marginRight: '10px' }} src="/assets/images/microsoft.svg" alt="microsoft logo" />
        Sign up with Microsoft
      </Button>
      <Button
        internalType="outline"
        className="w-full text-base"
        onClick={() => handleSignUpWithProvider('google')}
        style={{
          border: '1px solid black',
          background: 'white',
          marginBottom: '10px'
        }}
      >
        <img style={{ marginRight: '10px' }} src="/assets/images/google.svg" alt="google logo" />
        Sign up with Google
      </Button>

      <div className="sign-up-split mt-4 mb-4">or</div>

      <input
        name="name"
        type="text"
        placeholder="Name"
        data-cy="name-input"
        autoComplete="name"
        onChange={(event) => setValues({ ...values, name: event.target.value })}
      />
      <input
        name="email"
        type="text"
        placeholder="Email"
        data-cy="email-input"
        autoComplete="email"
        style={{ marginTop: '10px' }}
        onChange={(event) => setValues({ ...values, email: event.target.value })}
      />

      <input
        placeholder="Password"
        data-cy="password1-input"
        type="password"
        onChange={(event) => setValues({ ...values, password1: event.target.value })}
        style={{ marginTop: '10px' }}
        autoComplete="new-password"
      />

      <input
        placeholder="Confirm password"
        data-cy="password2-input"
        type="password"
        autoComplete="new-password"
        style={{ marginTop: '10px' }}
        onChange={(event) => setValues({ ...values, password2: event.target.value })}
      />

      <Button
        style={{ marginTop: '10px', width: '100%', fontSize: '1rem' }}
        onClick={() => handleSubmit()}
        data-cy="signup-button"
        isLoading={submitting}
      >
        Sign Up
      </Button>

      <div className="flex items-center justify-center mt-8">
        Already have an account?{' '}
        <Link to="/signin" style={{ marginLeft: '5px' }}>
          Sign in
        </Link>
      </div>

      <div className="text-secondary text-sm border-t-1px border-solid border-grey mt-8 pt-20px">
        By signing up, you agree to our
        <a
          href="https://morta.io/trust/tos"
          target="_blank"
          rel="noopener noreferrer"
          style={{ marginLeft: '5px', marginRight: '5px' }}
        >
          Terms of Service
        </a>
        and
        <a
          href="https://morta.io/trust/privacy"
          target="_blank"
          rel="noopener noreferrer"
          style={{ marginLeft: '5px' }}
        >
          Privacy Policy
        </a>
      </div>
    </div>
  )
}

export default SignupForm
