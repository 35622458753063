import React, { useState, useEffect, useRef, useCallback } from 'react'
import { cancelTimeout, requestTimeout, TimeoutID } from 'helpers/timer'
import { APIError, ApiErrorCodes } from 'helpers/api'
import Button from 'components/button'
import { SUBSCRIPTION } from 'routes'
import { ISnackbarObject } from 'types'
import { useLocation } from 'react-router'
import { HUB_BASE } from 'routes'

const _useApplicationProvider = () => {
  const location = useLocation()

  const [snackbarMessage, updateSnackbarMessage] = useState<ISnackbarObject>({
    message: '',
    status: 'noShow',
    duration: 8000
  })
  const [sidebarWidth, updateSidebarWidth] = useState<number>(0)
  const [templatesModal, setTemplatesModal] = useState<boolean>(false)

  const timeoutRef = useRef<TimeoutID | null>(null)

  const setSidebarWidth = useCallback((newSidebarWidth: number) => {
    localStorage.setItem('mortaSidebarWidth', newSidebarWidth.toString())
    updateSidebarWidth(newSidebarWidth)
  }, [])

  const setSnackbarMessage = useCallback((newSnackbarObject: ISnackbarObject) => {
    updateSnackbarMessage((prev) => {
      if (
        prev.message === newSnackbarObject.message &&
        prev.status === newSnackbarObject.status &&
        prev.duration === newSnackbarObject.duration
      ) {
        return prev
      }
      return newSnackbarObject
    })

    if (timeoutRef.current) {
      cancelTimeout(timeoutRef.current)
    }
    timeoutRef.current = requestTimeout(
      () => {
        updateSnackbarMessage({ status: undefined })
      },
      newSnackbarObject.duration === undefined ? 5000 : newSnackbarObject.duration
    )
  }, [])

  const displayErrorMessage = useCallback(
    (e: any) => {
      if (e instanceof APIError) {
        let tableId
        let tableViewId
        let projectId
        if (e.code === ApiErrorCodes.SUBSCRIPTION_LEVEL) {
          setSnackbarMessage({
            status: 'subscription',
            message: e.message,
            action: (
              <Button internalType="outline" style={{ marginLeft: '20px' }} to={SUBSCRIPTION}>
                Upgrade Now
              </Button>
            )
          })
        } else {
          if (e.errors && e.errors.length > 0) {
            const primaryError = e.errors[0]
            if (
              'attribute' in primaryError &&
              typeof primaryError['attribute'] === 'object' &&
              primaryError['attribute'] !== null &&
              primaryError['attribute'] !== undefined &&
              'tableId' in primaryError['attribute'] &&
              'tableViewId' in primaryError['attribute']
            ) {
              tableId = primaryError['attribute']['tableId']
              tableViewId = primaryError['attribute']['tableViewId']
            }
          }

          if (e.errors && e.errors.length > 0) {
            const primaryError = e.errors[0]
            if (
              'attribute' in primaryError &&
              primaryError['attribute'] !== null &&
              primaryError['attribute'] !== undefined &&
              primaryError['attribute'] === '_schema' &&
              primaryError['messages'] &&
              primaryError['messages'].length > 0
            ) {
              setSnackbarMessage({
                status: 'error',
                message: primaryError.messages[0]
              })
              return
            }
          }

          if (location) {
            try {
              const pathParts = location.pathname.split('/')
              if (pathParts.length > 2) {
                projectId = pathParts[2]
              }
            } catch (e) {
              projectId = undefined
            }
          }

          setSnackbarMessage({
            status: 'error',
            message: e.message,
            action:
              projectId && tableId && tableViewId ? (
                <a
                  href={`${HUB_BASE}${projectId}/table/${tableId}/view/${tableViewId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button internalType="outline" style={{ marginLeft: '10px' }}>
                    Open Table
                  </Button>
                </a>
              ) : (
                <div />
              )
          })
        }
      } else if (typeof e === 'string') {
        setSnackbarMessage({
          status: 'error',
          message: e
        })
      } else {
        setSnackbarMessage({
          status: 'error'
        })
      }
    },
    [setSnackbarMessage]
  )

  useEffect(() => {
    const savedSidebarWidth = localStorage.getItem('mortaSidebarWidth')
    if (savedSidebarWidth) {
      const newSidebarWidth = Number.parseInt(savedSidebarWidth)
      updateSidebarWidth(newSidebarWidth)
    }
  }, [])

  const minSidebarWidth = 60
  const maxSidebarWidth = 600
  const minSidebarWidthDisplay = 170

  return {
    snackbarMessage,
    setSnackbarMessage,
    sidebarWidth,
    setSidebarWidth,
    minSidebarWidth,
    maxSidebarWidth,
    minSidebarWidthDisplay,
    templatesModal,
    setTemplatesModal,
    displayErrorMessage
  }
}

type ApplicationContextType = ReturnType<typeof _useApplicationProvider>

const ApplicationContext = React.createContext<ApplicationContextType | undefined>(undefined)

export const ApplicationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const value = _useApplicationProvider()
  return <ApplicationContext.Provider value={value}>{children}</ApplicationContext.Provider>
}

export const useApplicationStore = () => {
  const context = React.useContext(ApplicationContext)

  if (context === undefined) {
    throw new Error('useApplication can only be used within an Application context')
  }

  return context
}

export default useApplicationStore
