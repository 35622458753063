import React from 'react'
import { useDataContext } from 'components/document/contexts/data'
import Variables from 'components/variables'
import Modal, { ModalProps } from 'components/modal'

const DefaultVariablesModal: React.FC<ModalProps> = ({ id, open, setOpen, onClose }) => {
  const { process, updateProcess } = useDataContext()
  if (process) {
    return (
      <Modal id={id} open={open} setOpen={setOpen} title="Default Variables" onClose={onClose}>
        <div
          className="overflow-x-hidden overflow-y-auto"
          style={{ listStyle: 'none', margin: '10px', padding: '10px', height: '500px' }}
        >
          <div style={{ marginTop: '20px', marginBottom: '40px' }}>
            Select the default variables that will be used for filtering tables in this process.
          </div>

          <Variables
            initialVariables={process ? process.variables : []}
            onChange={(variableIds: string[]) => updateProcess({ ...process, variables: variableIds })}
          />
        </div>
      </Modal>
    )
  } else {
    return <div />
  }
}

export default DefaultVariablesModal
