import { IColumnTypes, IResultFieldValidation } from 'components/spreadsheet/types'
import { AllowedDateFormats, AllowedDatetimeFormats, ICellValue, ITableViewColumn } from 'types'
import { NUMBER_COLUMN_TYPES, SELECT_COLUMN_TYPES, TEXT_COLUMN_TYPES } from '../constants/const'
import { isSameValueStringValue, validateField } from './functions'
import { toUTCString } from './format'
export const reformattedPasteData = (text: string) => {
  const trimmedText = text.replace(/\n+$/, '')

  const newString = trimmedText
    .replace(/("[\s\S]*?")/g, (m, cg) => {
      return cg.replace(/\n/g, 'LINE-BREAK-TO-PRESERVE')
    })
    .split('\n')
    .map((i) => i.replace(/LINE-BREAK-TO-PRESERVE/g, '\n'))
  return newString
}

function splitByDelimiters(value: string, delimiters: string[] = [',', ';', '|']): string[] {
  for (const delimiter of delimiters) {
    if (value.includes(delimiter)) {
      return value.split(delimiter).map((v) => v.trim())
    }
  }
  // If no delimiter matched, return single-item array
  return [value.trim()]
}

export const DATE_FORMAT_CANDIDATES: AllowedDateFormats[] = [
  'DD/MM/YYYY',
  'DD-MM-YYYY',
  'MM/DD/YYYY',
  'MM-DD-YYYY',
  'YYYY/MM/DD',
  'YYYY-MM-DD'
]

export const detectLikelyDateFormat = (dateStrings: string[]): AllowedDateFormats => {
  const formatScores = new Map<AllowedDateFormats, number>()
  DATE_FORMAT_CANDIDATES.forEach((fmt) => formatScores.set(fmt, 0))

  for (const raw of dateStrings) {
    const value = raw.trim()
    if (!value) continue

    let matched = false
    for (const fmt of DATE_FORMAT_CANDIDATES) {
      if (parseDateByFormat(value, fmt)) {
        formatScores.set(fmt, (formatScores.get(fmt) || 0) + 1)
        matched = true
        break // Use the first format that parses successfully
      }
    }
    // If no format matched, this string does not contribute to any score.
  }

  // Pick the candidate with the highest score
  let bestFormat: AllowedDateFormats = DATE_FORMAT_CANDIDATES[0]
  let bestScore = -1
  for (const [fmt, score] of formatScores.entries()) {
    if (score > bestScore) {
      bestFormat = fmt
      bestScore = score
    }
  }
  return bestFormat
}

export const DATETIME_FORMAT_CANDIDATES: AllowedDatetimeFormats[] = [
  'DD/MM/YYYY HH:mm',
  'DD-MM-YYYY HH:mm',
  'MM/DD/YYYY HH:mm',
  'MM-DD-YYYY HH:mm',
  'YYYY-MM-DD HH:mm',
  'YYYY/MM/DD HH:mm',
  'YYYY-MM-DD HH:mm:ss',
  'YYYY-MM-DDTHH:mm:ss'
]

export const detectLikelyDatetimeFormat = (datetimeStrings: string[]): AllowedDatetimeFormats => {
  const formatScores = new Map<AllowedDatetimeFormats, number>()
  DATETIME_FORMAT_CANDIDATES.forEach((fmt) => formatScores.set(fmt, 0))

  for (const raw of datetimeStrings) {
    const value = raw.trim()
    if (!value) continue

    let matched = false
    for (const fmt of DATETIME_FORMAT_CANDIDATES) {
      if (parseDatetimeByFormat(value, fmt)) {
        formatScores.set(fmt, (formatScores.get(fmt) || 0) + 1)
        matched = true
        break
      }
    }
    // If none match, the string is skipped.
  }

  let bestFormat: AllowedDatetimeFormats = DATETIME_FORMAT_CANDIDATES[0]
  let bestScore = -1
  for (const [fmt, score] of formatScores.entries()) {
    if (score > bestScore) {
      bestFormat = fmt
      bestScore = score
    }
  }
  return bestFormat
}

export function parseDateByFormat(value: string, format: AllowedDateFormats): Date | null {
  const trimmed = value.trim()
  if (!trimmed) return null

  // Determine delimiter by checking the format string (assume '/' or '-')
  const delimiter = format.includes('/') ? '/' : '-'
  const parts = trimmed.split(delimiter)
  if (parts.length !== 3) return null

  let day: number, month: number, year: number

  // Determine ordering based on the candidate format
  if (format.startsWith('DD')) {
    // 'DD/MM/YYYY' or 'DD-MM-YYYY'
    day = parseInt(parts[0], 10)
    month = parseInt(parts[1], 10)
    year = parseInt(parts[2], 10)
  } else if (format.startsWith('MM')) {
    // 'MM/DD/YYYY' or 'MM-DD-YYYY'
    month = parseInt(parts[0], 10)
    day = parseInt(parts[1], 10)
    year = parseInt(parts[2], 10)
  } else if (format.startsWith('YYYY')) {
    // 'YYYY/MM/DD' or 'YYYY-MM-DD'
    year = parseInt(parts[0], 10)
    month = parseInt(parts[1], 10)
    day = parseInt(parts[2], 10)
  } else {
    return null
  }

  // Basic range checks
  if (month < 1 || month > 12) return null
  if (day < 1 || day > 31) return null

  const date = new Date(Date.UTC(year, month - 1, day))
  // Check for date rollover (e.g. 31/02/2025)
  if (date.getFullYear() !== year || date.getMonth() !== month - 1 || date.getDate() !== day) {
    return null
  }
  return date
}

export function parseDatetimeByFormat(value: string, format: AllowedDatetimeFormats): Date | null {
  const trimmed = value.trim()
  if (!trimmed) return null

  // Determine the separator between date and time: either 'T' or space.
  const separator = format.includes('T') ? 'T' : ' '
  const parts = trimmed.split(separator)

  if (parts.length !== 2) return null

  const datePart = parts[0]
  const timePart = parts[1]

  // Extract the date format part from the candidate format.
  const candidateDateFormat = format.split(separator)[0] as AllowedDateFormats
  const date = parseDateByFormat(datePart, candidateDateFormat)

  if (!date) return null

  // Extract expected time format from candidate.
  const candidateTimeFormat = format.split(separator)[1]
  let hours: number,
    minutes: number,
    seconds = 0
  const timeComponents = timePart.split(':')

  if (candidateTimeFormat === 'HH:mm') {
    if (timeComponents.length !== 2) return null
    hours = parseInt(timeComponents[0], 10)
    minutes = parseInt(timeComponents[1], 10)
  } else if (candidateTimeFormat === 'HH:mm:ss') {
    if (timeComponents.length !== 3) return null
    hours = parseInt(timeComponents[0], 10)
    minutes = parseInt(timeComponents[1], 10)
    seconds = parseInt(timeComponents[2], 10)
  } else {
    return null // Unsupported time format
  }

  // Validate time ranges
  if (hours < 0 || hours > 23) return null
  if (minutes < 0 || minutes > 59) return null
  if (seconds < 0 || seconds > 59) return null

  date.setHours(hours, minutes, seconds, 0)
  if (date.getHours() !== hours || date.getMinutes() !== minutes || date.getSeconds() !== seconds) {
    return null
  }
  return date
}

export const transformStringToCorrectFormat = (
  value: string | null,
  kind: IColumnTypes,
  dateFormat?: AllowedDateFormats,
  datetimeFormat?: AllowedDatetimeFormats
) => {
  if (value === null || value === '') return null
  else {
    if (kind === 'multilink' || kind === 'multiselect') {
      let array: unknown
      try {
        array = JSON.parse(value)
      } catch {
        array = splitByDelimiters(value)
      }

      if (!Array.isArray(array)) {
        throw `Cannot transform '${value}' into '${kind}'.`
      }

      array.forEach((item) => {
        if (typeof item !== 'string') {
          throw `Cannot transform '${value}' into '${kind}'. Each item must be a string.`
        }
        return item.trim()
      })
      return array
    } else if (kind === 'attachment' || kind === 'vote') {
      let array
      try {
        array = JSON.parse(value)
      } catch {
        throw `Cannot transform '${value}' into '${kind}'.`
      }
      if (!Array.isArray(array)) throw `Cannot transform '${value}' into '${kind}'.`
      return array
    } else if (kind === 'integer' || kind === 'duration') {
      const number = Math.round(Number(value.replace(/,/g, '')))
      if (isNaN(number)) throw `Cannot transform '${value}' into '${kind}'.`
      return number
    } else if (kind === 'float' || kind === 'percentage') {
      const number = Number.parseFloat(value.replace(/,/g, ''))
      if (isNaN(number)) throw `Cannot transform '${value}' into '${kind}'.`
      return number
    } else if (kind === 'checkbox') {
      if (value && (value.toLocaleLowerCase() === 'true' || value === '1')) return 'true'
      else return 'false'
    } else if (kind === 'date') {
      if (dateFormat) {
        const date = parseDateByFormat(value, dateFormat)
        if (!date) throw `Cannot transform '${value}' into '${kind}' using '${dateFormat}' format.`
        return date.toISOString().split('T')[0]
      } else {
        checkStringIsValidDatetime(value, kind)
        return value
      }
    } else if (kind === 'datetime') {
      if (datetimeFormat) {
        const date = parseDatetimeByFormat(value, datetimeFormat)
        if (!date) throw `Cannot transform '${value}' into '${kind}' using '${datetimeFormat}' format.`
        return toUTCString(date.toISOString(), kind)
      } else {
        checkStringIsValidDatetime(value, kind)
        return value
      }
    } else {
      return value
    }
  }
}

export const validatePasteField = (value: ICellValue, column: ITableViewColumn) => {
  const kind = column.kind
  if (kind !== 'multilink' && kind !== 'attachment' && kind !== 'select' && kind !== 'multiselect' && kind !== 'vote') {
    const result = validateField(kind, value, column.name)
    if (!(result as IResultFieldValidation).pass) {
      throw `'${value}' is not a valid value for column with type '${kind}'.`
    }
  }

  return true
}

export const checkValuesSame = (oldValue: ICellValue, newValue: ICellValue, kind: IColumnTypes) => {
  if ([...SELECT_COLUMN_TYPES, 'multilink', 'attachment', 'vote'].includes(kind)) {
    // if both are arrays, we need to check if the values are the same
    if (Array.isArray(newValue) && Array.isArray(oldValue)) {
      if ((newValue as string[]).length === (oldValue as string[]).length) {
        const isNewValue = (newValue as string[])
          .map((item) => Array.isArray(oldValue) && (oldValue as string[]).includes(item))
          .some((e) => e === false)
        if (!isNewValue) {
          return true
        }
      }
    } else if (newValue === oldValue) {
      // if both values are the same either empty string or null we don't need to save
      return true
    }
  } else if (
    [...TEXT_COLUMN_TYPES, ...NUMBER_COLUMN_TYPES, 'link'].includes(kind) &&
    isSameValueStringValue(newValue as string, oldValue as string)
  ) {
    return true
  }
  return false
}

export const checkStringIsValidDatetime = (value: string, kind: string) => {
  const parsedDate = Date.parse(value)
  if (isNaN(parsedDate)) throw `Cannot transform '${value}' into '${kind}'.`
  const toIsoString = new Date(parsedDate).toISOString().split('T')[0]
  if (!value.startsWith(toIsoString)) throw `Cannot transform '${value}' into '${kind}'.`
  return value
}
