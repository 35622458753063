import React from 'react'
import { ISnackbarObject, IUserObject } from 'types'
import Button from 'components/button'
import { USER_SUBSCRIPTION_LEVELS } from 'app-constants'
import { Copy } from 'components/icons'

export const secondsToHms = (d: number) => {
  d = Number(d)
  const h = Math.floor(d / 3600)
  const m = Math.floor((d % 3600) / 60)
  const s = Math.floor((d % 3600) % 60)

  const hDisplay = h > 0 ? h + (h == 1 ? ' hour, ' : ' hours, ') : ''
  const mDisplay = m > 0 ? m + (m == 1 ? ' minute, ' : ' minutes, ') : ''
  const sDisplay = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : ''
  return hDisplay + mDisplay + sDisplay
}

export const mapUsersToTableData = (
  users: IUserObject[],
  onLogin: (user: IUserObject) => void,
  verifyEmail: (user: IUserObject) => void,
  handleEditUser: (user: IUserObject) => void,
  remove2fa: (user: IUserObject) => void,
  setSnackbarMessage: (newSnackbarObject: ISnackbarObject) => void
) => {
  return users.map((user: IUserObject) => {
    return {
      email: {
        label: (
          <div className="flex items-center">
            {user.email}
            <div
              className="cursor-pointer hover-bg-light-grey transition-all rounded"
              style={{ padding: '5px', maxWidth: 'fit-content', marginLeft: 'auto' }}
              onClick={() => {
                try {
                  navigator.clipboard.writeText(user.email)
                  setSnackbarMessage({
                    status: 'success',
                    message: 'Email copied to clipboard'
                  })
                } catch (error) {
                  setSnackbarMessage({
                    status: 'error',
                    message: 'Failed to copy email'
                  })
                }
              }}
            >
              <Copy />
            </div>
          </div>
        ),
        value: user.email
      },
      publicId: {
        label: (
          <div className="flex items-center">
            {user.publicId.slice(0, 5)}...
            <div
              className="cursor-pointer hover-bg-light-grey transition-all rounded"
              style={{ padding: '5px', maxWidth: 'fit-content', marginLeft: 'auto' }}
              onClick={() => {
                try {
                  navigator.clipboard.writeText(user.publicId)
                  setSnackbarMessage({
                    status: 'success',
                    message: 'Public ID copied to clipboard'
                  })
                } catch (error) {
                  setSnackbarMessage({
                    status: 'error',
                    message: 'Failed to copy public ID'
                  })
                }
              }}
            >
              <Copy />
            </div>
          </div>
        ),
        value: user.publicId
      },
      createdAt: {
        label: user.createdAt ? user.createdAt.split('T')[0] : '',
        value: user.createdAt ? user.createdAt : ''
      },
      subscriptionLevel: {
        label:
          user.subscriptionLevel && user.isOnFreeTrial ? (
            <span
              className="text-sm bg-light-orange rounded border-1px border-grey border-solid"
              style={{ padding: '5px' }}
            >
              Advanced (Free Trial)
            </span>
          ) : (
            <span
              className={`text-sm ${
                user.subscriptionLevel === 0
                  ? 'bg-light-blue'
                  : user.subscriptionLevel === 1 && !user.isOnFreeTrial
                  ? 'bg-light-green'
                  : 'bg-light-orange'
              } rounded border-1px border-grey border-solid`}
              style={{ padding: '5px' }}
            >
              {USER_SUBSCRIPTION_LEVELS[user.subscriptionLevel]}
            </span>
          ),
        value:
          user.subscriptionLevel && user.isOnFreeTrial ? 'Free Trial' : USER_SUBSCRIPTION_LEVELS[user.subscriptionLevel]
      },
      invitedBy: { label: user.invitedBy, value: user.invitedBy ? user.invitedBy : '' },
      invitesSent: { label: user.invitesSent, value: user.invitesSent ? user.invitesSent : 0 },
      projectsOn: { label: user.projectsOn, value: user.projectsOn ? user.projectsOn : 0 },
      allowSupportAccess: {
        label: user.allowSupportAccess ? 'Yes' : 'No',
        value: user.allowSupportAccess ? 'Yes' : 'No'
      },
      subscriptionQuota: {
        label: user.subscriptionQuota,
        value: user.subscriptionQuota
      },
      numberOfManagedSubscriptionUsers: {
        label: user.numberOfManagedSubscriptionUsers,
        value: user.numberOfManagedSubscriptionUsers
      },
      subscriptionManagerEmail: {
        label: user.subscriptionManagerEmail ? user.subscriptionManagerEmail : '',
        value: user.subscriptionManagerEmail ? user.subscriptionManagerEmail : ''
      },
      verified: {
        label: user.isEmailVerified ? (
          'Yes'
        ) : (
          <Button internalType="danger" onClick={() => verifyEmail(user)}>
            Verify
          </Button>
        ),
        value: user.isEmailVerified ? 'Yes' : 'No'
      },
      isSuperAdmin: { label: user.isSuperAdmin ? 'Yes' : 'No', value: user.isSuperAdmin ? 'Yes' : 'No' },
      is2faEnabled: {
        label: user.is2FaEnabled ? (
          <Button internalType="danger" onClick={() => remove2fa(user)}>
            Remove
          </Button>
        ) : (
          'No'
        ),
        value: user.is2FaEnabled ? 'Yes' : 'No'
      },
      edit: {
        label: (
          <Button internalType="danger" onClick={() => handleEditUser(user)}>
            Edit
          </Button>
        ),
        value: ''
      },
      login: {
        label: user.isEmailVerified ? (
          <Button internalType="secondary" onClick={() => onLogin(user)}>
            Login
          </Button>
        ) : (
          <div />
        ),
        value: ''
      }
    }
  })
}
