import React, { useState, useRef } from 'react'
import Modal, { ModalProps } from 'components/modal'
import { useProject } from 'hooks/project'
import api from 'helpers/api'
import { history } from 'helpers/history'
import { DOCUMENT_BASE, HUB_BASE } from 'routes'
import { useApplicationStore } from 'hooks/application'
import Button from 'components/button'
import { Plus } from 'components/icons'
import { IFolder } from 'types'
import constants from 'style/constants.module.scss'
import Flag from 'components/flag'

type newDocumentTypes = 'Upload DOCX' | 'Blank Document'

const NewProcess: React.FC<ModalProps> = ({ id, open, setOpen }) => {
  const { project, updateProject } = useProject()
  const { displayErrorMessage, setSnackbarMessage } = useApplicationStore()

  const [name, setName] = useState<string>('')
  const [source, setSource] = useState<newDocumentTypes>('Blank Document')
  const inputFile: any = useRef(null)
  const [createDocumentLoading, setCreateDocumentLoading] = useState(false)

  const createProcess = () => {
    const blockCreateDocument =
      name === '' || (source === 'Upload DOCX' && !inputFile.current.files[0]) || createDocumentLoading
    if (!blockCreateDocument) {
      setCreateDocumentLoading(true)
      const file = inputFile.current && inputFile.current.files && inputFile.current.files[0]
      const type = ''

      if (source === 'Blank Document') {
        api
          .createNewProcess({
            projectId: project.publicId,
            name: name,
            type: type,
            context: { projectId: project.publicId }
          })
          .then((response) => {
            updateProject({
              ...project,
              folders: project.folders.map((folder: IFolder) =>
                folder.id === ''
                  ? {
                      ...folder,
                      resources: folder.resources
                        ? [
                            ...folder.resources,
                            {
                              name: response.data.name,
                              type: 'process',
                              process: response.data
                            }
                          ]
                        : [
                            {
                              name: response.data.name,
                              type: 'process',
                              process: response.data
                            }
                          ]
                    }
                  : folder
              )
            })

            setCreateDocumentLoading(false)
            history.push(HUB_BASE + project.publicId + DOCUMENT_BASE + response.data.publicId)
            setOpen(false)
          })
          .catch((error) => {
            displayErrorMessage(error)
            setCreateDocumentLoading(false)
          })
      } else if (source === 'Upload DOCX') {
        if (file && createDocumentLoading === false) {
          setCreateDocumentLoading(true)

          api
            .createNewProcessFromFile(project.publicId, name, type, file)
            .then((response) => {
              let jsonResponse = null

              try {
                jsonResponse = JSON.parse(response as string)
                const process = jsonResponse.data
                updateProject({
                  ...project,
                  folders: project.folders.map((folder: IFolder) =>
                    folder.id === ''
                      ? {
                          ...folder,
                          resources: folder.resources
                            ? [
                                ...folder.resources,
                                {
                                  name: process.name,
                                  type: 'process',
                                  process: process
                                }
                              ]
                            : [
                                {
                                  name: process.name,
                                  type: 'process',
                                  process: process
                                }
                              ]
                        }
                      : folder
                  )
                })
                setCreateDocumentLoading(false)
                history.push(HUB_BASE + project.publicId + DOCUMENT_BASE + process.publicId)
                setOpen(false)
              } catch (error) {
                setSnackbarMessage({
                  status: 'error',
                  message: 'Something went wrong creating the table! Please try again.'
                })
                setCreateDocumentLoading(false)
                return
              }
            })
            .catch((error) => {
              displayErrorMessage(error)
              setCreateDocumentLoading(false)
            })
        }
      }
    } else {
      if (name === undefined || name === '') {
        setSnackbarMessage({
          status: 'error',
          message: 'Please enter a name for the document.'
        })
      } else if (source === 'Upload DOCX' && !inputFile.current.files[0]) {
        setSnackbarMessage({
          status: 'error',
          message: 'Please select a DOCX file to upload.'
        })
      }
    }
  }

  return (
    <Modal id={id} open={open} setOpen={setOpen} title="Create New Document">
      <input
        placeholder="Enter the name of your new document"
        onChange={(event) => setName(event.target.value)}
        autoFocus
        style={{ marginBottom: '20px' }}
      />

      <div className="flex items-center" style={{ gap: '20px' }}>
        <div
          className={`flex flex-column rounded ${
            source === 'Blank Document' ? 'border-blue' : 'border-grey'
          } border-solid border-2px hover-shadow transition-all cursor-pointer`}
          style={{ height: '400px', width: '300px' }}
          onClick={() => setSource('Blank Document')}
        >
          <div
            className="flex items-center justify-center"
            style={{ minHeight: '200px', maxHeight: '200px', backgroundColor: constants.reallyLightGrey }}
          >
            <img
              className="border-solid border-grey shadow"
              src="/assets/images/newdocument.png"
              style={{ height: '90%', width: '50%' }}
            />
          </div>
          <div className="border-t-1px border-solid border-grey" style={{ flex: 1, padding: '10px' }}>
            <div className="font-bold text-lg" style={{ marginBottom: '10px' }}>
              Create from scratch
            </div>
            <div>Start with a blank document and manually add your own sections.</div>
          </div>
        </div>

        <div
          className={`flex flex-column rounded ${
            source === 'Upload DOCX' ? 'border-blue' : 'border-grey'
          } border-solid border-2px hover-shadow transition-all cursor-pointer`}
          style={{ height: '400px', width: '300px' }}
          onClick={() => setSource('Upload DOCX')}
        >
          <div
            className="flex items-center justify-center"
            style={{ minHeight: '200px', maxHeight: '200px', backgroundColor: constants.reallyLightBlue }}
          >
            <img
              className="border-solid border-grey shadow"
              src="/assets/images/docxdocument.png"
              style={{ height: '90%', width: '50%' }}
            />
          </div>
          <div className="border-t-1px border-solid border-grey" style={{ flex: 1, padding: '10px' }}>
            <div className="flex items-baseline">
              <div className="font-bold text-lg" style={{ marginBottom: '10px' }}>
                Create from DOCX file
              </div>

              <Flag text="Beta" color={constants.red} />
            </div>
            <div style={{ marginBottom: '20px' }}>Upload a DOCX file to create your document from this.</div>
            <div style={{ marginBottom: '20px' }}>Tables will not be imported.</div>
            <input
              className="text-sm"
              type="file"
              id="file"
              ref={inputFile}
              accept=".docx"
              style={{ resize: 'none', padding: '5px' }}
            />
          </div>
        </div>
      </div>

      <div className="flex mt-20px">
        <Button
          internalType="accept"
          className="ml-auto text-base"
          onClick={() => createProcess()}
          data-cy="create-document-button"
          disabled={createDocumentLoading}
          isLoading={createDocumentLoading}
        >
          <Plus style={{ marginRight: '5px' }} /> Create New Document
        </Button>
      </div>
    </Modal>
  )
}

export default NewProcess
