import { ICellValue, ITableViewColumn } from 'types'
import { SELECT_COLUMN_TYPES, TEXT_COLUMN_TYPES, ViewTypes } from 'components/spreadsheet/constants/const'
import { IColumnValuesCount, IViewPortColumn } from '../types'
import { GROUP_KEY_SPLIT } from 'components/spreadsheet/constants/const'

export const isReadOnly = (column: IViewPortColumn, isContributor: boolean) => {
  return (
    column.column.locked ||
    !isContributor ||
    column.column.scriptEnabled ||
    column.column.formulaEnabled ||
    column.column.isJoined
  )
}

export const isValidString = (
  value: string,
  stringValidation: string,
  validationMessage: string,
  hardValidation: boolean
) => {
  let regexMatch = false
  try {
    const regex = new RegExp(stringValidation)
    regexMatch = regex.test(value)
  } catch (e) {}
  return { error: !regexMatch, warn: !hardValidation, errorMessage: !regexMatch ? validationMessage : '' }
}

export const isValidSelect = (value: string, allOptions: string[]) => {
  const valid = allOptions.find((option) => option && value && option.toLocaleString() === value.toLocaleString())
  const newValidationMessage = `Warning: That select option is no longer valid`
  return { error: !valid, warn: true, errorMessage: !valid ? newValidationMessage : '' }
}

export const isValidMultiSelect = (value: string[], allOptions: string[]) => {
  const valid = (value as string[])
    .map((currentVal) =>
      allOptions.find((option) => currentVal && option && option.toLocaleString() === currentVal.toLocaleString())
    )
    .every(Boolean)
  const newValidationMessage = `Warning: One of the select options is no longer valid`
  return { error: !valid, warn: true, errorMessage: !valid ? newValidationMessage : '' }
}

export const isValidValue = (
  value: ICellValue,
  rowData:
    | {
        [columnId: string]: ICellValue
      }
    | null
    | undefined,
  seenColumnValues: IColumnValuesCount,
  column: ITableViewColumn,
  formValues?: Record<string, ICellValue>,
  type?: number,
  future?: boolean
): {
  error: boolean
  warn: boolean
  errorMessage: string
} => {
  const kind = column.kind
  const newValidationMessage = column.validationMessage
    ? column.validationMessage
    : formValues
    ? `Value in column ${column.name} does not comply with the column requirements`
    : 'This value does not comply with the column requirements'
  const validation = {
    error: false,
    warn: !column.hardValidation,
    errorMessage: `${column.hardValidation ? 'Error' : 'Warning'}: `
  }

  if (column.validationNoBlanks && (value === null || value === '' || (Array.isArray(value) && value.length === 0))) {
    validation.error = true
  }

  if (column.validationNoDuplicates && value !== undefined && value !== null) {
    if (
      seenColumnValues[column.publicId] &&
      seenColumnValues[column.publicId][value.toString()] !== undefined &&
      seenColumnValues[column.publicId][value.toString()] + (future ? 1 : 0) > 1
    ) {
      validation.error = true
    }
  }

  if (TEXT_COLUMN_TYPES.includes(kind) && column.stringValidation && column.stringValidation !== '') {
    if (value && typeof value === 'string' && value !== '') {
      const newValidation = isValidString(value, column.stringValidation, newValidationMessage, column.hardValidation)

      if (newValidation.error) {
        validation.error = true
      }
    } else {
      validation.error = true
    }
  }

  if (SELECT_COLUMN_TYPES.includes(kind) && !column.isJoined) {
    const kindOptions = column.kindOptions

    const getDependencyCachedOptions = () => {
      if (kindOptions?.tableOptions?.dependencies?.length) {
        const keys: string[] = []
        kindOptions.tableOptions.dependencies.map((dependency) => {
          let cellValue
          if (type === ViewTypes.FORM) {
            cellValue = formValues![dependency.columnId]
          } else {
            cellValue = rowData ? rowData[dependency.columnId] : undefined
          }
          keys.push(`${cellValue}`)
        })
        const finalKey = keys.join(GROUP_KEY_SPLIT)

        const cachedOptionsDict = kindOptions?.tableOptions?.cachedOptions
          ? (kindOptions.tableOptions.cachedOptions as { [key: string]: string[] })
          : {}
        return cachedOptionsDict[finalKey] || []
      }
      return (kindOptions?.tableOptions?.cachedOptions as string[]) || []
    }

    const cachedOptions = getDependencyCachedOptions()

    const manualOptions = kindOptions?.manualOptions || []
    const allOptions = [...cachedOptions, ...manualOptions]
    if (kind === 'select' && value && typeof value === 'string') {
      const newValidation = isValidSelect(value, allOptions)
      if (newValidation.error) {
        validation.error = true
      }
    } else if (kind === 'multiselect' && value && Array.isArray(value)) {
      const newValidation = isValidMultiSelect(value as string[], allOptions)
      if (newValidation.error) {
        validation.error = true
      }
    }
  }

  if (validation.error) {
    validation.errorMessage += newValidationMessage
  }
  return validation
}
