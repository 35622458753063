import React from 'react'
import { useDataContext } from 'components/spreadsheet/contexts/data'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  RadialLinearScale,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Bar, Line, Pie, Doughnut, PolarArea, Radar, Scatter, Bubble } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { getCellColour } from 'components/spreadsheet/helpers/colouring'
import useProject from 'hooks/project'
import { ITableColumn, ITableRow } from 'types'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  RadialLinearScale,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
)

const MULTISELECT_COLUMN_KINDS = ['multiselect', 'multilink']
const NUMERIC_COLUMN_KINDS = ['integer', 'float', 'percentage', 'duration']

const getLabels = (column: ITableColumn, row: ITableRow) => {
  const value = row.rowData[column.publicId]
  if (value === null || value === undefined || value === '') {
    return column.kind === 'checkbox' ? ['false'] : ['']
  }
  // For multiselect columns, assume value is an array of selections
  if (MULTISELECT_COLUMN_KINDS.includes(column.kind) && Array.isArray(value)) {
    return value.map(String)
  }
  // Add any additional formatting for timestamps or JSON as needed.
  return [String(value)]
}

const SpreadsheetChart: React.FC = () => {
  const { project } = useProject()
  const { spreadsheetData } = useDataContext()

  const defaultChartSettings = {
    chartType: null,
    showLegend: true,
    showValues: false,
    columnLabelId: '',
    columnValueId: ''
  }

  const chartSettings =
    spreadsheetData.userConfiguration.chartSettings === null
      ? spreadsheetData.viewDetails.chartSettings === null
        ? defaultChartSettings
        : spreadsheetData.viewDetails.chartSettings
      : spreadsheetData.userConfiguration.chartSettings

  const labelColumn =
    chartSettings && chartSettings.columnLabelId
      ? spreadsheetData.viewDetails.columns.find((column) => column.publicId === chartSettings.columnLabelId)
      : null
  const valueColumn =
    chartSettings && chartSettings.columnValueId
      ? spreadsheetData.viewDetails.columns.find((column) => column.publicId === chartSettings.columnValueId)
      : null

  const isSumAggregation =
    labelColumn && valueColumn
      ? !MULTISELECT_COLUMN_KINDS.includes(labelColumn.kind) && NUMERIC_COLUMN_KINDS.includes(valueColumn.kind)
      : false

  // Aggregate data by label and simultaneously capture a representative colour per label.
  const aggregatedData: { [key: string]: number } = {}
  const aggregatedColours: { [key: string]: string } = {}

  if (labelColumn && valueColumn) {
    spreadsheetData.rows.forEach((row) => {
      const labelsForRow = getLabels(labelColumn, row)
      labelsForRow.forEach((label) => {
        if (!aggregatedData[label]) {
          aggregatedData[label] = 0
          const cellValue = row.rowData[valueColumn.publicId]
          const { backgroundColour } = getCellColour(
            valueColumn,
            spreadsheetData.columnValuesCount,
            row,
            cellValue,
            spreadsheetData.userConfiguration.colourSettings,
            false,
            project.primaryColour
          )
          aggregatedColours[label] = backgroundColour
        }
        const rawValue = row.rowData[valueColumn.publicId]
        const value = rawValue != null ? rawValue : 0
        aggregatedData[label] += isSumAggregation ? Number(value) : 1
      })
    })
  }

  const aggregatedLabels = Object.keys(aggregatedData)
  const aggregatedValues = aggregatedLabels.map((label) => aggregatedData[label])

  const standardChartData = {
    labels: aggregatedLabels,
    datasets: [
      {
        label: valueColumn ? valueColumn.name : '',
        data: aggregatedValues,
        backgroundColor: aggregatedLabels.map((label) => aggregatedColours[label] || project.primaryColour)
      }
    ]
  }

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1,
    plugins: {
      legend: {
        position: 'bottom' as const,
        display: chartSettings ? chartSettings.showLegend : true
      },
      datalabels: {
        display: chartSettings ? chartSettings.showValues : false,
        formatter: (value: any) => Number(value).toLocaleString('en-US'),
        color: 'black',
        anchor: 'end' as const,
        align: 'end' as const,
        offset: 4
      }
    }
  }

  const renderChart = () => {
    if (!chartSettings || !labelColumn || !valueColumn) {
      return <div className="text-center text-gray-500">No data available for the selected chart type</div>
    }
    switch (chartSettings.chartType) {
      case 'bar':
        return <Bar key="bar" options={chartOptions} data={standardChartData} plugins={[ChartDataLabels]} />
      case 'horizontal-bar':
        return <Bar key="horizontal-bar" options={{ ...chartOptions, indexAxis: 'y' }} data={standardChartData} />
      case 'stacked-bar':
        return (
          <Bar
            key="stacked"
            options={{
              ...chartOptions,
              scales: {
                x: { stacked: true },
                y: { stacked: true }
              }
            }}
            data={standardChartData}
          />
        )
      case 'grouped-bar':
        return <Bar key="grouped-bar" options={chartOptions} data={standardChartData} />
      case 'line':
        return <Line key="line" options={chartOptions} data={standardChartData} />
      case 'area':
        return (
          <Line
            key="area"
            options={chartOptions}
            data={{
              ...standardChartData,
              datasets: standardChartData.datasets.map((ds) => ({ ...ds, fill: true }))
            }}
          />
        )
      case 'multiaxis-line':
        return (
          <Line
            key="multiaxis-line"
            options={{
              ...chartOptions,
              scales: {
                y: { type: 'linear', position: 'left' },
                y1: {
                  type: 'linear',
                  position: 'right',
                  grid: { drawOnChartArea: false }
                }
              }
            }}
            data={standardChartData}
          />
        )
      case 'pie':
        return <Pie key="pie" options={chartOptions} data={standardChartData} />
      case 'doughnut':
        return <Doughnut key="doughnut" options={chartOptions} data={standardChartData} />
      case 'polar-area':
        return <PolarArea key="polar-area" options={chartOptions} data={standardChartData} />
      case 'radar':
        return <Radar key="radar" options={chartOptions} data={standardChartData} />
      case 'scatter':
        return <Scatter key="scatter" options={chartOptions} data={standardChartData} />
      case 'bubble': {
        const bubbleData = {
          datasets: [
            {
              label: valueColumn ? valueColumn.name : '',
              data: spreadsheetData.rows.map((row) => ({
                x: Number(row.rowData[labelColumn.publicId]) || 0,
                y: Number(row.rowData[valueColumn.publicId]) || 0,
                r: 10
              })),
              backgroundColor: 'rgba(255, 99, 132, 0.5)'
            }
          ]
        }
        return <Bubble key="bubble" options={chartOptions} data={bubbleData} />
      }
      default:
        return <div className="text-center text-gray-500">Unsupported chart type</div>
    }
  }

  return (
    <div
      className="relative bg-white rounded shadow border-box"
      style={{
        width: '100%',
        height: '100%',
        padding: '40px'
      }}
    >
      {renderChart()}

      <div className="text-secondary text-xs italic truncate" style={{ textAlign: 'right', marginTop: '10px' }}>
        Note: Values are being {isSumAggregation ? 'summed' : 'counted'} in the {valueColumn ? valueColumn.name : ''}{' '}
        column
        <br />
      </div>
    </div>
  )
}

export default SpreadsheetChart
