import React, { useState } from 'react'
import Modal, { ModalProps } from 'components/modal'
import Button from 'components/button'
import api from 'helpers/api'
import { useApplicationStore } from 'hooks/application'

import { useDataContext } from 'components/document/contexts/data'
import Toggle from 'components/toggle'

const ExportModal: React.FC<ModalProps> = ({ id, open, setOpen, onClose }) => {
  const { process } = useDataContext()
  const { displayErrorMessage } = useApplicationStore()
  const [tablesAsLinks, setTablesAsLinks] = useState<boolean>(false)
  const [pageFormat, setPageFormat] = useState<string>('A4')
  const [pageOrientation, setPageOrientation] = useState<string>('portrait')
  const [downloading, setDownloading] = useState<boolean>(false)

  const exportProcess = () => {
    if (process) {
      setDownloading(true)
      api
        .downloadProcess(process.publicId, process.name, tablesAsLinks, pageFormat, pageOrientation)
        .then(() => {
          setDownloading(false)
          setOpen(false)
          if (onClose) onClose()
        })
        .catch((error) => {
          displayErrorMessage(error)
          setDownloading(false)
          setOpen(false)
          if (onClose) onClose()
        })
    }
  }

  return (
    <Modal id={id} open={open} setOpen={setOpen} onClose={onClose} title="Export Word Document">
      <div className="mb-8 flex items-center" style={{ height: '45px' }}>
        <div style={{ width: '60%' }}>Page Format</div>
        <div className="w-full">
          <select value={pageFormat} onChange={(event) => setPageFormat(event.target.value)}>
            <option value="A1">A1</option>
            <option value="A2">A2</option>
            <option value="A3">A3</option>
            <option value="A4">A4</option>
            <option value="letter">Letter</option>
            <option value="legal">Legal</option>
          </select>
        </div>
      </div>
      <div className="mb-8 flex items-center" style={{ height: '45px' }}>
        <div style={{ width: '60%' }}>Page Orientation</div>
        <div className="w-full">
          <select value={pageOrientation} onChange={(event) => setPageOrientation(event.target.value)}>
            <option value="portrait">Portrait</option>
            <option value="landscape">Landscape</option>
          </select>
        </div>
      </div>
      <div className="mb-8 flex items-center" style={{ height: '45px' }}>
        <div style={{ width: '60%' }}>Export All Tables As Links</div>
        <div className="w-full">
          <Toggle toggled={tablesAsLinks} onToggle={(toggled: boolean) => setTablesAsLinks(toggled)} />
        </div>
      </div>
      <div className="mb-8 flex items-center justify-end" style={{ height: '45px' }}>
        <Button
          internalType="danger"
          onClick={() => {
            setOpen(false)
            if (onClose) onClose()
          }}
        >
          Cancel
        </Button>
        <Button
          style={{ marginLeft: '15px' }}
          internalType="accept"
          onClick={() => exportProcess()}
          isLoading={downloading}
        >
          Export
        </Button>
      </div>
    </Modal>
  )
}

export default ExportModal
