import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { history } from 'helpers/history'
import { useAuth } from 'hooks/auth'
import { APIError } from 'helpers/api'
import { HOME, SIGN_IN } from 'routes'
import { redirectTo } from 'helpers/redirect'

const SAMLComplete: React.FC = () => {
  const { updateUser } = useAuth()
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')

  const completeSSO = async () => {
    try {
      const params = new URLSearchParams(location.search)
      const encodedData = params.get('data')

      if (!encodedData) {
        console.error('No user data received')
        history.push(redirectTo(SIGN_IN))
        return
      }

      const decodedData = JSON.parse(decodeURIComponent(encodedData))
      const { user } = decodedData

      if (!user) {
        history.push(redirectTo(SIGN_IN))
        return
      }

      await updateUser(user)
      setLoading(false)
      history.push(redirectTo(HOME))
    } catch (e) {
      if (e instanceof APIError) {
        setErrorMessage(e.message)
      }

      setError(true)
      setLoading(false)
      history.push(redirectTo(SIGN_IN))
    }
  }

  useEffect(() => {
    completeSSO()
  }, [])

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center">
          <p className="text-gray-600">Completing authentication...</p>
        </div>
      </div>
    )
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center">
          <p className="text-red-600">Authentication failed. Please try again.</p>
          <button onClick={() => history.push(redirectTo(SIGN_IN))} className="mt-4 text-blue-600 hover:underline">
            Return to Login
          </button>
        </div>
      </div>
    )
  }

  return (
    <div className="bg-really-light-blue flex flex-1 items-center justify-center overflow-hidden box-border h-screen w-full px-8">
      <div
        className="h-fit flex flex-column bg-white border-1px border-solid border-black p-8 heavy-shadow rounded text-primary"
        style={{ width: '550px' }}
      >
        <h1 className="mb-12 text-2xl">Signing you into your Morta account</h1>
        {error && (
          <div>
            <p>
              {errorMessage === '' && (
                <span>
                  There was an error signing your into your account. If the problem persists contact the adminstrator on
                  your SSO account.
                </span>
              )}
              {errorMessage !== '' && <span>{errorMessage}</span>}
            </p>

            <br />
            <p>
              Return to <Link to={SIGN_IN}>sign in page</Link>.
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

export default SAMLComplete
