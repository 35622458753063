import React, { useState } from 'react'
import Modal, { ModalProps } from 'components/modal'
import Button from 'components/button'
import api from 'helpers/api'
import { useApplicationStore } from 'hooks/application'
import { history } from 'helpers/history'
import { PROJECT } from 'app-constants'
import { capitaliseFirstLetter } from 'helpers/utils'
import { Plus } from 'components/icons'
import { HUB_BASE } from 'routes'

const CreateProjectModal: React.FC<ModalProps> = ({ id, open, setOpen }) => {
  const [newProject, setNewProject] = useState<any>({
    name: `New ${capitaliseFirstLetter(PROJECT)}`,
    template: 'None',
    loading: false
  })

  const { displayErrorMessage } = useApplicationStore()

  const handleClick = async () => {
    setNewProject({ ...newProject, loading: true })
    api({
      method: 'POST',
      endpoint: '/project',
      data: {
        name: newProject.name
      }
    })
      .then((response) => {
        setOpen(false)
        setNewProject({
          name: `New ${capitaliseFirstLetter(PROJECT)}`,
          loading: false
        })
        const projectId = response.data.publicId
        history.push(`${HUB_BASE}${projectId}`)
      })
      .catch((error) => {
        displayErrorMessage(error)
        setNewProject({ ...newProject, loading: false })
      })
  }

  return (
    <Modal id={id} open={open} setOpen={setOpen} title={`Create New ${capitaliseFirstLetter(PROJECT)}`}>
      <div className="mb-8">
        <div className="border-b-2px border-solid border-grey py-10px mb-4 font-bold">
          {capitaliseFirstLetter(PROJECT)} Name
        </div>
        <input
          required
          data-cy="project-name-input"
          placeholder={`The name of your new ${PROJECT}`}
          defaultValue={newProject.name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setNewProject({ ...newProject, name: event.target.value })
          }
        />
      </div>

      <div className="flex justify-end">
        <Button
          data-cy="create-project-button"
          internalType="accept"
          onClick={() => handleClick()}
          isLoading={newProject.loading}
        >
          <Plus style={{ marginRight: '10px' }} />
          Create New {capitaliseFirstLetter(PROJECT)}
        </Button>
      </div>
    </Modal>
  )
}

export default CreateProjectModal
