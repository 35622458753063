import React, { useState } from 'react'
import { ChevronDown, ChevronUp, Cross, Move } from 'components/icons'
import { isValidFilterTypeForColType } from 'components/spreadsheet/helpers/filtering'
import { IFilterType } from 'components/spreadsheet/types'
import { FilterTypesToDescription } from 'components/spreadsheet/constants/const'
import FilterInput from 'components/spreadsheet/components/menu/views/filter/FilterInput'
import { CompactPicker } from 'react-color'
import Select from 'components/select'
import { IColourItemProps } from '.'

interface ColourItemProps extends IColourItemProps {
  onDelete: (index: number) => void
  onChange: (newColour: IColourItemProps, prevColour: IColourItemProps) => void
  handleOrderChange: (position1: number, position2: number) => void
  style: any
  index: number
  optionsOpen?: boolean
}

const ColourItem: React.FC<ColourItemProps> = ({
  columnId,
  columnName,
  filterType,
  value,
  multipleValues,
  backgroundColour,
  fontColour,
  selectedColumns,
  columnOptions,
  kind,
  column,
  style,
  index,
  focus,
  onDelete,
  onChange,
  handleOrderChange,
  optionsOpen
}) => {
  const [fontColourOpen, setFontColourOpen] = useState(false)

  const typesOptions = React.useMemo(() => {
    return Object.values(IFilterType)
      .reduce((acc, type) => {
        acc.push({
          value: type,
          label: FilterTypesToDescription[type]
        })
        return acc
      }, [] as { label: string; value: IFilterType }[])
      .filter((option) => {
        return isValidFilterTypeForColType(kind, option.value)
      })
  }, [kind])

  const handleColumnChange = (selectedColumnId: string) => {
    const foundSelectedColumn = selectedColumns.find((column) => column.value === selectedColumnId)
    let newSelectedColumns = [...selectedColumns]
    if (foundSelectedColumn) {
      newSelectedColumns = selectedColumns.filter((column) => column.value !== selectedColumnId)
    } else {
      const newColumnName = columnOptions.find((column) => column.value === selectedColumnId)!.label
      newSelectedColumns.push({ value: selectedColumnId, label: newColumnName })
    }
    newSelectedColumns.sort((a, b) => a.label.localeCompare(b.label))
    const newColour: IColourItemProps = {
      columnId,
      columnName,
      filterType,
      value,
      multipleValues,
      backgroundColour,
      fontColour,
      selectedColumns: newSelectedColumns,
      kind,
      column,
      columnOptions,
      focus
    }
    // if the newly choosen column kind is not supported by the existing operator
    // then change it
    const foundKind = columnOptions.find((option) => option.value === selectedColumnId)!.kind
    if (!isValidFilterTypeForColType(foundKind, filterType)) {
      newColour.filterType = IFilterType.eq
    }
    onChange(newColour, prevColour)
  }

  const handleDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    const id = event.currentTarget.id

    event.dataTransfer.setData('lowestIndex', id.split('/')[1])
    event.dataTransfer.effectAllowed = 'move'
  }

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.dataTransfer.dropEffect = 'move'
  }

  const handleOnDrop = (event: React.DragEvent<HTMLDivElement>) => {
    const draggedColourNumber = Number(event.dataTransfer.getData('lowestIndex'))
    const currentIndexOfDroppedColour = Number(event.currentTarget.id.split('/')[1])

    if (draggedColourNumber !== undefined && currentIndexOfDroppedColour !== undefined) {
      handleOrderChange(draggedColourNumber, currentIndexOfDroppedColour)
    }
  }
  const handleFilterTypeChange = (newFilterType: IFilterType) => {
    if (newFilterType === IFilterType.one_of || newFilterType === IFilterType.not_one_of) {
      onChange({ ...prevColour, filterType: newFilterType, multipleValues: multipleValues || [] }, prevColour)
    } else if (value !== '') {
      onChange({ ...prevColour, filterType: newFilterType, value: value, multipleValues: [] }, prevColour)
    } else if (
      newFilterType === IFilterType.is_null ||
      newFilterType === IFilterType.is_not_null ||
      newFilterType === IFilterType.is_valid ||
      newFilterType === IFilterType.is_not_valid
    ) {
      onChange({ ...prevColour, filterType: newFilterType, value: '' }, prevColour)
    } else {
      onChange({ ...prevColour, filterType: newFilterType }, prevColour)
    }
  }
  const prevColour: IColourItemProps = {
    columnId,
    columnName,
    filterType,
    value,
    multipleValues,
    backgroundColour,
    fontColour,
    selectedColumns,
    kind,
    column,
    columnOptions,
    focus
  }

  return (
    <div
      className="flex items-center border-b-1px border-solid border-grey"
      style={{ ...style, padding: '20px 10px' }}
      id={`colouritem/${index}`}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDrop={handleOnDrop}
      onDragEnter={handleDragEnter}
      draggable={true}
    >
      <div className="colour-controls">
        <div
          className="flex items-center cursor-pointer text-secondary"
          style={{ marginRight: '10px' }}
          onClick={() => onDelete(index)}
        >
          <Cross />
        </div>
      </div>
      <div style={{ minWidth: '200px', marginRight: '5px' }}>
        <Select
          options={columnOptions}
          optionsSelected={selectedColumns.map((column) => column.value)}
          onOptionClick={(option) => handleColumnChange(option)}
          multiselect={true}
        />
      </div>
      <div style={{ minWidth: '200px', marginRight: '5px' }}>
        <Select
          options={typesOptions}
          optionsSelected={filterType ? [filterType] : []}
          onOptionClick={(option) => handleFilterTypeChange(option as IFilterType)}
        />
      </div>
      <div style={{ width: '300px' }}>
        <FilterInput
          columns={[column]}
          columnId={columnId}
          value={value}
          filterType={filterType}
          multipleValues={multipleValues || []}
          onChange={(value: string) => {
            onChange({ ...prevColour, value: value }, prevColour)
          }}
          onChangeMultipleValues={(multipleValues: string[]) => {
            onChange({ ...prevColour, multipleValues: multipleValues ? multipleValues : [] }, prevColour)
          }}
          focus={focus}
          optionsOpen={optionsOpen}
        />
      </div>
      <div className="flex flex-column" style={{ gap: '20px' }}>
        <div className="flex flex-column" style={{ marginLeft: '20px', marginRight: '20px' }}>
          <div className="font-bold mb-2">Background Colour</div>
          <CompactPicker
            color={backgroundColour}
            onChange={(value) => onChange({ ...prevColour, backgroundColour: value.hex }, prevColour)}
          />
        </div>

        <div className="flex flex-column hover-bg-light-grey" style={{ marginLeft: '20px', marginRight: '20px' }}>
          <div
            className="font-bold mb-2 flex flex-row items-center cursor-pointer"
            onClick={() => setFontColourOpen(!fontColourOpen)}
          >
            <div style={{ marginRight: '10px' }}>Font Colour</div>
            {fontColourOpen ? <ChevronUp /> : <ChevronDown />}
          </div>
          {fontColourOpen && (
            <CompactPicker
              color={fontColour}
              onChange={(value) => onChange({ ...prevColour, fontColour: value.hex }, prevColour)}
            />
          )}
        </div>
      </div>

      <div className="flex items-center cursor-grab text-secondary" style={{ marginRight: '10px' }}>
        <Move />
      </div>
    </div>
  )
}

export default React.memo(ColourItem)
