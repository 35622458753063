import React, { useEffect, useState } from 'react'
import api from 'helpers/api'
import useProject from 'hooks/project'
import { IAiAnswer } from 'types'
import useApplicationStore from 'hooks/application'
import { Table } from 'components/table'

const AI: React.FC = () => {
  const { project } = useProject()
  const { displayErrorMessage } = useApplicationStore()

  const [aiAnswers, setAIAnswers] = useState<IAiAnswer[]>()
  const [loading, setLoading] = useState(false)

  const tableHeaders = [
    { header: 'Created At', id: 'createdAt' },
    { header: 'Question', id: 'question' },
    { header: 'Answer', id: 'answer' },
    { header: 'Vote', id: 'answer_vote' },
    { header: 'Comments', id: 'answer_comment' }
  ]

  useEffect(() => {
    if (project && project.publicId !== '') {
      setLoading(true)
      api
        .getAiAnswers(project.publicId)
        .then((response) => {
          setAIAnswers(response.data)
          setLoading(false)
        })
        .catch((error) => {
          displayErrorMessage(error)
          setLoading(false)
        })
    }
  }, [project.publicId])

  return (
    <div className="flex flex-column w-full h-full overflow-y-scroll" style={{ padding: '10px 30px' }}>
      {aiAnswers && aiAnswers.length === 0 && <div className="text-center">No AI answers found</div>}
      {aiAnswers && aiAnswers.length > 0 && (
        <Table
          data={aiAnswers.map((aiAnswer) => ({
            question: {
              label: <div style={{ whiteSpace: 'pre-wrap', overflow: 'hidden' }}>{aiAnswer.question}</div>,
              value: aiAnswer.question
            },
            answer: {
              label: <div style={{ whiteSpace: 'pre-wrap', overflow: 'hidden' }}>{aiAnswer.answer}</div>,
              value: aiAnswer.answer
            },
            createdAt: {
              label: aiAnswer.createdAt.split('T')[0],
              value: aiAnswer.createdAt
            },
            answer_vote: {
              label: aiAnswer.answerVote === null ? 'n/a' : aiAnswer.answerVote ? '👍' : '👎',
              value: aiAnswer.answerVote === null ? 'n/a' : aiAnswer.answerVote ? 'Good' : 'Bad'
            },
            answer_comment: {
              label: (
                <div style={{ whiteSpace: 'pre-wrap', overflow: 'hidden' }}>
                  {aiAnswer.answerComment ? aiAnswer.answerComment : 'n/a'}
                </div>
              ),
              value: aiAnswer.answerComment === null ? 'n/a' : aiAnswer.answerComment
            }
          }))}
          include={tableHeaders}
          defaultSort={'createdAt'}
          defaultSortAscending={true}
          loading={loading}
        />
      )}
    </div>
  )
}

export default AI
