import React, { useState, useEffect } from 'react'
import { EditorState } from 'draft-js'
import Button from 'components/button'
import Menu from 'components/menu'
import { IContextMenuState, ITable, ITableView } from 'types'
import { INITIAL_CONTEXT_MENU_STATE } from 'app-constants'
import { Spreadsheet } from 'components/icons'
import useProject from 'hooks/project'
import Select from 'components/select'
import { getProjectTables } from 'helpers/utils'
import useApplicationStore from 'hooks/application'
import api from 'helpers/api'

interface TableInsertProps {
  editorState: EditorState
  onChange: (nextEditorState: EditorState) => void
  modifier: (editorState: EditorState, tableId: string, viewId: string, height: number) => EditorState
}

interface AddTableFormProps extends TableInsertProps {
  onClose: () => void
}

const AddTableForm: React.FC<AddTableFormProps> = ({ editorState, onChange, modifier, onClose }: AddTableFormProps) => {
  const { project, variables } = useProject()
  const { displayErrorMessage } = useApplicationStore()

  const [tables, setTables] = useState<ITable[]>()
  const [tableViews, setTableViews] = useState<Array<{ name: string; publicId: string }>>([])

  const defaultHeight = 500
  const [tableId, setTableId] = useState<string>('')
  const [viewId, setViewId] = useState<string>('')
  const [height, setHeight] = useState<number>(defaultHeight)

  useEffect(() => {
    if (project.publicId !== '') {
      getProjectTables(project.publicId)
        .then((tables) => {
          setTables(tables)
        })
        .catch((e) => displayErrorMessage(e))
    }
  }, [project.publicId])

  useEffect(() => {
    if (tableId) {
      api
        .getTableViews(tableId, { ignoreColumns: true })
        .then((viewResponse) => {
          setTableViews(
            viewResponse.data.map((view: ITableView) => {
              return { name: view.name, publicId: view.publicId }
            })
          )
        })
        .catch(() => setTableViews([]))
    }
  }, [tableId])

  const handleSubmit = () => {
    onChange(modifier(editorState, tableId, viewId, height))
    handleClose()
  }

  const handleClose = () => {
    setTableId('')
    setViewId('')
    setHeight(defaultHeight)
    onClose()
  }

  const handleKeyPressed = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleSubmit()
    } else if (event.key === 'Escape') {
      handleClose()
    }
  }

  return (
    <div className="flex" style={{ padding: '12px 20px', gap: '15px', alignItems: 'end' }}>
      <div className="flex flex-column" style={{ width: '185px' }}>
        <span className="text-secondary italic" style={{ marginBottom: '3px' }}>
          Select Table
        </span>
        <Select
          options={
            tables
              ? tables
                  .slice()
                  .sort((a: ITable, b: ITable) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
                  .map((table: ITable) => {
                    const rawGroup = table.type ? table.type : 'No Type'
                    const groupName =
                      variables && rawGroup && rawGroup in variables ? variables[rawGroup].label : rawGroup
                    return { value: table.publicId, label: table.name, group: groupName }
                  })
              : []
          }
          onOptionClick={(option) => {
            if (tables) {
              const table = tables.find((table) => table.publicId === option)
              if (table) {
                setTableId(table.publicId)
                setViewId(table.defaultViewId)
              }
            }
          }}
          groupBy={true}
          optionsSelected={tableId ? [tableId] : []}
        />
      </div>
      <div className="flex flex-column" style={{ width: '185px' }}>
        <span className="text-secondary italic" style={{ marginBottom: '3px' }}>
          Select View
        </span>
        <Select
          options={
            tableViews
              ? tableViews
                  .sort((a: { name: string; publicId: string }, b: { name: string; publicId: string }) =>
                    a.name > b.name ? 1 : a.name < b.name ? -1 : 0
                  )
                  .map((view) => {
                    return { value: view.publicId, label: view.name }
                  })
              : []
          }
          onOptionClick={(option) => setViewId(option)}
          optionsSelected={viewId ? [viewId] : []}
        />
      </div>
      <div className="flex flex-column" style={{ width: '185px' }}>
        <span className="text-secondary italic" style={{ marginBottom: '3px' }}>
          Select Height
        </span>
        <input
          id="iframe-height"
          type="number"
          value={height}
          min={300}
          step={50}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setHeight(parseInt(event.target.value))}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => handleKeyPressed(event)}
        />
      </div>

      <Button
        style={{ marginLeft: '7px', width: '70px', height: '45px' }}
        onClick={() => handleSubmit()}
        internalType="accept"
      >
        + Add
      </Button>
    </div>
  )
}

interface AddIconProps {
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void
}

const AddTableIcon: React.FC<AddIconProps> = ({ onClick }) => {
  return (
    <div
      title="Add Morta Table"
      className="flex items-center h-full cursor-pointer italic hover-bg-light-grey"
      onClick={onClick}
    >
      <Spreadsheet />
      <span style={{ marginLeft: '5px' }}>Morta table</span>
    </div>
  )
}

const TableInsert: React.FC<TableInsertProps> = ({ editorState, onChange, modifier }) => {
  const [tableMenu, setTableMenu] = useState<IContextMenuState>(INITIAL_CONTEXT_MENU_STATE)

  const tableMenuWidth = 750
  const offset = 500

  return (
    <div>
      <AddTableIcon
        onClick={(event: React.MouseEvent<HTMLDivElement>) => {
          setTableMenu({
            open: true,
            top: `${event.currentTarget.getBoundingClientRect().bottom + 10}px`,
            left: `${event.currentTarget.getBoundingClientRect().left - tableMenuWidth + offset}px`,
            right: 'auto',
            bottom: 'auto'
          })
        }}
      />
      {tableMenu.open && (
        <Menu
          id="table-insert-menu"
          menuState={tableMenu}
          setMenuState={setTableMenu}
          width={tableMenuWidth}
          zIndex={1000}
        >
          <AddTableForm
            editorState={editorState}
            onChange={onChange}
            onClose={() => setTableMenu(INITIAL_CONTEXT_MENU_STATE)}
            modifier={modifier}
          />
        </Menu>
      )}
    </div>
  )
}

export default TableInsert
