import React from 'react'
import useAuth from 'hooks/auth'

interface SubscriptionProps {
  type: 'advanced' | 'scale'
  message: string
}

const Message = ({ message }: { message: string }) => (
  <div className="text-sm mb-4 font-bold flex items-center justify-center p-2 rounded bg-alert border-1px border-solid border-black">
    Contact{' '}
    <a href="mailto:mo@morta.io" style={{ marginLeft: '5px', marginRight: '5px' }}>
      mo@morta.io
    </a>{' '}
    to upgrade to {message}.
  </div>
)

const Subscription: React.FC<SubscriptionProps> = ({ type, message }) => {
  const { user, onAdvancedPlan } = useAuth()
  if (user && type === 'scale' && !user.onScalePlan) {
    return <Message message={`the Scale Plan to ${message}`} />
  } else if (user && type === 'advanced' && !onAdvancedPlan) {
    return <Message message={`an Advanced Plan to ${message}`} />
  } else return null
}

export default Subscription
