import React, { useEffect, useRef, useState } from 'react'
import { DataContextProvider, useDataContext } from 'components/document/contexts/data'
import Editor from 'components/editor'
import { IContextMenuState, IHeadingStyle, IProcessObject, IProcessResponseObject, IProcessSectionObject } from 'types'
import Response from 'components/document/components/response'
import ErrorBoundary from 'components/error'
import Navigation from 'components/document/components/navigation'
import Toolbar from 'components/document/components/toolbar'
import { Triangle, Dots, Spreadsheet } from 'components/icons'
import CommentsComponent from 'components/comments'
import Image from 'components/document/components/image'
import EditSection from 'components/document/components/modal/sectionEdit'
import { INITIAL_CONTEXT_MENU_STATE } from 'app-constants'
import SectionMenu from 'components/document/components/menu/views/section'
import DeleteResource from 'components/delete'
import useProject from 'hooks/project'
import {
  addSectionToProcess,
  findSection,
  findSectionParent,
  removeSectionFromProcess,
  getFlatData,
  doesSectionContain,
  scrollToSection
} from 'components/document/helpers/functions'
import useApplicationStore from 'hooks/application'
import api from 'helpers/api'
import { useLocation } from 'react-router-dom'
import { getSectionDisplayNumber } from './helpers/formatting'
import useOnScreen from 'hooks/screen'
import ArchivedWarning from 'components/archived'
import { Link } from 'react-router-dom'
import ResourceDeleted from 'views/auth/views/resourceDeleted'
import Powered from 'components/document/components/powered'
import useAuth from 'hooks/auth'

interface IDragOver {
  type: string
  source: string
  target: string
}

interface DocumentProps {
  processPublicId: string
  permissionCap?: number
  templateRender?: boolean
}

const Document: React.FC<DocumentProps> = ({ processPublicId, permissionCap, templateRender }) => {
  return (
    <DataContextProvider processPublicId={processPublicId} permissionCap={permissionCap}>
      <DocumentComponent templateRender={templateRender} />
    </DataContextProvider>
  )
}

interface ProcessSectionProps {
  section: IProcessSectionObject
  sectionNumber: string
  level: number
  addSection: (parentId: string | null) => void
  dragOver: IDragOver
  setDragOver: (dragOver: IDragOver) => void
  handleDragStart: (event: React.DragEvent<HTMLDivElement>) => void
  handleDragEnter: (event: React.DragEvent<HTMLDivElement>) => void
  handleDragEnd: () => void
  formatting: IHeadingStyle
  templateRender?: boolean
}

const ProcessSection: React.FC<ProcessSectionProps> = ({
  section,
  sectionNumber,
  level,
  addSection,
  dragOver,
  setDragOver,
  handleDragStart,
  handleDragEnd,
  handleDragEnter,
  formatting,
  templateRender
}) => {
  const { process, updateProcess, setSection, isAdmin, editMode } = useDataContext()
  const { project } = useProject()
  const { displayErrorMessage } = useApplicationStore()
  const { user } = useAuth()

  const sectionRef = useRef<HTMLDivElement>(null)

  const isVisible = useOnScreen(sectionRef)

  useEffect(() => {
    if (section.visible !== isVisible) {
      setSection({ ...section, visible: isVisible }, true)
    }
  }, [isVisible])

  const [expandComments, setExpandComments] = useState<boolean>(false)
  const [editSectionModal, setEditSectionModal] = useState<boolean>(false)
  const [settingsMenu, setSettingsMenu] = useState<IContextMenuState>(INITIAL_CONTEXT_MENU_STATE)
  const [deleteSectionModal, setDeleteSectionModal] = useState<boolean>(false)
  const [archivingProcessSection, setArchivingProcessSection] = useState<{
    isArchiving: boolean
    status: string | null
  }>({
    isArchiving: false,
    status: null
  })

  const settingsWidth = 300

  const deleteSection = (): void => {
    if (process && section) {
      if (!archivingProcessSection.isArchiving) {
        setArchivingProcessSection({ isArchiving: true, status: null })
        api
          .deleteProcessSection(project.publicId, process.publicId, section.publicId)
          .then(() => {
            setArchivingProcessSection({ isArchiving: false, status: 'success' })
            setDeleteSectionModal(false)
            let children = [...process.children]
            children = removeSectionFromProcess(section.publicId, children)
            updateProcess({ ...process, children: children })
          })
          .catch((error) => {
            displayErrorMessage(error)
            setArchivingProcessSection({ isArchiving: false, status: 'error' })
            setDeleteSectionModal(false)
          })
      }
    }
  }

  const dragId = `${dragOver.type}/${dragOver.target}`
  const aboveId = `above/${section.publicId}`
  const withinId = `within/${section.publicId}`
  const belowId = `below/${section.publicId}`
  const dragHeights = 4
  const draggingSelf = dragOver.source === section.publicId

  return (
    <div>
      <div className="relative" style={{ margin: '12.5px 0px', userSelect: 'none' }}>
        <div
          ref={sectionRef}
          id={`section/${section.publicId}`}
          className="flex items-center"
          style={{ padding: '5px 2px' }}
        >
          {/* 
        
        ----------------
        Dragging Areas
        ----------------
        
        */}

          <div
            id={aboveId}
            className="absolute w-full"
            style={{
              top: 0,
              height: `${dragHeights}px`,
              backgroundColor: dragId === aboveId && !draggingSelf ? project.primaryColour : 'white'
            }}
            onDragEnter={handleDragEnter}
          />

          <div
            id={belowId}
            className="absolute w-full"
            style={{
              top: `calc(62px - ${dragHeights}px)`,
              height: `${dragHeights}px`,
              backgroundColor: dragId === belowId && !draggingSelf ? project.primaryColour : 'white'
            }}
            onDragEnter={handleDragEnter}
          />

          {/* 
        
        ----------------
        Section Content
        ----------------
        
        */}

          <div
            id={withinId}
            className="flex items-center w-full"
            style={{ border: dragId === withinId ? `2px solid ${project.primaryColour}` : '2px solid white' }}
            draggable={true}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragEnter={handleDragEnter}
          >
            <div
              className={`flex items-center justify-center rounded p-10px select-none transition-all ${
                editMode ? 'cursor-grab' : 'cursor-pointer'
              } hover-bg-light-grey`}
              title={section.expanded ? 'Collapse Section' : 'Expand Section'}
              onClick={() => setSection({ ...section, expanded: section.expanded ? false : true }, true)}
              style={{ padding: '10px 10px 10px 0px' }}
            >
              <div className="text-secondary">
                <Triangle type={section.expanded ? 'down' : 'right'} />
              </div>
            </div>
            <div className="flex w-full" draggable={true} onDragStart={(event) => event.preventDefault()}>
              <div
                className={`flex flex-column justify-center ${formatting.bold ? 'font-bold' : ''} ${
                  formatting.italic ? 'italic' : ''
                } ${formatting.underline ? 'underline' : ''}`}
                style={{ color: formatting.colour, fontSize: `${formatting.fontSize * 100}%` }}
              >
                {sectionNumber}&nbsp;
              </div>
              <input
                className={`${formatting.bold ? 'font-bold' : ''} ${formatting.italic ? 'italic' : ''} ${
                  formatting.underline ? 'underline' : ''
                } rounded transition-all w-full box-border ${editMode ? 'border-1px' : 'border-0'} bg-white p-0`}
                id={`process-section-${section.publicId}-input`}
                style={{ color: formatting.colour, fontSize: `${formatting.fontSize * 100}%`, transition: 'all 0s' }}
                readOnly={!isAdmin || !editMode}
                value={section.name}
                onChange={(event) => setSection({ ...section, name: event.target.value })}
              />
            </div>
            {!user && <Powered />}
            {!templateRender && process?.allowComments && !editMode && (
              <div
                draggable={true}
                onDragStart={(event) => event.preventDefault()}
                className="flex items-center justify-center rounded select-none transition-all cursor-pointer hover-bg-light-grey"
                style={{ marginLeft: 'auto', padding: '10px 4px' }}
                title="Section Comments"
              >
                <CommentsComponent
                  selected={expandComments}
                  referenceId={section.publicId}
                  referenceType="process_section"
                  openCommentThreads={section.openCommentThreads}
                  permissionLevel={process ? process.permissionLevel : 0}
                  onSelectedComment={() => {
                    setExpandComments(true)
                  }}
                  onCommentThreadsChange={() => {
                    void 0
                  }}
                />
              </div>
            )}
            {isAdmin && editMode && (
              <div
                draggable={true}
                onDragStart={(event) => event.preventDefault()}
                className="flex items-center justify-center rounded select-none transition-all cursor-pointer hover-bg-light-grey"
                style={{ padding: '10px 4px' }}
                onClick={() => {
                  setEditSectionModal(true)
                }}
                title={'Edit Section Responses'}
              >
                <Spreadsheet />
              </div>
            )}

            {!templateRender && (
              <div
                draggable={true}
                onDragStart={(event) => event.preventDefault()}
                className="flex items-center justify-center rounded select-none transition-all cursor-pointer hover-bg-light-grey"
                style={{ padding: '10px 4px' }}
                title={'Section Settings'}
                onClick={(event: React.MouseEvent) =>
                  setSettingsMenu({
                    open: true,
                    top: `${event.currentTarget.getBoundingClientRect().bottom + 10}px`,
                    left: `${event.currentTarget.getBoundingClientRect().left - settingsWidth + 20}px`,
                    right: 'auto',
                    bottom: 'auto'
                  })
                }
              >
                <Dots />
              </div>
            )}
          </div>
        </div>

        {section.expanded && (
          <div>
            {((isAdmin && editMode) ||
              (isAdmin &&
                section.description &&
                !(
                  section.description.content.blocks.length === 1 && section.description.content.blocks[0].text === ''
                )) ||
              (!isAdmin && section.description)) &&
              process && (
                <Editor
                  databaseDoc={section.description}
                  readOnly={!isAdmin || !editMode}
                  editorId={section.publicId}
                  onChange={(content) => {
                    setSection({ ...section, description: content })
                  }}
                  border={editMode}
                  process={process}
                  processSectionId={section.publicId}
                  resources={[{ resource: 'process', publicId: process.publicId }]}
                />
              )}

            {section.responses.map((response: IProcessResponseObject) => {
              return (
                <ErrorBoundary key={section.publicId + '-' + response.publicId}>
                  <div style={{ marginTop: '10px' }}>
                    <Response
                      key={section.publicId + '-' + response.publicId}
                      section={section}
                      response={response}
                    ></Response>
                  </div>
                </ErrorBoundary>
              )
            })}
          </div>
        )}
      </div>
      {section.expanded &&
        section.children.map((subsection: IProcessSectionObject, subsectionNumber: number) => {
          const subsectionFormatting = project.headingStyles[level] ? project.headingStyles[level] : formatting
          const subsectionDisplayNumber = getSectionDisplayNumber(sectionNumber, subsectionNumber, subsectionFormatting)
          return (
            <ProcessSection
              key={subsection.publicId}
              section={subsection}
              sectionNumber={subsectionDisplayNumber}
              level={level + 1}
              addSection={addSection}
              dragOver={dragOver}
              setDragOver={setDragOver}
              handleDragEnd={handleDragEnd}
              handleDragEnter={handleDragEnter}
              handleDragStart={handleDragStart}
              formatting={subsectionFormatting}
            />
          )
        })}

      {editSectionModal && (
        <EditSection id="edit-section-modal" open={editSectionModal} setOpen={setEditSectionModal} section={section} />
      )}

      <SectionMenu
        id={`section-settings-context-menu`}
        menuState={settingsMenu}
        setMenuState={setSettingsMenu}
        width={settingsWidth}
        section={section}
        setDeleteSectionModal={setDeleteSectionModal}
        addSection={addSection}
        sectionNumber={sectionNumber}
      />

      {deleteSectionModal && (
        <DeleteResource
          id="delete-process-section-modal"
          open={deleteSectionModal}
          setOpen={setDeleteSectionModal}
          resourceName={section.name}
          deleteResource={() => deleteSection()}
        />
      )}
    </div>
  )
}

interface DocumentComponentProps {
  templateRender?: boolean
}

const DocumentComponent: React.FC<DocumentComponentProps> = ({ templateRender }) => {
  const {
    process,
    updateProcess,
    setSection,
    isAdmin,
    editMode,
    navigationMenu,
    expandAllSections,
    errorStatusCode
  } = useDataContext()
  const { project } = useProject()
  const { displayErrorMessage, setSnackbarMessage } = useApplicationStore()
  const [dragOver, setDragOver] = useState<IDragOver>({ type: '', source: '', target: '' })

  const handleDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    if (editMode) {
      const id = event.currentTarget.id
      setDragOver({ ...dragOver, source: id.split('/')[1] })
    }
  }

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    if (editMode && dragOver.source !== '') {
      const targetSectionId = event.currentTarget.id
      setDragOver({ ...dragOver, type: targetSectionId.split('/')[0], target: targetSectionId.split('/')[1] })
    }
  }

  const handleDragEnd = () => {
    if (editMode && dragOver.target !== '' && dragOver.source !== '' && dragOver.source !== dragOver.target) {
      const type = dragOver.type
      const source = dragOver.source
      const target = dragOver.target

      setDragOver({ type: '', target: '', source: '' })

      if (process) {
        const sectionParent = findSectionParent(source, process)
        const sourceSection = findSection(source, process)
        const targetSection = findSection(target, process)
        const targetParentSection = findSectionParent(target, process)

        // If same parent then cancel
        if (type === 'within' && sectionParent === target) return

        let children = [...process.children]
        children = removeSectionFromProcess(source, children)

        if (type === 'within') {
          const parentId = targetSection.section ? targetSection.section.publicId : null
          children = addSectionToProcess(parentId, children, { ...sourceSection.section, parentId })
        } else if (type === 'above') {
          if (targetSection.index - 1 === sourceSection.index) return
          const parentId = targetParentSection && targetParentSection !== process.publicId ? targetParentSection : null
          children = addSectionToProcess(parentId, children, sourceSection.section, targetSection.index)
        } else if (type === 'below') {
          if (targetSection.index + 1 === sourceSection.index) return
          const parentId = targetParentSection && targetParentSection !== process.publicId ? targetParentSection : null
          children = addSectionToProcess(parentId, children, sourceSection.section, targetSection.index)
        }

        const newProcess = { ...process, children: children }
        updateProcess(newProcess, true)

        const processSections = getFlatData(newProcess)
        api
          .updateProcessSectionOrder({
            processSections,
            processId: process.publicId,
            context: { projectId: project.publicId }
          })

          .catch((error) => {
            displayErrorMessage(error)
          })
      }
    } else {
      setDragOver({ type: '', target: '', source: '' })
    }
  }

  const query = new URLSearchParams(useLocation().search)
  const urlSectionId = query.get('section')
  const autoExpandSections = query.get('expandSections')

  const expandSections = async (parent: IProcessObject | IProcessSectionObject, sectionId: string) => {
    for (let i = 0; i < parent.children.length; i++) {
      const section = parent.children[i]

      if (section.publicId === sectionId || doesSectionContain(section, sectionId)) {
        setSection({ ...section, expanded: true }, true)
      }
      expandSections(section, sectionId)
    }
  }

  useEffect(() => {
    if (process && urlSectionId) {
      expandSections(process, urlSectionId).then(() => {
        scrollToSection(urlSectionId)
      })
    }
  }, [process?.publicId])

  useEffect(() => {
    if (process && (process.expandByDefault || autoExpandSections)) {
      expandAllSections(process, true)
    }
  }, [process?.expandByDefault])

  const addSection = (parentId: null | string) => {
    if (process) {
      api
        .createNewProcessSection({
          name: 'New Document Section',
          processId: process.publicId,
          parentId: parentId,
          plaintextDescription: 'Here is the description of your document section.',
          context: { projectId: project.publicId }
        })
        .then((response) => {
          setSnackbarMessage({ status: 'success' })
          let children: IProcessSectionObject[] = [...process.children]
          if (!parentId) {
            children.push(response.data)
          } else {
            children = addSectionToProcess(parentId, children, response.data)
          }

          updateProcess({ ...process, children: children })
        })
        .catch((error) => {
          displayErrorMessage(error)
        })
    }
  }

  const dragId = `${dragOver.type}/${dragOver.target}`
  const withinProcessId = `within/${process ? process.publicId : ''}`
  const formatting = project.headingStyles[0]
    ? project.headingStyles[0]
    : {
        fontSize: 1.4,
        colour: project.processTitleColour,
        bold: project.processTitleBold,
        italic: project.processTitleItalic,
        underline: project.processTitleUnderline
      }

  if (
    !templateRender &&
    process &&
    process.projectPublicId !== '' &&
    project.publicId !== '' &&
    process.projectPublicId !== project.publicId
  ) {
    return <ResourceDeleted />
  } else if (process && errorStatusCode === null)
    return (
      <div className="flex flex-row flex-wrap w-full h-full background-white overflow-auto">
        {process.isDeleted && <ArchivedWarning message={'This resource has been archived'} />}
        {!templateRender && <Toolbar addSection={addSection} />}
        {navigationMenu && <Navigation />}
        <div className="flex flex-column w-full h-full overflow-hidden text-primary">
          <div style={{ overflow: 'auto', background: 'white', userSelect: 'none' }}>
            <Image />

            <div className="relative process-margin">
              {isAdmin && editMode ? (
                <input
                  id={withinProcessId}
                  className={`${project.processTitleBold ? 'font-bold' : ''} ${
                    project.processTitleItalic ? 'italic' : ''
                  } ${
                    project.processTitleUnderline ? 'underline' : ''
                  } rounded transition-all w-full box-border border-0 bg-white p-0`}
                  style={{
                    color: project.processTitleColour,
                    fontSize: `${project.processTitleFontSize * 100}%`,
                    border: dragId === withinProcessId ? `2px solid ${project.primaryColour}` : '1px solid black'
                  }}
                  value={process.name}
                  onChange={(event) => updateProcess({ ...process, name: event.target.value })}
                  onDragEnter={handleDragEnter}
                />
              ) : (
                <div
                  className={`${project.processTitleBold ? 'font-bold' : ''} ${
                    project.processTitleItalic ? 'italic' : ''
                  } ${
                    project.processTitleUnderline ? 'underline' : ''
                  } rounded transition-all w-full box-border border-0 bg-white p-0`}
                  style={{
                    color: project.processTitleColour,
                    fontSize: `${project.processTitleFontSize * 100}%`,
                    lineHeight: '1em',
                    border: dragId === withinProcessId ? `2px solid ${project.primaryColour}` : '2px solid white',
                    height: 'fit-content',
                    textAlign: project.processTitleAlignment,
                    userSelect: 'text'
                  }}
                >
                  {process.name}
                </div>
              )}

              {process.createdBy && (
                <div className="flex items-center" style={{ marginTop: '30px', marginBottom: '30px' }}>
                  <img
                    src={
                      process.createdBy.profilePicture ? process.createdBy.profilePicture : '/assets/images/user.png'
                    }
                    style={{ height: '25px', width: '25px', borderRadius: '50%' }}
                  />
                  <div className="text-secondary" style={{ marginLeft: '5px' }}>
                    Created by{' '}
                    <Link
                      to={`/profile/${process.createdBy.firebaseUserId}/information`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {process.createdBy.name}
                    </Link>
                  </div>
                </div>
              )}

              {((isAdmin && editMode) ||
                (isAdmin &&
                  process.description &&
                  !(
                    process.description.content.blocks.length === 1 && process.description.content.blocks[0].text === ''
                  )) ||
                (!isAdmin && process.description)) && (
                <Editor
                  databaseDoc={process.description ? process.description : null}
                  readOnly={!isAdmin || !editMode}
                  editorId={process.publicId}
                  onChange={(content) => {
                    updateProcess({ ...process, description: content })
                  }}
                  border={editMode}
                  process={process}
                  resources={[{ resource: 'process', publicId: process.publicId }]}
                />
              )}

              {process.children.map((section: IProcessSectionObject, sectionNumber: number) => {
                const sectionDisplayNumber = getSectionDisplayNumber('', sectionNumber, formatting)
                return (
                  <ProcessSection
                    key={section.publicId}
                    section={section}
                    sectionNumber={sectionDisplayNumber}
                    level={1}
                    addSection={addSection}
                    dragOver={dragOver}
                    setDragOver={setDragOver}
                    handleDragStart={handleDragStart}
                    handleDragEnd={handleDragEnd}
                    handleDragEnter={handleDragEnter}
                    formatting={formatting}
                    templateRender={templateRender}
                  />
                )
              })}
            </div>
          </div>
        </div>
      </div>
    )
  else if (errorStatusCode === 404) {
    return <ResourceDeleted />
  } else {
    return (
      <div className="flex flex-column justify-center items-center w-full h-full overflow-hidden text-primary">
        <span className="spin" />
      </div>
    )
  }
}

export default Document
