import React, { useEffect, useState } from 'react'
import Select from 'components/select'
import api from 'helpers/api'
import useProject from 'hooks/project'
import { IFolderContents } from 'types'
import ResourceType from 'components/resourceType'
import Button from 'components/button'
import useApplicationStore from 'hooks/application'
import { formatDateTime } from 'components/spreadsheet/helpers/format'
import { capitaliseFirstLetter } from 'helpers/utils'

const BulkTypesUpdate: React.FC = () => {
  const { project, variables } = useProject()
  const { setSnackbarMessage } = useApplicationStore()

  const [resources, setResources] = useState<IFolderContents[]>()
  const [selectedResources, setSelectedResources] = useState<string[]>([])
  const [newResourceType, setNewResourceType] = useState<string>('')
  const [savingResources, setSavingResources] = useState<boolean>(false)

  useEffect(() => {
    if (project && project.publicId !== '') {
      api.getProjectResources(project.publicId, {}).then((response) => {
        setResources(response.data)
      })
    }
  }, [project.publicId])

  const bulkUpdateResources = async () => {
    if (resources) {
      if (window.confirm('Are you sure you want to bulk update these resources?')) {
        setSavingResources(true)
        let updateNumber = 1
        for (let i = 0; i < selectedResources.length; i++) {
          const selectedResourceId = selectedResources[i]
          const resource = resources.find((resource) =>
            resource.process
              ? resource.process.publicId === selectedResourceId
              : resource.table
              ? resource.table.publicId === selectedResourceId
              : false
          )
          if (resource) {
            if (resource.type === 'process') {
              try {
                await api.updateProcess({
                  processId: selectedResourceId,
                  type: newResourceType,
                  context: {
                    projectId: project.publicId
                  }
                })

                setSnackbarMessage({
                  status: 'success',
                  message: `Successfully updated ${resource.name} type (update ${updateNumber} of ${selectedResources.length})`
                })
              } catch {
                setSnackbarMessage({
                  status: 'error',
                  message: `Unsuccessfully updated ${resource.name} type (update ${updateNumber} of ${selectedResources.length})`
                })
              }
            } else if (resource.type === 'table') {
              try {
                await api.updateTable(selectedResourceId, {
                  name: resource.name,
                  type: newResourceType,
                  context: {
                    projectId: project.publicId
                  }
                })

                setSnackbarMessage({
                  status: 'success',
                  message: `Successfully updated ${resource.name} type (update ${updateNumber} of ${selectedResources.length})`
                })
              } catch {
                setSnackbarMessage({
                  status: 'error',
                  message: `Unsuccessfully updated ${resource.name} type (update ${updateNumber} of ${selectedResources.length})`
                })
              }
            }

            updateNumber++
          }
        }
        setSelectedResources([])
        setSavingResources(false)
      }
    }
  }

  return (
    <div className="w-full" style={{ padding: '10px 30px' }}>
      <div className="mb-30px">
        <div className="flex flex-row items-center w-full text-lg font-bold mt-10px mb-30px py-10px border-b-2px border-solid border-grey">
          Select Resources
        </div>
        <Select
          options={
            resources
              ? resources
                  .sort((resourceA, resourceB) =>
                    resourceA.name > resourceB.name ? 1 : resourceB.name > resourceA.name ? -1 : 0
                  )
                  .map((resource) => {
                    const rawGroup =
                      resource.process && resource.process.type
                        ? resource.process.type
                        : resource.table && resource.table.type
                        ? resource.table.type
                        : 'No Type'

                    const groupName =
                      variables && rawGroup && rawGroup in variables ? variables[rawGroup].label : rawGroup

                    const resourceCreatedAt = resource.process
                      ? resource.process.createdAt
                      : resource.table
                      ? resource.table.createdAt
                      : ''

                    const resourceName =
                      resource.name + ' (' + formatDateTime(resourceCreatedAt + 'Z', 'YYYY-MM-DD HH:mm') + ')'
                    const resourceType = resource.type === 'process' ? 'document' : resource.type

                    return {
                      label: `${capitaliseFirstLetter(resourceType)}: ${resourceName}`,
                      value: resource.process
                        ? resource.process.publicId
                        : resource.table
                        ? resource.table.publicId
                        : 'No Type',
                      group: groupName
                    }
                  })
              : []
          }
          groupBy={true}
          optionsSelected={selectedResources}
          onOptionClick={(option) => {
            const newResourcesSelected = [...selectedResources]
            const index = newResourcesSelected.findIndex((resource) => resource === option)
            if (index === -1) {
              newResourcesSelected.push(option)
            } else {
              newResourcesSelected.splice(index, 1)
            }
            setSelectedResources(newResourcesSelected)
          }}
          multiselect={true}
          setOptionsSelected={(options) => setSelectedResources(options)}
          loading={!resources}
        />
      </div>
      <div className="mb-30px">
        <div className="flex flex-row items-center w-full text-lg font-bold mt-10px mb-30px py-10px border-b-2px border-solid border-grey">
          <div style={{ marginRight: 'auto' }}>New Resources Type</div>
        </div>

        <ResourceType
          handleChange={(value: string | null) => {
            if (value) {
              setNewResourceType(value)
            } else {
              setNewResourceType('')
            }
          }}
          defaultValue={''}
          helperText="Set the new resource type"
        />
      </div>
      <div className="mb-30px">
        <div className="flex flex-row items-center w-full text-lg font-bold mt-10px mb-30px py-10px border-b-2px border-solid border-grey">
          Apply Changes
        </div>
        <Button
          internalType="danger"
          onClick={() => bulkUpdateResources()}
          disabled={selectedResources.length === 0}
          isLoading={savingResources}
        >
          Bulk Save Changes
        </Button>
      </div>
    </div>
  )
}

export default BulkTypesUpdate
