import React, { useEffect } from 'react'
import { useProject } from 'hooks/project'
import InformationBox from 'views/settings/components/information'
import Formatting from 'views/settings/components/formatting'
import PermissionsBox from 'views/settings/components/permissions'
import Notifications from 'views/settings/components/notifications'
import Secrets from 'views/settings/components/secrets'
import InvitedBox from 'views/settings/components/invited'
import ErrorBoundary from 'components/error'
import Audit from 'components/audit'
import BulkUpdates from 'views/settings/components/bulkUpdates'
import DeletedResources from 'views/settings/components/deleted'
import Nav from 'components/nav'
import AI from './components/ai'

const ProjectSettings: React.FC = () => {
  const { project, projectOwner } = useProject()

  useEffect(() => {
    document.title = `${project.name} Settings`
  }, [project.loading])

  const [selection, changeSelection] = React.useState<string>('Information')
  let sections = []

  if (projectOwner && !project.isDeleted) {
    if (project.aiSearchEnabled) {
      sections = [
        'Information',
        'AI Q&A',
        'Styling',
        'Bulk Updates',
        'Deleted Resources',
        'Audit Log',
        'Members',
        'Sent Invites',
        'Webhooks',
        'Secrets'
      ]
    } else {
      sections = [
        'Information',
        'Styling',
        'Bulk Updates',
        'Deleted Resources',
        'Audit Log',
        'Members',
        'Sent Invites',
        'Webhooks',
        'Secrets'
      ]
    }
  } else {
    sections = ['Information']
  }

  return (
    <div className="flex flex-column w-full h-full overflow-auto">
      <Nav
        sections={sections}
        selectedSection={selection}
        onClick={(section) => changeSelection(section)}
        primaryColor={project.primaryColour}
      />

      <ErrorBoundary>
        {selection === 'Information' && <InformationBox />}
        {projectOwner && project.aiSearchEnabled && selection === 'AI Q&A' && <AI />}
        {projectOwner && selection === 'Styling' && <Formatting />}
        {projectOwner && selection === 'Bulk Updates' && <BulkUpdates />}
        {projectOwner && selection === 'Deleted Resources' && <DeletedResources />}
        {projectOwner && selection === 'Members' && <PermissionsBox />}
        {projectOwner && selection === 'Sent Invites' && <InvitedBox />}
        {projectOwner && selection === 'Webhooks' && <Notifications />}
        {projectOwner && selection === 'Secrets' && <Secrets />}
        {projectOwner && selection === 'Audit Log' && (
          <div className="flex flex-column overflow-hidden w-full" style={{ padding: '20px 30px' }}>
            <Audit type={'project'} resourceId={project.publicId} />
          </div>
        )}
      </ErrorBoundary>
    </div>
  )
}

export default ProjectSettings
