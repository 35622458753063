import React, { useState } from 'react'
import Permissions from 'components/permissions'
import { AccessResourceKinds, PROJECT } from 'app-constants'
import { useDataContext } from 'components/document/contexts/data'
import Modal, { ModalProps } from 'components/modal'
import useProject from 'hooks/project'
import useApplicationStore from 'hooks/application'
import api from 'helpers/api'
import Button from 'components/button'

const PermissionsModal: React.FC<ModalProps> = ({ id, open, setOpen, onClose }) => {
  const { project } = useProject()
  const { setSnackbarMessage, displayErrorMessage } = useApplicationStore()
  const [screen, setScreen] = useState<number>(0)
  const [viewerProjectPermissionForSelects, setViewerProjectPermissionForSelects] = useState<boolean>(false)
  const handleBulkSyncPermissions = () => {
    if (project && process) {
      api

        .updateViewsProcessPermissions(process.publicId, viewerProjectPermissionForSelects)
        .then(() => {
          setSnackbarMessage({ message: 'View permissions have been synced successfully!', status: 'success' })
          setOpen(false)
        })
        .catch((error) => displayErrorMessage(error))
    }
  }

  const handleCancelSyncPermissions = () => {
    setViewerProjectPermissionForSelects(false)
    setScreen(0)
  }
  const { process } = useDataContext()
  if (process) {
    return (
      <Modal id={id} open={open} setOpen={setOpen} title={`${screen == 1 ? 'Sync ' : ''}Permissions`} onClose={onClose}>
        {screen == 0 && (
          <div
            style={{
              listStyle: 'none',
              margin: '10px'
            }}
          >
            <div className="flex items-center border-b-2px border-solid border-grey py-10px mb-4 font-bold">
              Sync Permissions
            </div>
            <div>
              A <b>one-off</b> synchronisation of permissions for <b>all table views</b> contained in the document. If a
              user has contributor access to the document, they will be added as a contributor to all views contained in
              this document. No permissions will be removed from the table views.
            </div>
            <div className="flex items-center" style={{ marginBottom: '40px', marginTop: '20px' }}>
              <Button
                internalType="accept"
                className="text-base"
                onClick={() => {
                  setScreen(1)
                }}
              >
                <span>Sync Permissions</span>
              </Button>
            </div>

            <div className="border-b-2px border-solid border-grey py-10px mb-4 font-bold">Document Permissions</div>
            <Permissions resource={AccessResourceKinds.PROCESS} resourceId={process.publicId} />
          </div>
        )}
        {screen == 1 && (
          <div
            style={{
              listStyle: 'none',
              margin: '10px'
            }}
          >
            <div className="flex items-center justify-center" style={{ marginTop: '20px', marginBottom: '30px' }}>
              <div>
                Automatically assign {PROJECT.toLowerCase()} members as viewers on all source views for select and
                multiselect columns in this document (if any)?
              </div>
              <select
                className="ml-auto"
                style={{ width: '150px' }}
                value={viewerProjectPermissionForSelects ? 'Yes' : 'No'}
                onChange={(event) => {
                  if (event.target.value === 'Yes') {
                    setViewerProjectPermissionForSelects(true)
                  } else {
                    setViewerProjectPermissionForSelects(false)
                  }
                }}
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            <div className="flex items-center justify-end mt-20px">
              <Button internalType="danger" onClick={() => handleCancelSyncPermissions()}>
                Cancel
              </Button>

              <Button internalType="accept" style={{ marginLeft: '10px' }} onClick={() => handleBulkSyncPermissions()}>
                Confirm
              </Button>
            </div>
          </div>
        )}
      </Modal>
    )
  } else {
    return <div />
  }
}

export default PermissionsModal
