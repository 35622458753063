import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from 'hooks/auth'
import SignupForm, { IFormValues } from 'views/auth/components/SignupForm'
import { useApplicationStore } from 'hooks/application'
import { SIGN_IN } from 'routes'
import { APIError } from 'helpers/api'

const SignUp: React.FC = () => {
  const [state, setState] = useState(1)
  const { register } = useAuth()
  const { setSnackbarMessage } = useApplicationStore()
  document.title = 'Morta | Sign Up'

  const handleSubmitSignup = async (values: IFormValues) => {
    try {
      const payload = {
        name: values.name,
        email: values.email,
        password: values.password1
      }
      await register(payload)
      setState(2)
    } catch (e) {
      if (e instanceof APIError) {
        setSnackbarMessage({
          message: e.message,
          status: 'error',
          duration: 30000,
          action: (
            <a
              style={{
                backgroundColor: 'white',
                border: '1px solid white',
                padding: '5px',
                borderRadius: '2px',
                marginLeft: '10px'
              }}
              href={`/signin`}
            >
              Return To Sign In
            </a>
          )
        })
      }

      return
    }

    setState(2)
  }

  const VerificationSent = (
    <React.Fragment>
      <p>
        A verification email has been sent to your email address. Please click on the link in the email to verify your
        account.
      </p>
      <br />
      <p className="border-t-1px border-solid border-grey mt-20px pt-20px">
        Return to <Link to={SIGN_IN}>Sign In</Link>
      </p>
    </React.Fragment>
  )

  const UserDetails = <SignupForm onSubmit={handleSubmitSignup} />

  return (
    <div className="bg-really-light-blue flex flex-1 items-center justify-center overflow-hidden box-border h-screen w-full px-8">
      <div
        className="h-fit flex flex-column bg-white border-1px border-solid border-black p-8 heavy-shadow rounded text-primary items-center"
        style={{ width: '550px' }}
      >
        <h1 className="mb-8 text-2xl">Welcome to Morta</h1>
        {state === 1 && UserDetails}
        {state === 2 && VerificationSent}
      </div>
    </div>
  )
}

export default SignUp
