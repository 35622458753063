import {
  IFilterType,
  ISpreadsheetData,
  ITableRowInGroup,
  rowHeights,
  IGroupRowCreate,
  IGroupCollapsed,
  IAggregateTypes,
  IColumnValuesCount
} from 'components/spreadsheet/types'
import {
  ITableViewFilter,
  ResponsePayload,
  ITableViewWithColumns,
  ITableRow,
  ITableColumn,
  ISummaryUser,
  ITableViewSort,
  ITableRowCompressed,
  IVote,
  ITableViewColumn,
  IAccessPolicy,
  ITag,
  IProcessObject,
  ICellValue,
  EditorContent,
  ITableViewColour
} from 'types'
import { IColumnTypes, IResultFieldValidation } from 'components/spreadsheet/types'
import {
  TEXT_COLUMN_TYPES,
  NUMBER_COLUMN_TYPES,
  ATTACHMENT_COLUMN_TYPES,
  DATE_COLUMN_TYPES,
  LINK_COLUMN_TYPES,
  getInitialSpreadsheetState,
  IntegrationSyncTypes
} from 'components/spreadsheet/constants/const'
import constants from 'style/constants.module.scss'
import { PERMISSIONS } from 'helpers/auth'
import BigNumber from 'bignumber.js'
import { EditorState, convertFromRaw } from 'draft-js'
import { GROUP_KEY_SPLIT } from 'components/spreadsheet/constants/const'
import api, { APIError } from 'helpers/api'

export const rounder = (digits: number) => {
  return parseInt('1' + Array(digits + 1).join('0'))
}

export const floor = (n: number, digits = 0) => {
  const r = rounder(digits)
  return Math.floor(n * r) / r
}

export const ceil = (n: number, digits = 0) => {
  const r = rounder(digits)
  return Math.ceil(n * r) / r
}

export const createCellId = (rowId: string, columnId: string, uniqueNumber: number) => {
  return `cell/${rowId}/${columnId}/${uniqueNumber}`
}

export const isUIFilterActive = (filter: ITableViewFilter) => {
  if (
    (filter.filterType === IFilterType.one_of || filter.filterType === IFilterType.not_one_of) &&
    filter.multipleValues &&
    filter.multipleValues.length > 0
  ) {
    return true
  }
  if (filter.value !== '') {
    return true
  }
  if (
    filter.filterType === IFilterType.is_null ||
    filter.filterType === IFilterType.is_not_null ||
    filter.filterType === IFilterType.is_valid ||
    filter.filterType === IFilterType.is_not_valid
  ) {
    return true
  }
  return false
}

export const createApiFilterFromUIFilter = (filter: ITableViewFilter) => {
  if (filter.filterType === IFilterType.one_of || filter.filterType === IFilterType.not_one_of) {
    return {
      columnName: filter.columnName,
      filterType: filter.filterType,
      orGroup: filter.orGroup,
      multipleValues: filter.multipleValues
    }
  } else if (
    filter.filterType === IFilterType.is_null ||
    filter.filterType === IFilterType.is_not_null ||
    filter.filterType === IFilterType.is_valid ||
    filter.filterType === IFilterType.is_not_valid
  ) {
    return {
      columnName: filter.columnName,
      filterType: filter.filterType,
      orGroup: filter.orGroup,
      value: null
    }
  } else {
    return {
      columnName: filter.columnName,
      filterType: filter.filterType,
      orGroup: filter.orGroup,
      value: filter.value
    }
  }
}

const createColumnMapping = (columns: ITableColumn[], columnsRow: string[]) => {
  const columnIdToIndexMapping = columnsRow.reduce((prev, current, idx) => {
    const column = columns.find((c) => c.publicId === current)!
    if (column) prev[idx] = column.publicId
    return prev
  }, {} as Record<number, string>)

  return columnIdToIndexMapping
}

const transformMsgpackRowsToSpreadsheetRows = (
  columnIdToIndexMapping: Record<number, string>,
  data: ITableRowCompressed[],
  positionOffset: number,
  seenValues: IColumnValuesCount
) => {
  const rows = []

  for (let i = 0; i < data.length; i++) {
    const row = data[i]
    const rowNormalized = {
      publicId: row[0],
      sortOrder: row[1],
      searched: [],
      rowData: {}
    } as ITableRow
    for (let j = 2; j < row.length; j++) {
      const rowValue = row[j] as string
      const columnId = columnIdToIndexMapping[j - 2]

      rowNormalized.rowData[columnId] = rowValue
      if (seenValues[columnId] !== undefined && seenValues[columnId][rowValue] !== undefined) {
        seenValues[columnId][rowValue] += 1
      } else {
        if (seenValues[columnId] === undefined) seenValues[columnId] = {}
        seenValues[columnId][rowValue] = 1
      }
    }
    rows.push(rowNormalized)
  }

  return { rows, seenValues }
}

export async function* getSpreadsheetRowsStream(
  isAdmin: boolean,
  tableId: string,
  tableViewId: string,
  columns: ITableColumn[],
  processId?: string
): AsyncGenerator<{ batchRows: ITableRow[]; seenValues: IColumnValuesCount }> {
  let columnNameToIndexMapping: Record<number, string> = {}
  let positionOffset = 0
  const seenColumnValues = {}
  try {
    for await (const result of api.getTableViewRowsAsync(tableId, tableViewId, isAdmin, processId)) {
      if (result.type === 'header' && result.header && Array.isArray(result.header)) {
        columnNameToIndexMapping = createColumnMapping(columns, result.header)
      } else if (result.rows && Array.isArray(result.rows)) {
        const { rows, seenValues } = transformMsgpackRowsToSpreadsheetRows(
          columnNameToIndexMapping,
          result.rows,
          positionOffset,
          seenColumnValues
        )
        positionOffset += result.rows!.length
        yield { batchRows: rows, seenValues }
      }
    }
  } catch (e) {
    console.error(e)
  }
}

// The filter has to be any here to avoid type conflicts
// in rest of spreadsheet
const mapUnknownFilterTypes = (filter: any) => {
  return {
    ...filter,
    filterType:
      filter.filterType === 'is_not' ? IFilterType.neq : filter.filterType === 'is' ? IFilterType.eq : filter.filterType
  }
}

export const getMissingColumns = (hiddenColumns: string[], spreadsheetData: ISpreadsheetData) => {
  const missingColumns: ITableViewColumn[] = spreadsheetData['tableDetails']['columns']
    .filter((column: ITableColumn) => hiddenColumns.includes(column.publicId))
    .map((column: ITableColumn) => {
      // Keep validation in sync
      // If you are keeping validations in sync you need to find
      // the column in another view
      let sourceColumn: ITableViewColumn | undefined
      for (let i = 0; i < spreadsheetData['tableView'].length; i++) {
        const view = spreadsheetData['tableView'][i]
        sourceColumn = view.columns.find((col) => col.publicId === column.publicId)
        break
      }

      return {
        ...column,
        locked: false,
        required: false,
        sortOrder: -1,
        stringValidation:
          sourceColumn && spreadsheetData['tableDetails'].keepValidationsInSync ? sourceColumn.stringValidation : null,
        validationMessage:
          sourceColumn && spreadsheetData['tableDetails'].keepValidationsInSync ? sourceColumn.validationMessage : null,
        hardValidation:
          sourceColumn && spreadsheetData['tableDetails'].keepValidationsInSync ? sourceColumn.hardValidation : false,
        validationNoBlanks:
          sourceColumn && spreadsheetData['tableDetails'].keepValidationsInSync
            ? sourceColumn.validationNoBlanks
            : false,
        validationNoDuplicates:
          sourceColumn && spreadsheetData['tableDetails'].keepValidationsInSync
            ? sourceColumn.validationNoDuplicates
            : false
      }
    })

  return missingColumns
}

export const getSpreadsheetData = async (
  tableId: string,
  tableViewId: string,
  processId?: string,
  process?: IProcessObject
): Promise<ISpreadsheetData> => {
  const data = getInitialSpreadsheetState()
  data['tableDetails']['publicId'] = tableId
  data['activeTableView'] = tableViewId

  try {
    const tableDetails = await api.getTable(tableId, false)
    const viewDetails = await api.getTableView(tableViewId)
    const commentStats = await api.getTableComments(tableId)

    data['tableDetails']['name'] = tableDetails.data.name
    data['tableDetails']['permissionLevel'] = tableDetails.data.permissionLevel
    data['tableDetails']['logo'] = tableDetails.data.logo
    data['tableDetails']['keepValidationsInSync'] = tableDetails.data.keepValidationsInSync
    data['tableDetails']['keepColoursInSync'] = tableDetails.data.keepColoursInSync
    data['tableDetails']['isDeleted'] = tableDetails.data.isDeleted
    data['tableDetails']['deletedAt'] = tableDetails.data.deletedAt
    data['tableDetails']['columns'] = tableDetails.data.columns
    data['tableDetails']['defaultViewId'] = tableDetails.data.defaultViewId
    data['tableDetails']['isSynced'] = tableDetails.data.isSynced
    data['tableDetails']['syncUser'] = tableDetails.data.syncUser
    data['tableDetails']['isSyncing'] = tableDetails.data.isSyncing
    data['tableDetails']['lastSync'] = tableDetails.data.lastSync
    data['tableDetails']['syncHourlyFrequency'] = tableDetails.data.syncHourlyFrequency
    data['tableDetails']['allowComments'] = tableDetails.data.allowComments
    data['tableDetails']['isReferenceTable'] = tableDetails.data.isReferenceTable
    data['tableDetails']['projectPublicId'] = tableDetails.data.projectPublicId
    data['tableDetails']['failedSyncAttempts'] = tableDetails.data.failedSyncAttempts
    data['tableDetails']['isViewpointSynced'] = tableDetails.data.isViewpointSynced
    data['tableDetails']['isViewpointRfisSynced'] = tableDetails.data.isViewpointRfisSynced
    data['tableDetails']['isAutodeskBim360Synced'] = tableDetails.data.isAutodeskBim360Synced
    data['tableDetails']['isAutodeskBim360ChecklistsSynced'] = tableDetails.data.isAutodeskBim360ChecklistsSynced
    data['tableDetails']['isAutodeskBim360IssuesSynced'] = tableDetails.data.isAutodeskBim360IssuesSynced
    data['tableDetails']['isAutodeskBim360ModelsSynced'] = tableDetails.data.isAutodeskBim360ModelsSynced
    data['tableDetails']['isAutodeskBim360UsersSynced'] = tableDetails.data.isAutodeskBim360UsersSynced
    data['tableDetails']['isProcoreSynced'] = tableDetails.data.isProcoreSynced
    data['tableDetails']['isAconexSynced'] = tableDetails.data.isAconexSynced
    data['tableDetails']['isAconexWorkflowsSynced'] = tableDetails.data.isAconexWorkflowsSynced
    data['tableDetails']['isAsiteDocumentsSynced'] = tableDetails.data.isAsiteDocumentsSynced
    data['tableDetails']['isAsiteFormsSynced'] = tableDetails.data.isAsiteFormsSynced
    data['tableDetails']['isMortaProjectsSynced'] = tableDetails.data.isMortaProjectsSynced
    data['tableDetails']['isMortaUsersSynced'] = tableDetails.data.isMortaUsersSynced
    data['tableDetails']['isMortaResourcesSynced'] = tableDetails.data.isMortaResourcesSynced
    data['tableDetails']['isMortaCommentsSynced'] = tableDetails.data.isMortaCommentsSynced
    data['tableDetails']['isMortaColumnsSynced'] = tableDetails.data.isMortaColumnsSynced
    data['tableDetails']['isReviztoIssuesSynced'] = tableDetails.data.isReviztoIssuesSynced
    data['tableDetails']['type'] = tableDetails.data.type
    data['tableDetails']['joins'] = tableDetails.data.joins
    data['viewDetails'] = {
      ...viewDetails.data,
      filterSettings: viewDetails.data.filterSettings.map(mapUnknownFilterTypes),
      colourSettings: viewDetails.data.colourSettings.map(mapUnknownFilterTypes)
    }

    data['originalViewColumns'] = viewDetails.data.columns.map((column: ITableViewColumn) => column.name)
    data['loading'] = false
    const columnWidths = calculateFrozenColumnWidths(
      data['viewDetails']['columns'],
      data['viewDetails']['frozenIndex'],
      data['userConfiguration']['hiddenColumns']
    )
    data['columnWidths'] = columnWidths
    data['comments'] = commentStats.data

    const isAdmin = data.tableDetails.permissionLevel >= PERMISSIONS.owner
    const isContributor = isAdmin || data.viewDetails.permissionLevel >= PERMISSIONS.contributor
    data['isAdmin'] = isAdmin
    data['isContributor'] = isContributor
    data['totalRowsOnServer'] = viewDetails.metadata.total!

    if (isAdmin && !processId) {
      const tableViews: ResponsePayload<ITableViewWithColumns[]> = await api.getTableViews(tableId)
      data['tableView'] = tableViews.data.map((tableView: ITableViewWithColumns) => {
        const hiddenColumns = data['tableDetails']['columns']
          .filter(
            (column: ITableColumn) =>
              !tableView.columns.map((column: ITableViewColumn) => column.publicId).includes(column.publicId)
          )
          .map((column: ITableColumn) => {
            return { columnId: column.publicId, columnName: column.name }
          })

        return {
          ...tableView,
          filterSettings: tableView.filterSettings.map(mapUnknownFilterTypes),
          colourSettings: tableView.colourSettings.map(mapUnknownFilterTypes),
          chartSettings: tableView.chartSettings,
          hiddenColumns,
          columns: tableView['columns'].concat(
            getMissingColumns(
              hiddenColumns.map((column) => column.columnId),
              data
            )
          )
        }
      })
    }

    if (processId && process && process.permissions) {
      data['processVariables'] = process.variables
      data['processVariableValues'] = process.variableValues
      const allTags = process.permissions
        .filter(
          (processPermission: IAccessPolicy) =>
            processPermission.accessAttribute.kind === 'tag' && processPermission.accessAttribute.tag !== undefined
        )
        .map((processPermission: IAccessPolicy) => processPermission.accessAttribute.tag) as ITag[]

      data['processTags'] = allTags

      // Find any filters and colours related to temp process variables
      data['userConfiguration']['filterSettings'] = data['viewDetails']['filterSettings'].filter(
        (filterSetting) => filterSetting.value === '{{process.public_variables}}'
      )
      data['userConfiguration']['colourSettings'] = data['viewDetails']['colourSettings'].filter(
        (colourSetting) => colourSetting.value === '{{process.public_variables}}'
      )
    }
    data['viewDetails']
  } catch (e) {
    if (e instanceof Response) {
      data['errorStatusCode'] = e.status
    } else if (e instanceof APIError) {
      data['errorStatusCode'] = e.rawCode
    } else {
      throw e
    }
  }
  return data
}

export const calculateFrozenColumnWidths = (
  columns: ITableColumn[],
  frozenIndex: number,
  hiddenColumns: string[]
): { frozenWidth: number; unfrozenWidth: number } => {
  let frozenWidth = Number(constants.rowNumberColumnWidth)
  let unfrozenWidth = 0
  const unhiddenColumns = columns.filter((column: ITableColumn) => !hiddenColumns.includes(column.publicId))

  for (let i = 0; i < unhiddenColumns.length; i++) {
    const column = unhiddenColumns[i]
    if (i < frozenIndex) frozenWidth += column.width
    else unfrozenWidth += column.width
  }

  return { frozenWidth, unfrozenWidth }
}

export const arrayMove = (arr: any[], fromIndex: number, toIndex: number) => {
  const element = arr[fromIndex]
  arr.splice(fromIndex, 1)
  arr.splice(toIndex, 0, element)
}

export const validateField = (
  kind: IColumnTypes,
  value: ICellValue,
  fieldName: string
): IResultFieldValidation | IResultFieldValidation[] => {
  if (value === null) return { pass: true, error: '' }
  if (TEXT_COLUMN_TYPES.includes(kind)) {
    return validateTextField(kind, value as string, fieldName)
  } else if (NUMBER_COLUMN_TYPES.includes(kind)) {
    return validateNumericField(kind, value as string, fieldName)
  } else if (DATE_COLUMN_TYPES.includes(kind)) {
    return validateDateTimeField(kind, value as string, fieldName)
  } else if (LINK_COLUMN_TYPES.includes(kind)) {
    if (kind === 'link') {
      return validateSingleLinkField(value as string)
    } else {
      return (value as string[]).map(() => validateSingleLinkField(value as string))
    }
  } else if (kind === 'select') {
    const isValid = value === null || typeof value === 'string'
    return { pass: value === null || typeof value === 'string', error: isValid ? '' : `Invalid select value ${value}` }
  } else if (kind === 'checkbox') {
    const isValid = value === 'true' || value === 'false'
    return { pass: isValid, error: isValid ? '' : `Invalid checkbox value ${value}` }
  }
  return { pass: false, error: `${fieldName} is an invalid type.` }
}

export const validateTextField = (kind: IColumnTypes, value: string, fieldName: string) => {
  switch (kind) {
    case 'text':
    case 'tag':
    case 'variable':
      return { pass: true, error: '' }
    case 'email':
      const validEmail = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$").test(value)
      return { pass: validEmail, error: validEmail ? '' : `${fieldName} has invalid email value ${value}` }
    case 'phone':
      const validPhone = /^\+?(([0-9]|x)+)?$/.test(value)
      return { pass: validPhone, error: validPhone ? '' : `${fieldName} has invalid phone value ${value}` }
    default:
      return { pass: false, error: 'Field type not implemented yet' }
  }
}

export const validateNumericField = (kind: IColumnTypes, value: string | number, fieldName: string) => {
  const numberValue = Number(value)
  if (Number.isNaN(numberValue) || (kind === 'integer' && !Number.isInteger(numberValue))) {
    return { pass: false, error: `${fieldName} is an invalid ${kind} value ${value}` }
  }
  return { pass: true, error: '' }
}

export const validateDateTimeField = (kind: IColumnTypes, value: string, fieldName: string) => {
  if (typeof value === 'string') {
    const dateValue = new Date(value)
    const validDate = Object.prototype.toString.call(dateValue) === '[object Date]'
    return { pass: validDate, error: validDate ? '' : `${fieldName} is an invalid ${kind} value ${value}` }
  }
  return { pass: false, error: `${fieldName} is an invalid ${kind} value ${value}` }
}

export const validateSingleLinkField = (value: null | string | string[]) => {
  if (!value) {
    return {
      pass: true,
      error: ''
    }
  }

  const isValid = true
  return { pass: isValid, error: isValid ? '' : `${value} is an invalid value.` }
}

export const isRowInGroup = (row: unknown): row is ITableRowInGroup =>
  row !== undefined && row !== null && (row as ITableRowInGroup).isGrouped ? true : false

export const isGroupRowCreate = (row: unknown): row is IGroupRowCreate =>
  row !== null && row !== undefined && (row as IGroupRowCreate).isAddRow

export const isCollapsedGroupRow = (row: unknown): row is IGroupCollapsed => (row as IGroupCollapsed).isCollapsedRow

export const isSummaryUser = (value: ICellValue): value is ISummaryUser => {
  const test = value as ISummaryUser

  if (test !== null && test.name && test.email) {
    return true
  }
  return false
}

export const getRowHeightVariable = (rowHeight: number) => {
  if (rowHeight === rowHeights.Small) return Number(constants.smallRowHeight)
  else if (rowHeight === rowHeights.Medium) return Number(constants.mediumRowHeight)
  else if (rowHeight === rowHeights.Tall) return Number(constants.tallRowHeight)
  else return Number(constants.extraTallRowHeight)
}

export const getRowHeightClassName = (rowHeight: number) => {
  if (rowHeight === rowHeights.Small) return 'small-row-height'
  else if (rowHeight === rowHeights.Medium) return 'medium-row-height'
  else if (rowHeight === rowHeights.Tall) return 'tall-row-height'
  else return 'extra-tall-row-height'
}

export const coerceToValue = (val: ICellValue, kind: IColumnTypes) => {
  if (val === null || val === undefined) {
    return ''
  } else if (typeof val === 'string') {
    return val
  } else if (typeof val === 'number') {
    return val
  } else if (Array.isArray(val) && kind === 'vote') {
    const voteArray: IVote[] = val as IVote[]
    const positiveVotes = voteArray.filter((vote: IVote) => vote.type).length
    const negativeVotes = voteArray.filter((vote: IVote) => !vote.type).length
    return positiveVotes - negativeVotes
  } else {
    return val.toString()
  }
}

export const sortRows = (rows: ITableRow[], sortBy: ITableViewSort[], columns: ITableViewColumn[]) => {
  const sortedRows = [...rows]
  const direction = {
    asc: 1,
    desc: -1
  }
  if (sortBy.length == 0) {
    sortedRows.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
  } else {
    sortedRows.sort((a, b) => {
      let i = 0
      let result = 0
      while (i < sortBy.length && result === 0) {
        const columnId = sortBy[i].columnId
        const column = columns.find((column: ITableViewColumn) => column.publicId === columnId)
        const columnKind = column ? column.kind : 'text'
        const aValue = coerceToValue(a.rowData[columnId], columnKind)
        const bValue = coerceToValue(b.rowData[columnId], columnKind)
        const sortValue =
          aValue === bValue
            ? 0
            : aValue === ''
            ? 1
            : bValue === ''
            ? -1
            : aValue < bValue
            ? -1
            : aValue > bValue
            ? 1
            : 0
        result = direction[sortBy[i].direction] * sortValue
        i++
      }
      return result
    })
  }
  return sortedRows
}

export const convertToApiFilter = (filterSettings: ITableViewFilter[]) => {
  const newFilters = (filterSettings as Partial<ITableViewFilter>[]).map((filter) => {
    filter.filterType === 'one_of' || filter.filterType === 'not_one_of'
      ? delete filter['value']
      : delete filter['multipleValues']
    return filter as ITableViewFilter
  })
  return newFilters
}

export const convertToApiColour = (colourSettings: ITableViewColour[]) => {
  const newColours = (colourSettings as Partial<ITableViewColour>[]).map((colour) => {
    colour.filterType === 'one_of' || colour.filterType === 'not_one_of'
      ? delete colour['value']
      : delete colour['multipleValues']
    return colour as ITableViewColour
  })
  return newColours
}

// Given a position (row index) this will return the sort index that will be used by the row
// The current row at the position will be shifted to the right in the ordering of the rows
// Ported from the old spreadsheet component
export const getSortIndexForPosition = (rows: ITableRow[], position: number): number => {
  // insert before first row
  if (position === 0) {
    return rows[0].sortOrder - 1
  }

  // appending to end of table
  if (position > rows.length - 1) {
    return rows[rows.length - 1].sortOrder + 1
  }

  // Get a number between the previous and next rows
  const before = new BigNumber(rows[position - 1].sortOrder)
  const after = new BigNumber(rows[position].sortOrder)
  const difference = after.minus(before)
  const step = findStepSize(difference)

  return after.minus(step).toNumber()
}

export const findRowAndIndex = (rowPublicId: string, rows: ITableRow[]) => {
  for (let i = 0; i < rows.length; i++) {
    if (rows[i].publicId === rowPublicId) {
      return { index: i, row: rows[i] }
    }
  }
}

// Given the difference between two numbers, this finds a value that can
// be used to create a new number between those numbers
// 0.1 < diff <= Inf = 0.1
// 0.01 < diff <= 0.1 = 0.01
// 0.001 < diff <= 0.01 = 0.001
const findStepSize = (difference: BigNumber): BigNumber => {
  let min = new BigNumber(0.1)
  let max = new BigNumber(Number.MAX_VALUE)

  if (difference.isEqualTo(0)) {
    throw new Error('Difference cannot be zero')
  }

  while (true) {
    if (difference.isGreaterThan(min) && difference.isLessThanOrEqualTo(max)) {
      return min
    } else {
      max = min
      min = min.dividedBy(10)
    }
  }
}

export const isFieldValueEmpty = (kind: IColumnTypes, value: unknown) => {
  const arrayTypes = [...ATTACHMENT_COLUMN_TYPES, 'multiselect', 'multilink', 'vote']
  const stringTypes = [...TEXT_COLUMN_TYPES, 'select', 'link']
  const numberTypes = NUMBER_COLUMN_TYPES

  // General checks
  if (value === undefined || value === null) {
    return true
  }

  // For arrays check length
  if (arrayTypes.includes(kind)) {
    if ((value as unknown[]).length === 0) {
      return true
    } else {
      return false
    }
  }

  // For strings check it's not empty
  else if (stringTypes.includes(kind)) {
    if ((value as string).trim() === '') {
      return true
    } else {
      return false
    }
  }

  //  For numbers the undefined and null check is enough
  else if (numberTypes.includes(kind)) {
    return false
  }

  // For everything else check if value not falsy
  else {
    return !value ? true : false
  }
}

export const isValidOption = (column: ITableColumn, value: ICellValue, values: { [key: string]: ICellValue }) => {
  if (!column.kindOptions || value === null) {
    return false
  }
  let allOptions = column.kindOptions.manualOptions ? [...column.kindOptions.manualOptions] : []

  if (Object.keys(column.kindOptions.tableOptions).length > 0) {
    const tableOptions = column.kindOptions.tableOptions
    let cachedOptions = []
    if (Array.isArray(tableOptions.cachedOptions)) {
      cachedOptions = [...tableOptions.cachedOptions]
    } else {
      const key = tableOptions.dependencies.map((dependency) => values[dependency.columnId]).join(GROUP_KEY_SPLIT)
      const cachedOptionsFromDict = (tableOptions.cachedOptions as { [key: string]: string[] })[key]
      cachedOptions = cachedOptionsFromDict ? cachedOptionsFromDict : []
    }
    allOptions = allOptions.concat(cachedOptions)
  }

  // Check if there are options available
  if (allOptions.length === 0) return false

  const isValidSelectValue = column.kind === 'select' && allOptions.includes(value.toLocaleString())
  const isValidMultiselectValue =
    column.kind === 'multiselect' &&
    Array.isArray(value) &&
    (value as string[])
      .map((currentVal) => {
        return allOptions.includes(currentVal.toLocaleString())
      })
      .every(Boolean)

  return isValidSelectValue || isValidMultiselectValue
}

// Convert a row that has column ID keys to column name keys
export const convertRowDataToNameValueMap = (columns: ITableColumn[], values: Record<string, ICellValue>) => {
  const output = Object.keys(values).reduce((accumulator, columnId) => {
    const column = columns.find((c) => c.publicId === columnId)!
    accumulator[column.name] = values[columnId]
    return accumulator
  }, {} as Record<string, ICellValue>)

  return output
}

// Convert a row that has column name keys to column ID keys (opposite of above function)
export const convertRowDataToIdValueMap = (columns: ITableColumn[], values: Record<string, ICellValue>) => {
  const output = Object.keys(values).reduce((accumulator, columnName) => {
    const column = columns.find((c) => c.name === columnName)
    if (column) accumulator[column.publicId] = values[columnName]
    return accumulator
  }, {} as Record<string, ICellValue>)

  return output
}

// We disable rearranging of rows when filtering or sorting is applied
export const canRearrangeRows = (state: ISpreadsheetData) => {
  if (
    state.userConfiguration.filterSettings.length === 0 &&
    state.userConfiguration.sortSettings.length === 0 &&
    state.viewDetails.sortSettings.length === 0 &&
    state.viewDetails.filterSettings.length === 0
  ) {
    return true
  } else {
    return false
  }
}

export const isEditorEmpty = (editorContent: EditorContent) => {
  if (editorContent) {
    const editorState = EditorState.createWithContent(convertFromRaw(editorContent.content))
    return !editorState.getCurrentContent().hasText()
  }
  return true
}

export const isSameValueStringValue = (v1: string, v2: number | string | boolean | null) => {
  // empty string and null is evaulated as the same
  if (v1 === '' && v2 === null) {
    return true
    // compare string with string version of number
  } else if (typeof v2 === 'number') {
    if ((v1 === null && v2 !== null) || (v1 !== null && v2 === null)) {
      return false
    } else {
      // Empty string and 0 are evaluated as equal values when transformed into numbers.
      // We need to make sure that an empty value in the text field means null and that 0 is an actual value and not an empty one
      return v1.toString() === v2.toString() || (Number(v1) === Number(v2) && !(v1 === '' && Number(v2) === 0))
    }

    // compare strings
  } else {
    return v1 === v2
  }
}

export const buildNewColumn = (
  spreadsheetData: ISpreadsheetData,
  sortOrder: number,
  projectId: string,
  headerBackgroundColor: string,
  headerTextColor: string
) => {
  const fieldRegex = new RegExp('^Column [0-9]+$')
  const fields = (spreadsheetData.tableDetails.columns || [])
    .filter((column) => fieldRegex.test(column.name))
    .map((column) => Number(column.name.split(' ').pop()))
  fields.sort((a, b) => a - b)
  const lastFieldNumber = fields.pop()
  const fieldNumber = lastFieldNumber ? lastFieldNumber + 1 : 1
  const column: Omit<ITableViewColumn, 'publicId' | 'kindOptions'> = {
    name: `Column ${fieldNumber}`,
    sortOrder: sortOrder,
    isIndexed: false,
    viewpointSynced: IntegrationSyncTypes.NOT_SYNCED,
    viewpointRfisSynced: IntegrationSyncTypes.NOT_SYNCED,
    autodeskBim360Synced: IntegrationSyncTypes.NOT_SYNCED,
    autodeskBim360ChecklistsSynced: IntegrationSyncTypes.NOT_SYNCED,
    autodeskBim360IssuesSynced: IntegrationSyncTypes.NOT_SYNCED,
    autodeskBim360ModelsSynced: IntegrationSyncTypes.NOT_SYNCED,
    procoreSynced: IntegrationSyncTypes.NOT_SYNCED,
    aconexSynced: IntegrationSyncTypes.NOT_SYNCED,
    aconexWorkflowsSynced: IntegrationSyncTypes.NOT_SYNCED,
    asiteDocumentsSynced: IntegrationSyncTypes.NOT_SYNCED,
    asiteFormsSynced: IntegrationSyncTypes.NOT_SYNCED,
    mortaSynced: IntegrationSyncTypes.NOT_SYNCED,
    reviztoIssuesSynced: IntegrationSyncTypes.NOT_SYNCED,
    locked: false,
    width: 150,
    kind: 'text',
    description: null,
    required: false,
    script: null,
    thousandSeparator: true,
    displayLink: true,
    decimalPlaces: 2,
    dateFormat: null,
    scriptEnabled: false,
    formula: null,
    formulaEnabled: false,
    aggregate: IAggregateTypes.None,
    stringValidation: null,
    validationMessage: null,
    hardValidation: false,
    validationNoBlanks: false,
    validationNoDuplicates: false,
    isJoined: false,
    headerBackgroundColor: headerBackgroundColor,
    headerTextColor: headerTextColor,
    exportWidth: null
  }
  const payload = { ...column, context: { projectId } }
  return payload
}

export const getColumnsAsAdmin = (spreadsheetData: ISpreadsheetData) => {
  // Keep order of the columns in the view. Add missing columns on view at the end
  const missingColumns: ITableColumn[] = []
  spreadsheetData.tableDetails.columns.map((col) => {
    const found = spreadsheetData.viewDetails.columns.find((viewColumn) => viewColumn.publicId === col.publicId)
    if (!found) {
      missingColumns.push(col)
    }
  })
  return [...spreadsheetData.viewDetails.columns, ...missingColumns] as ITableColumn[]
}

export const getColumnsPerUserRole = (spreadsheetData: ISpreadsheetData) => {
  return spreadsheetData.isAdmin
    ? getColumnsAsAdmin(spreadsheetData)
    : (spreadsheetData.viewDetails.columns as ITableColumn[])
}
