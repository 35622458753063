import React, { useEffect, useState } from 'react'
import Modal, { ModalProps } from 'components/modal'
import { IDuplicatedChild } from 'types'
import api from 'helpers/api'
import useApplicationStore from 'hooks/application'

interface DuplicateChildrenProps extends ModalProps {
  type: 'project' | 'table' | 'process'
  publicId: string
}

const DuplicateChildren: React.FC<DuplicateChildrenProps> = ({ id, open, setOpen, type, publicId }) => {
  const { displayErrorMessage } = useApplicationStore()
  const [duplicatedChildren, setDuplicatedChildren] = useState<IDuplicatedChild[]>([])

  useEffect(() => {
    if (open) {
      api
        .getDuplicatedChildren(publicId, type)
        .then((response) => {
          setDuplicatedChildren(response.data)
        })
        .catch((error) => {
          displayErrorMessage(error)
        })
    }
  }, [])

  return (
    <Modal id={id} open={open} setOpen={setOpen} title={`${duplicatedChildren.length} Duplicated Resources`}>
      <ul style={{ padding: '20px' }}>
        {duplicatedChildren.length > 0 &&
          duplicatedChildren
            .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
            .map((child, index) => {
              const duplicateName = child.name
              const duplicatedBy = child.user ? child.user : 'Unknown'
              const duplicatedDate = child.createdAt
              return (
                <li key={index} className="flex flex-row items-center" style={{ marginBottom: '10px' }}>
                  <a href={child.link} target="_blank" rel="noreferrer">
                    {duplicateName}
                  </a>
                  <div className="text-sm font-secondary ml-4">
                    Duplicated by {duplicatedBy} on {new Date(duplicatedDate).toLocaleString()}
                  </div>
                </li>
              )
            })}

        {duplicatedChildren.length === 0 && (
          <div className="no-duplicated-children">No resources have been duplicated.</div>
        )}
      </ul>
    </Modal>
  )
}

export default DuplicateChildren
