import { ICellValue, ITableRow, ITableViewColour, ITableViewColumn } from 'types'
import { doesFilterMatchValue } from 'components/spreadsheet/helpers/filtering'
import constants from 'style/constants.module.scss'
import { IColumnValuesCount } from '../types'

export const getCellColour = (
  column: ITableViewColumn,
  columnValuesCount: IColumnValuesCount,
  row: ITableRow | null,
  value?: ICellValue,
  colourSettings?: ITableViewColour[],
  greyBackground?: boolean,
  defaultColour?: string
) => {
  let backgroundColour = greyBackground ? constants.lightGrey : defaultColour ? defaultColour : '#fff'
  let fontColour = constants.textPrimary
  if (column && colourSettings && value !== undefined) {
    for (let i = 0; i < colourSettings.length; i++) {
      const colourSetting = colourSettings[i]
      if (colourSetting.columnId === column.publicId) {
        const match = doesFilterMatchValue(
          colourSetting.filterType,
          colourSetting.value,
          colourSetting.multipleValues,
          value,
          '',
          '',
          [],
          [],
          [],
          [],
          column,
          columnValuesCount,
          row
        )
        if (match) {
          backgroundColour = colourSetting.backgroundColour
          fontColour = colourSetting.fontColour
          break
        }
      }
    }
  }
  return {
    backgroundColour: backgroundColour,
    fontColour: fontColour
  }
}
