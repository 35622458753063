import React from 'react'
import { IColumnTypes } from 'components/spreadsheet/types'
import { getRowHeightVariable, validateDateTimeField } from 'components/spreadsheet/helpers/functions'
import { EDITABLE_DATETIME_OPTION_TYPES } from 'components/spreadsheet/constants/const'
import { formatDate, formatDateTime, toUTCString } from 'components/spreadsheet/helpers/format'
import { isDate } from 'helpers/date'

interface DateCellProps {
  value: string
  align: 'center' | 'left' | 'right'
  color: string
  kind: IColumnTypes
  readOnly: boolean
  editing: boolean
  width: number
  rowHeight: number
  selected: boolean
  validation: boolean
  handleKeyDown: (event: React.KeyboardEvent) => void
  setCellValue: (value: string) => void
  dateFormat: string | null
}

const DateCellComponent: React.FC<DateCellProps> = ({
  value,
  align,
  color,
  kind,
  readOnly,
  editing,
  width,
  rowHeight,
  selected,
  validation,
  handleKeyDown,
  setCellValue,
  dateFormat
}) => {
  const dateValue =
    value && typeof value === 'string' && value !== '' && isDate(value + 'Z')
      ? kind === 'date'
        ? formatDate(new Date(value + 'Z').toISOString(), dateFormat ? dateFormat : 'YYYY-MM-DD')
        : formatDateTime(new Date(value + 'Z').toISOString(), dateFormat ? dateFormat : 'YYYY-MM-DD HH:mm:ss')
      : ''

  const handleDateTimeValue = (value: string) => {
    let formattedValue = ''
    if (kind === 'datetime') {
      formattedValue = formatDateTime(value + 'Z', 'YYYY-MM-DDTHH:mm')
    } else {
      formattedValue = formatDate(value + 'Z', 'YYYY-MM-DD')
    }
    return formattedValue
  }

  if (EDITABLE_DATETIME_OPTION_TYPES.includes(kind) && editing && !readOnly) {
    return (
      <input
        className="cell-value-editing focus"
        type={kind === 'datetime' ? 'datetime-local' : kind}
        style={{ width: 'inherit', height: `${getRowHeightVariable(rowHeight) - 1}px`, resize: 'none' }}
        defaultValue={value ? handleDateTimeValue(value) : ''}
        onChange={(event) => setCellValue(toUTCString(event.target.value, kind))}
        onKeyDown={handleKeyDown}
        autoFocus
      ></input>
    )
  } else {
    return (
      <div
        className={`cell-value ${selected ? 'focus' : ''}`}
        tabIndex={-1}
        style={{
          textAlign: align,
          color: color,
          width: width,
          maxWidth: width,
          maxHeight: `${getRowHeightVariable(rowHeight)}px`
        }}
        onDoubleClick={(event) => !EDITABLE_DATETIME_OPTION_TYPES.includes(kind) && event.stopPropagation()}
      >
        <div
          className="truncate flex items-center"
          style={{
            maxHeight: `${getRowHeightVariable(rowHeight) - 3}px`,
            width: validation ? 'calc(100% - 25px)' : '100%'
          }}
        >
          {validateDateTimeField(kind, value !== null ? value : '', '').pass ? (
            kind === 'date' ? (
              dateValue
            ) : (
              `${dateValue}`
            )
          ) : (
            <span>&nbsp;</span>
          )}
        </div>
      </div>
    )
  }
}

export const DateCell = React.memo(DateCellComponent)
