import React, { useState, useEffect, createRef, CSSProperties } from 'react'
import CSS from 'csstype'
import api from 'helpers/api'
import Editor from 'components/editor'
import Button from 'components/button'
import { useProject } from 'hooks/project'
import { PERMISSIONS } from 'helpers/auth'
import Spreadsheet from 'components/spreadsheet/index'
import { useAuth } from 'hooks/auth'
import Select from 'components/select'
import { useApplicationStore } from 'hooks/application'
import { IFileParentResource } from 'types'
import FileList from './FileList'
import { IProcessSectionObject, IProcessResponseObject } from 'types'
import AlterResponseDialog from 'components/document/components/responseEdit/components/alterResponseDialog'
import { useDataContext } from 'components/document/contexts/data'
import useOnScreen from 'hooks/screen'
import Modal from 'components/modal'

interface ResponseProps {
  section: IProcessSectionObject
  response: IProcessResponseObject
}

const defaultDialogState = {
  type: '',
  open: false
}

const nonFullScreenDiv: CSS.Properties = {
  display: 'flex',
  flexDirection: 'column',
  height: `600px`,
  marginBottom: '5px'
}

const Response: React.FC<ResponseProps> = ({ section, response }: ResponseProps) => {
  const { process, setSection } = useDataContext()
  const [updatedResponse, setUpdatedResponse] = useState<IProcessResponseObject>(response)
  const [inputFiles, setInputFiles] = useState<Array<any>>(response.response || [])
  const [fileUploading, setFileUploading] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(false)
  const { project } = useProject()
  const { user } = useAuth()
  const { displayErrorMessage, setSnackbarMessage } = useApplicationStore()
  const [alterResponseDialog, setAlterResponseDialog] = useState<{ type: string; open: boolean }>(defaultDialogState)
  const [responseSelectionOptions, setResponseSelectionOptions] = useState<{
    error: boolean
    options: Array<string> | null
    errorMessage: string
  }>({
    error: false,
    options: null,
    errorMessage: ''
  })

  const responseRef = createRef<HTMLDivElement>()
  const isVisible = useOnScreen(responseRef)

  const setResponse = (newResponse: IProcessResponseObject) => {
    const newSection = { ...section }
    newSection.responses.forEach((sectionResponse: IProcessResponseObject, responseIndex: number) => {
      if (sectionResponse.publicId === response.publicId) newSection.responses[responseIndex] = newResponse
    })
    setSection(newSection, true)
  }

  useEffect(() => {
    if (response.type === 'File Upload' && inputFiles.length === 0) {
      const numberOfFiles = response.typeOptions.noOfFiles ? Number(response.typeOptions.noOfFiles) : 1
      setInputFiles(new Array(numberOfFiles).fill(undefined))
    } else if (
      response.type === 'Selection' &&
      response.typeOptions &&
      response.typeOptions.table &&
      response.typeOptions.selectionColumn &&
      response.typeOptions.selectionColumn.publicId
    ) {
      api
        .getDistinct({ tableId: response.typeOptions.table, columnId: response.typeOptions.selectionColumn.publicId })
        .then((response) => {
          setResponseSelectionOptions({ error: false, options: response.data.sort(), errorMessage: '' })
        })
        .catch((error) => {
          displayErrorMessage(error)
          setResponseSelectionOptions({ error: true, options: null, errorMessage: error.message })
        })
    }
  }, [])

  useEffect(() => {
    if (isVisible && !visible) {
      setVisible(true)
    }
  }, [isVisible])

  const resetResponse = (response: IProcessResponseObject): void => {
    if (process) {
      api
        .resetProcessResponse(project.publicId, process.publicId, section.publicId, response.publicId)
        .then((response) => {
          setResponse(response.metadata.response)
          setSnackbarMessage({ status: 'success' })
        })
        .catch((error) => {
          displayErrorMessage(error)
        })
    }
  }

  const [submitResponseDialog, setSubmitResponseDialog] = useState<{ open: boolean }>({
    open: false
  })

  function onResponseChange(value: any) {
    response.response = value
  }

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    setFileUploading(true)
    setSnackbarMessage({
      status: 'info',
      message: 'This file is being uploaded. Please wait.'
    })

    if (!e.target.files) {
      return false
    }

    const file = e.target.files[0]

    if (process) {
      try {
        const resources: IFileParentResource[] = [{ resource: 'process', publicId: process.publicId }]
        const responseFilesUPload = await api.uploadFile(file, resources)
        const updatedFiles = [...inputFiles]
        updatedFiles[index] = responseFilesUPload.data

        setInputFiles(updatedFiles)
        setSnackbarMessage({
          status: 'success'
        })
        setFileUploading(false)
        setResponse({ ...response, response: updatedFiles })
      } catch (e) {
        setSnackbarMessage({
          status: 'error',
          message: 'There was a problem uploading this file'
        })
      }
    }
  }

  const tableView =
    response &&
    response.type === 'Table' &&
    response.typeOptions &&
    response.typeOptions.table &&
    response.typeOptions.tableView

  const updateResponse = () => {
    if (response.type === 'File Upload') {
      const missingFiles = inputFiles.filter((file) => !file).length
      if (missingFiles > 0) {
        setSnackbarMessage({
          status: 'error',
          message: 'Please make sure you provided all files requested. Number of files missing:' + missingFiles + '.'
        })
        setSubmitResponseDialog({ open: false })
        return
      } else {
        response.response = inputFiles
      }
    }

    if (process && (response.type !== 'Selection' || (response.type === 'Selection' && response.response))) {
      api
        .submitProcessResponse({
          response: response.response,
          processId: process.publicId,
          processSectionId: section.publicId,
          processResponseId: response.publicId,
          context: { projectId: project.publicId }
        })
        .then((apiResponse) => {
          setResponse(apiResponse.metadata.response)
          setSnackbarMessage({ status: 'success', message: 'Response submitted successfully.' })
          setSubmitResponseDialog({ open: false })
          setVisible(false)
          setVisible(true)
        })
        .catch((error) => {
          displayErrorMessage(error)
          setSubmitResponseDialog({ open: false })
        })
    } else {
      setSnackbarMessage({
        status: 'error',
        message: 'Please make sure you have filled out a response before submitting it.'
      })
    }
  }

  interface UploadFileProps {
    index: number
    fileName: string
  }

  const UploadFile: React.FC<UploadFileProps> = ({ index, fileName }: UploadFileProps) => {
    const inputRef = React.useRef<HTMLInputElement>(null)

    return (
      <div
        className="w-full border-solid border-1px border-grey bg-white rounded"
        style={{ padding: '10px', marginBottom: '10px' }}
      >
        <input
          type="file"
          id="file"
          ref={inputRef}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => onFileChange(event, index)}
          style={{ display: 'none' }}
        />
        <Button style={{ padding: '2px 10px', marginRight: '10px' }} onClick={() => inputRef.current!.click()}>
          Choose file
        </Button>
        <span>{fileName ? fileName : 'No file chosen'}</span>
      </div>
    )
  }

  interface SaveButtonProps {
    label: string
    onClick: () => void
    loading?: boolean
    disabled?: boolean
    style?: CSSProperties
  }

  const SaveButton: React.FC<SaveButtonProps> = ({ label, onClick, disabled, loading, style }: SaveButtonProps) => (
    <Button style={{ ...style, maxWidth: '170px' }} onClick={() => onClick()} isLoading={loading} disabled={disabled}>
      {label}
    </Button>
  )

  interface ResponseActionButtonsAreaProps {
    loading?: boolean
  }

  const ResponseActionButtonsArea: React.FC<ResponseActionButtonsAreaProps> = ({
    loading
  }: ResponseActionButtonsAreaProps) => {
    if (response.enableSubmission) {
      return (
        <div className="flex items-center" style={{ paddingTop: '5px', paddingBottom: '5px' }}>
          <SaveButton
            label={'Submit Response'}
            onClick={() => setSubmitResponseDialog({ open: true })}
            loading={loading}
          />
        </div>
      )
    } else {
      return null
    }
  }

  if (response.responseDate) {
    return (
      <div ref={responseRef} className="rounded" style={{ marginTop: '-5px', marginBottom: '20px' }}>
        <div>
          {process && response.type === 'Flexible' && (
            <Editor
              databaseDoc={response.response}
              readOnly={true}
              editorId={response.publicId}
              process={process}
              processSectionId={section.publicId}
              processResponseId={response.publicId}
              resources={[{ resource: 'process', publicId: process.publicId }]}
            />
          )}

          {response.type === 'Selection' && (
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>{response.response}</div>
          )}

          {response.type === 'File Upload' && (
            <div style={{ padding: '0.5rem' }}>
              <FileList fileList={response.response} />
            </div>
          )}

          {process &&
            response.type === 'Table' &&
            response.typeOptions &&
            response.typeOptions.table &&
            response.typeOptions.tableView && (
              <div>
                {visible ? (
                  <div style={nonFullScreenDiv}>
                    <Spreadsheet
                      tableId={response.typeOptions.table}
                      tableViewId={tableView}
                      processId={process.publicId}
                      processSectionId={section.publicId}
                      processResponseId={response.publicId}
                      permissionCap={PERMISSIONS.viewer}
                      process={process}
                    />
                  </div>
                ) : (
                  <div style={{ height: '600px' }} />
                )}
              </div>
            )}

          {response.user && user && response.user.firebaseUserId === user!.firebaseUserId && (
            <div style={{ display: 'flex', width: '100%', paddingTop: '5px', paddingBottom: '5px' }}>
              <Button
                style={{ marginLeft: 'auto', width: '170px' }}
                onClick={() => setAlterResponseDialog({ open: true, type: 'Reopen' })}
              >
                Reopen Response
              </Button>
            </div>
          )}

          <AlterResponseDialog
            open={alterResponseDialog.open}
            onClose={() => setAlterResponseDialog(defaultDialogState)}
            type={alterResponseDialog.type}
            message={'Are you sure you would like to reopen this response?'}
            onSave={() => {
              setAlterResponseDialog(defaultDialogState)
              resetResponse(response)
            }}
          />
        </div>
      </div>
    )
  } else if (process && process.permissionLevel < PERMISSIONS.contributor) {
    return (
      <div ref={responseRef} className="rounded" style={{ marginTop: '-5px', marginBottom: '20px' }}>
        {response.type === 'Table' &&
          response.typeOptions &&
          response.typeOptions.table &&
          response.typeOptions.tableView && (
            <div>
              {visible ? (
                <div style={nonFullScreenDiv}>
                  <Spreadsheet
                    tableId={response.typeOptions.table}
                    tableViewId={tableView}
                    processId={process.publicId}
                    processSectionId={section.publicId}
                    processResponseId={response.publicId}
                    permissionCap={PERMISSIONS.viewer}
                    process={process}
                  />
                </div>
              ) : (
                <div style={{ height: '600px' }} />
              )}
            </div>
          )}
      </div>
    )
  } else if (process && process.permissionLevel >= PERMISSIONS.contributor) {
    return (
      <div ref={responseRef} className="rounded" style={{ marginTop: '-5px', marginBottom: '20px' }}>
        <div>
          <div>
            {process && response.type === 'Flexible' && (
              <div
                className="h-full"
                style={{ padding: '10px', background: !!response.response ? 'rgb(227, 237, 255)' : 'inherit' }}
              >
                <Editor
                  databaseDoc={response.response}
                  onChange={(value: any) => onResponseChange(value)}
                  readOnly={false}
                  editorId={response.publicId}
                  resources={[
                    {
                      resource: 'process',
                      publicId: process.publicId
                    }
                  ]}
                  border={true}
                />
                <ResponseActionButtonsArea />
              </div>
            )}

            {response.type === 'File Upload' && (
              <div
                className="h-full"
                style={{ padding: '10px', background: !!response.response ? 'rgb(227, 237, 255)' : 'inherit' }}
              >
                {response.typeOptions &&
                  Array(Math.max(1, parseInt(response.typeOptions.noOfFiles ? response.typeOptions.noOfFiles : 1)))
                    .fill(0)
                    .map(function (item: any, index: number) {
                      return (
                        <UploadFile
                          key={`upload-file-${index}`}
                          fileName={
                            response.response && response.response[index]
                              ? response.response[index].filename
                              : undefined
                          }
                          index={index}
                        />
                      )
                    })}
                <ResponseActionButtonsArea loading={fileUploading} />
              </div>
            )}

            {response.type === 'Selection' && responseSelectionOptions.error && (
              <div>
                Something went wrong getting the selection options from the table. Error message:
                {`'${responseSelectionOptions.errorMessage}'`}
              </div>
            )}

            {response.type === 'Selection' &&
              responseSelectionOptions.options &&
              !responseSelectionOptions.error &&
              response.typeOptions.selectionNumber === 'Single' && (
                <div
                  className="h-full"
                  style={{
                    padding: '10px',
                    background: !!(response.response || '') ? 'rgb(227, 237, 255)' : 'inherit'
                  }}
                >
                  <Select
                    options={
                      responseSelectionOptions.options
                        ? responseSelectionOptions.options.map((option) => {
                            return { value: option, label: option }
                          })
                        : []
                    }
                    onOptionClick={(option) => {
                      onResponseChange(option)
                      setUpdatedResponse({ ...response, response: option })
                    }}
                    optionsSelected={
                      updatedResponse.response && typeof updatedResponse.response === 'string'
                        ? [updatedResponse.response]
                        : []
                    }
                    setOptionsSelected={(option) => {
                      onResponseChange(option)
                      setUpdatedResponse({ ...response, response: option })
                    }}
                  />

                  <ResponseActionButtonsArea />
                </div>
              )}

            {response.type === 'Selection' &&
              responseSelectionOptions.options &&
              !responseSelectionOptions.error &&
              response.typeOptions.selectionNumber === 'Multiple' && (
                <div
                  className="h-full"
                  style={{
                    padding: '10px',
                    background: !!(
                      (response.response &&
                        typeof updatedResponse.response === 'string' &&
                        response.response.split(',')) ||
                      []
                    )
                      ? 'rgb(227, 237, 255)'
                      : 'inherit'
                  }}
                >
                  <Select
                    options={
                      responseSelectionOptions.options
                        ? responseSelectionOptions.options.map((option) => {
                            return { value: option, label: option }
                          })
                        : []
                    }
                    onOptionClick={(option) => {
                      if (updatedResponse && updatedResponse.response !== undefined) {
                        const answer = updatedResponse.response
                        const selectedOptions: string[] =
                          answer === '' || answer == null || typeof updatedResponse.response === 'object'
                            ? []
                            : answer.split(', ')
                        const index = selectedOptions.findIndex((selection: string) => selection === option)
                        const newSelectedOptions = [...selectedOptions]

                        if (index === -1) {
                          newSelectedOptions.push(option)
                        } else {
                          newSelectedOptions.splice(index, 1)
                        }
                        const newValue = newSelectedOptions.join(', ')
                        onResponseChange(newValue)
                        setUpdatedResponse({ ...response, response: newValue })
                      }
                    }}
                    optionsSelected={
                      updatedResponse.response && typeof updatedResponse.response === 'string'
                        ? updatedResponse.response.split(', ')
                        : []
                    }
                    setOptionsSelected={(options) => {
                      const newValue = options.join(', ')
                      onResponseChange(newValue)
                      setUpdatedResponse({ ...response, response: newValue })
                    }}
                    multiselect={true}
                  />
                  <ResponseActionButtonsArea />
                </div>
              )}

            {process &&
              response.type === 'Table' &&
              response.typeOptions &&
              response.typeOptions.table &&
              response.typeOptions.tableView && (
                <div>
                  {visible ? (
                    <div style={nonFullScreenDiv}>
                      <Spreadsheet
                        tableId={response.typeOptions.table}
                        tableViewId={tableView}
                        processId={process.publicId}
                        processSectionId={section.publicId}
                        processResponseId={response.publicId}
                        permissionCap={PERMISSIONS.contributor}
                        process={process}
                      />
                    </div>
                  ) : (
                    <div style={{ height: '600px' }} />
                  )}

                  {response.enableSubmission && (
                    <div style={{ display: 'flex', width: '100%', paddingTop: '5px', paddingBottom: '5px' }}>
                      <Button
                        style={{ marginLeft: 'auto', maxWidth: '170px' }}
                        onClick={() => setSubmitResponseDialog({ open: true })}
                      >
                        Submit Response
                      </Button>
                    </div>
                  )}
                </div>
              )}

            {response.type === 'Table' && response.typeOptions && !response.typeOptions.table && (
              <div>
                The document owner needs to select a table for you to respond to.
                <br />
                <br />
              </div>
            )}
          </div>
        </div>

        {submitResponseDialog.open && (
          <Modal
            id="submit-response-modal"
            open={submitResponseDialog.open}
            setOpen={() => setSubmitResponseDialog({ open: false })}
            title="Submit Response"
          >
            Are you sure you would like to submit this response? You will not be able to change this response once it
            has been submitted.
            <div className="flex items-center justify-end mt-20px">
              <Button internalType="danger" onClick={() => setSubmitResponseDialog({ open: false })}>
                Cancel
              </Button>

              <Button internalType="accept" style={{ marginLeft: '10px' }} onClick={() => updateResponse()}>
                Confirm
              </Button>
            </div>
          </Modal>
        )}
      </div>
    )
  } else {
    return <span className="skeleton-box" style={{ width: '100%', height: '300px', marginTop: '20px' }} />
  }
}

export default Response
