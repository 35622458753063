import React, { useState } from 'react'
import constants from 'style/constants.module.scss'

export interface ITableData {
  label: any
  value: string | number | Date
}

interface TableProps {
  data: Array<Record<string, ITableData>>
  include: Array<any>
  defaultSort: string
  defaultSortAscending: boolean
  sort?: boolean
  loading?: boolean
  highlightColumn?: string
  highlightColor?: string
}

export const Table: React.FC<TableProps> = ({
  data,
  include,
  defaultSort,
  defaultSortAscending,
  sort,
  loading,
  highlightColumn,
  highlightColor
}: TableProps) => {
  const [sortBy, setSortBy] = useState<string>(defaultSort)
  const [sortAscending, setSortAscending] = useState<boolean>(defaultSortAscending)

  const rowSort = (a: Record<string, ITableData>, b: Record<string, ITableData>) => {
    const aValue = a[sortBy].value ? a[sortBy].value : ''
    const bValue = b[sortBy].value ? b[sortBy].value : ''
    if (!isNaN(Date.parse(aValue.toString()))) {
      const dateA = new Date(aValue).getTime()
      const dateB = new Date(bValue).getTime()
      if (dateA - dateB > 0) return sortAscending ? -1 : 1
      else if (dateA - dateB < 0) return sortAscending ? 1 : -1
      else return 0
    } else {
      if (aValue > bValue) return sortAscending ? -1 : 1
      else if (aValue < bValue) return sortAscending ? 1 : -1
      else return 0
    }
  }

  function handleClick(name: string) {
    setSortAscending(!sortAscending)
    setSortBy(name)
  }

  return (
    <table className="block h-full overflow-auto text-base-sm overflow-auto border-collapse bg-transparent margin-0 text-primary rounded">
      <thead className="select-none">
        <tr style={{ height: '40px' }}>
          {include.map(function (includeTerm, index) {
            return (
              <th
                className={`text-left align-middle font-semibold bg-grey ${sort !== false && 'cursor-pointer'}`}
                key={index}
                onClick={() => {
                  if (sort !== false) handleClick(includeTerm.id)
                }}
                style={{ padding: '10px' }}
              >
                {includeTerm.header}
                {sortBy === includeTerm.id && (sortAscending ? <span>↑</span> : <span>↓</span>)}
              </th>
            )
          })}
        </tr>
      </thead>

      <tbody>
        {loading && (
          <div style={{ display: 'contents' }}>
            <tr className="border-b-1px border-solid border-grey" style={{ height: '30px' }}>
              {include.map((_, index: number) => {
                return (
                  <td key={index} className="text-left align-middle whitespace-nowrap" style={{ padding: '10px' }}>
                    <span className="skeleton-box" style={{ marginTop: '10px', width: '100%', height: '30px' }} />
                  </td>
                )
              })}
            </tr>
            <tr className="border-b-1px border-solid border-grey" style={{ height: '30px' }}>
              {include.map((_, index: number) => {
                return (
                  <td key={index} className="text-left align-middle whitespace-nowrap" style={{ padding: '10px' }}>
                    <span className="skeleton-box" style={{ marginTop: '10px', width: '100%', height: '30px' }} />
                  </td>
                )
              })}
            </tr>
            <tr className="border-b-1px border-solid border-grey" style={{ height: '30px' }}>
              {include.map((_, index: number) => {
                return (
                  <td key={index} className="text-left align-middle whitespace-nowrap" style={{ padding: '10px' }}>
                    <span className="skeleton-box" style={{ marginTop: '10px', width: '100%', height: '30px' }} />
                  </td>
                )
              })}
            </tr>
          </div>
        )}
        {!loading &&
          data.sort(rowSort).map((row, index) => {
            return (
              <tr key={index} className="border-b-1px border-solid border-grey" style={{ height: '30px' }}>
                {include.map((includeObject: { header: string; id: string }, index: number) => {
                  return (
                    <td
                      key={index}
                      className="text-left align-middle whitespace-nowrap"
                      style={{
                        padding: '10px',
                        backgroundColor:
                          highlightColumn && highlightColor && includeObject.id === highlightColumn
                            ? highlightColor
                            : highlightColumn && includeObject.id === highlightColumn
                            ? constants.lightBlue
                            : 'none'
                      }}
                    >
                      {row[includeObject.id].label}
                    </td>
                  )
                })}
              </tr>
            )
          })}
      </tbody>
    </table>
  )
}
