import React, { useState, useEffect } from 'react'
import Modal, { ModalProps } from 'components/modal'
import { PERMISSIONS } from 'helpers/auth'
import DocumentComponent from 'components/document'
import { capitaliseFirstLetter } from 'helpers/utils'
import Button from 'components/button'
import useAuth from 'hooks/auth'
import useApplicationStore from 'hooks/application'
import api from 'helpers/api'
import { IProject } from 'types'
import { PROJECT } from 'app-constants'

interface HomeTemplateModalProps extends ModalProps {
  templateName: string
  templateId: string
}

const HomeTemplateModal: React.FC<HomeTemplateModalProps> = ({
  id,
  open,
  setOpen,
  onClose,
  templateId,
  templateName
}) => {
  const { user } = useAuth()
  const { displayErrorMessage, setSnackbarMessage } = useApplicationStore()

  const [projects, setProjects] = useState<IProject[]>()
  const [selectedProject, setSelectedProject] = useState<string>('')

  useEffect(() => {
    api
      .getOwnerProjects()
      .then((response) => {
        if (response && response.data) {
          setProjects(
            response.data
              .filter((project: IProject) => !project.isDeleted)
              .sort((a: IProject, b: IProject) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
          )
        }
      })
      .catch((error) => {
        displayErrorMessage(error)
      })
  }, [])

  const duplicateProcess = () => {
    api
      .duplicateProcess({
        publicId: templateId,
        targetProjectId: selectedProject,
        duplicatePermissions: true
      })
      .then(() => {
        setSnackbarMessage({
          status: 'success',
          message: 'Template duplication has started. You will receive an email once it has been complete.'
        })
        setOpen(false)
      })
      .catch((error) => {
        displayErrorMessage(error)
      })
  }

  return (
    <Modal
      id={id}
      open={open}
      setOpen={setOpen}
      title={`Template: ${templateName}`}
      removePadding={true}
      onClose={onClose}
    >
      <div>
        <div style={{ height: '50vh' }}>
          <DocumentComponent processPublicId={templateId} permissionCap={PERMISSIONS.viewer} templateRender={true} />
        </div>

        <div
          className="flex items-center justify-end border-t-2px border-solid border-grey"
          style={{ padding: '20px' }}
        >
          <div className="ml-auto">Select {capitaliseFirstLetter(PROJECT)}:</div>
          <select
            onChange={(e) => setSelectedProject(e.target.value)}
            value={selectedProject}
            style={{ width: '200px', marginLeft: '10px' }}
          >
            <option value="">Select a {capitaliseFirstLetter(PROJECT)}</option>
            {projects &&
              projects.map((project) => (
                <option key={project.publicId} value={project.publicId}>
                  {project.name}
                </option>
              ))}
          </select>
          <Button
            className="text-base"
            internalType="accept"
            style={{ marginLeft: '10px' }}
            onClick={() => duplicateProcess()}
            disabled={!user || selectedProject === ''}
          >
            {user ? 'Use This Template' : 'Sign In To Use This Template'}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default HomeTemplateModal
