import React, { useEffect, useState } from 'react'
import Modal, { ModalProps } from 'components/modal'
import Button from 'components/button'
import api from 'helpers/api'
import { IUserObject, IApiKey, IProject, ITable, IProcess } from 'types'
import { Copy, Plus } from 'components/icons'
import useApplicationStore from 'hooks/application'
import Select from 'components/select'
import { PROJECT } from 'app-constants'
import { capitaliseFirstLetter } from 'helpers/utils'

interface KeyModalProps extends ModalProps {
  user: IUserObject
  setUserData: (user: IUserObject) => void
  mode: 'new' | 'edit'
  initialApiKey: IApiKey
}

const KeyModal: React.FC<KeyModalProps> = ({ id, open, setOpen, onClose, setUserData, mode, initialApiKey, user }) => {
  const { displayErrorMessage } = useApplicationStore()

  const [apiKey, setAPIKey] = useState<IApiKey>(initialApiKey)
  const [stage, setStage] = useState(mode === 'new' ? { key: '', number: 0 } : null)
  const [projects, setProjects] = useState<IProject[]>([])
  const [fetchedProjects, setFetchedProjects] = useState<string[]>([])
  const [loadingProjects, setLoadingProjects] = useState<boolean>(false)
  const [tables, setTables] = useState<ITable[]>([])
  const [documents, setDocuments] = useState<IProcess[]>([])
  const [loadingResources, setLoadingResources] = useState<boolean>(false)

  useEffect(() => {
    setLoadingProjects(true)
    api
      .getOwnerProjects()
      .then((response) => {
        setProjects(response.data)
        setLoadingProjects(false)
      })
      .catch((error) => {
        displayErrorMessage(error)
        setLoadingProjects(false)
      })
  }, [])

  useEffect(() => {
    setLoadingResources(true)
    if (apiKey.projectRestrictions) {
      for (const project of apiKey.projectRestrictions) {
        if (!fetchedProjects.includes(project)) {
          getProjectResources(project).then((response) => {
            setTables(tables.concat(response.tables))
            setDocuments(documents.concat(response.documents))
            setFetchedProjects([...fetchedProjects, project])
          })
        }
      }
    }
    setLoadingResources(false)
  }, [apiKey.projectRestrictions])

  async function handleSubmit(): Promise<null | undefined> {
    if (!apiKey || apiKey.name === '') return null

    if (mode === 'new') {
      try {
        const response = await api.createApiKey(apiKey)
        setStage({ key: response.data.tempKey, number: 1 })
        setUserData({
          ...user,
          apiKeys: [...user.apiKeys, response.data]
        })
      } catch (error) {
        displayErrorMessage(error)
      }
    } else {
      try {
        await api.updateApiKey(apiKey)
        setUserData({
          ...user,
          apiKeys: user.apiKeys.map((key) => {
            if (key.hash === apiKey.hash) {
              return apiKey
            }
            return key
          })
        })
        if (onClose) onClose()
        setOpen(false)
      } catch (error) {
        displayErrorMessage(error)
      }
    }
  }

  // Only used in new mode once a key is generated.
  function handleSave(): void {
    setAPIKey({
      name: 'New API Key',
      accessLevel: 1,
      projectRestrictions: [],
      tableRestrictions: [],
      documentRestrictions: [],
      hash: '',
      publicId: '',
      prefix: ''
    })
    setStage({ key: '', number: 0 })
    setOpen(false)
  }

  const getProjectResources = async (projectPublicId: string) => {
    try {
      const tablesResponse = await api.getProjectTables(projectPublicId)
      const documentsResponse = await api.getProjectProcesses(projectPublicId)
      return { tables: tablesResponse.data, documents: documentsResponse.data }
    } catch (error) {
      displayErrorMessage(error)
      return { tables: [], documents: [] }
    }
  }

  const renderForm = () => (
    <div>
      <div className="flex flex-column mb-8" style={{ gap: '20px' }}>
        <div className="font-bold">API Key Name</div>
        <p>This should be a name that will remind you what the API key is for.</p>
        <input
          className="w-full"
          data-cy="api-key-name"
          placeholder="e.g. PowerBI Dashboard"
          required
          style={{ width: '100%' }}
          value={apiKey.name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
            setAPIKey({ ...apiKey, name: event.target.value })
          }
        />
      </div>

      <div className="flex flex-column mb-8" style={{ gap: '20px' }}>
        <div className="font-bold">Read + Write Restriction</div>
        <p>
          You can restrict this API key to only have read or write access. If you are unsure, select both. You can
          always change this later.
        </p>
        <Select
          options={[
            { value: '0', label: 'Read' },
            { value: '1', label: 'Read + Write' }
          ]}
          optionsSelected={apiKey.accessLevel !== undefined ? [apiKey.accessLevel.toString()] : []}
          onOptionClick={(value: string): void => setAPIKey({ ...apiKey, accessLevel: Number.parseInt(value) })}
        />
      </div>

      <div className="flex flex-column mb-8" style={{ gap: '20px' }}>
        <div className="font-bold">{capitaliseFirstLetter(PROJECT)} Restriction</div>
        <p>
          You can restrict this API key to only have access to particular {PROJECT}s. If you are unsure, leave blank
          (allowing access to all).
        </p>
        <Select
          multiselect={true}
          options={projects.map((project) => ({
            value: project.publicId,
            label: project.name
          }))}
          optionsSelected={apiKey.projectRestrictions || []}
          loading={loadingProjects}
          onOptionClick={(value: string) => {
            if (apiKey.projectRestrictions.includes(value)) {
              setAPIKey({
                ...apiKey,
                projectRestrictions: apiKey.projectRestrictions.filter((project) => project !== value),
                tableRestrictions: apiKey.tableRestrictions.filter(
                  () => !tables?.find((tbl) => tbl.projectPublicId === value)
                ),
                documentRestrictions: apiKey.documentRestrictions.filter(
                  () => !documents?.find((d) => d.projectPublicId === value)
                )
              })
              setFetchedProjects(fetchedProjects.filter((project) => project !== value))
              setTables(tables?.filter((table) => table.projectPublicId !== value))
              setDocuments(documents?.filter((document) => document.projectPublicId !== value))
            } else {
              setAPIKey({ ...apiKey, projectRestrictions: [...apiKey.projectRestrictions, value] })
            }
          }}
        />
      </div>

      <div className="flex flex-column mb-8" style={{ gap: '20px' }}>
        <div className="font-bold">Document Restriction</div>
        <p>
          You can restrict this API key to only have access to particular documents. If you are unsure, leave blank
          (allowing access to all).
        </p>
        <Select
          multiselect={true}
          options={
            documents
              ? documents.map((document) => ({
                  value: document.publicId,
                  label: document.name,
                  group: document.projectName
                }))
              : []
          }
          optionsSelected={apiKey.documentRestrictions}
          onOptionClick={(value: string) => {
            if (apiKey.documentRestrictions.includes(value)) {
              setAPIKey({
                ...apiKey,
                documentRestrictions: apiKey.documentRestrictions.filter((doc) => doc !== value)
              })
            } else {
              setAPIKey({ ...apiKey, documentRestrictions: [...apiKey.documentRestrictions, value] })
            }
          }}
          loading={loadingResources}
          groupBy={true}
        />
      </div>

      <div className="flex flex-column mb-8" style={{ gap: '20px' }}>
        <div className="font-bold">Table Restriction</div>
        <p>
          You can restrict this API key to only have access to particular tables. If you are unsure, leave blank
          (allowing access to all).
        </p>
        <Select
          multiselect={true}
          options={
            tables
              ? tables.map((table) => ({
                  value: table.publicId,
                  label: table.name,
                  group: table.projectName
                }))
              : []
          }
          optionsSelected={apiKey.tableRestrictions}
          onOptionClick={(value: string) => {
            if (apiKey.tableRestrictions.includes(value)) {
              setAPIKey({
                ...apiKey,
                tableRestrictions: apiKey.tableRestrictions.filter((tbl) => tbl !== value)
              })
            } else {
              setAPIKey({ ...apiKey, tableRestrictions: [...apiKey.tableRestrictions, value] })
            }
          }}
          groupBy={true}
          loading={loadingResources}
        />
      </div>

      <div className="flex justify-end mt-20px">
        <Button
          data-cy={mode === 'new' ? 'generate-new-api-key' : 'api-key-save'}
          internalType="accept"
          className="text-base"
          style={{ marginTop: '40px' }}
          onClick={() => handleSubmit()}
        >
          {mode === 'new' ? (
            <>
              <Plus style={{ marginRight: '5px' }} />
              Generate New API Key
            </>
          ) : (
            'Save Changes'
          )}
        </Button>
      </div>
    </div>
  )

  return (
    <Modal
      id={id}
      open={open}
      setOpen={setOpen}
      onClose={onClose}
      title={mode === 'new' ? 'New API Key' : 'Update API Key'}
    >
      {mode === 'new' && stage && stage.number === 1 ? (
        <div>
          <p>
            Here is your API key – please make sure you save this in a safe place as we do not store your API key for
            security reasons.
          </p>
          <div className="flex flex-row items-center rounded heavy-shadow w-full p-10px flex-wrap my-30px border-1px border-solid border-black select-none">
            <div data-cy="generated-api-key">{stage.key}</div>
            <span
              onClick={(): Promise<void> => navigator.clipboard.writeText(stage.key)}
              style={{ cursor: 'pointer', marginLeft: 'auto' }}
            >
              <Copy />
            </span>
          </div>
          <div className="flex justify-end mt-20px">
            <Button data-cy="saved-key" onClick={handleSave}>
              I Have Saved My Key
            </Button>
          </div>
        </div>
      ) : (
        renderForm()
      )}
    </Modal>
  )
}

export default KeyModal
