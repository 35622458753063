import React from 'react'
import { history } from 'helpers/history'
import { useAuth } from 'hooks/auth'
import { SIGN_IN } from 'routes'

// According to the openid spec, front channel logout is a url
// that is embedded within an iframe, which will cause the currently logged in
// user to be logged out
const FrontChannelLogout: React.FC = () => {
  const { logout, user } = useAuth()

  const logoutUser = async () => {
    logout()
    history.push(SIGN_IN)
  }

  React.useEffect(() => {
    if (user) {
      logoutUser()
    }
  }, [])

  return <div>{user ? 'Logging out user' : 'User logged out'}</div>
}

export default FrontChannelLogout
