import React, { useState } from 'react'
import Modal, { ModalProps } from 'components/modal'
import useAuth from 'hooks/auth'
import api, { APIError } from 'helpers/api'
import Button from 'components/button'
import { useDataContext } from 'components/spreadsheet/contexts/data'
import useApplicationStore from 'hooks/application'
import Integration from 'components/integrations'
import { IntegrationProviders } from 'app-constants'
import { IIntegrationSyncResourceTypes } from 'types'
import {
  getIsProviderSynced,
  getProviderLogo,
  syncIntegration
} from 'components/spreadsheet/components/modal/views/integration/helpers/sync'
import useProject from 'hooks/project'
import { HUB_BASE } from 'routes'

interface IntegrationModalProps extends ModalProps {
  provider: IntegrationProviders
  integrationName: string
  dataType: IIntegrationSyncResourceTypes
}

const IntegrationModal: React.FC<IntegrationModalProps> = ({
  id,
  open,
  setOpen,
  provider,
  integrationName,
  dataType
}) => {
  const { user } = useAuth()
  const { project } = useProject()
  const { spreadsheetData } = useDataContext()
  const { setSnackbarMessage, displayErrorMessage } = useApplicationStore()

  const [syncingIntegration, setSyncingIntegration] = useState<boolean>(false)
  const [deletingIntegration, setDeletingIntegration] = useState<boolean>(false)
  const [updateOrDeleteSync, setUpdateOrDeleteSync] = useState<string>()

  const providerLogo = getProviderLogo(provider)
  const isIntegrationSynced = getIsProviderSynced(provider, spreadsheetData.tableDetails)

  const onSuccess = () => {
    setInterval(() => {
      api.getSyncIntegrationInfo(spreadsheetData.tableDetails.publicId).then((response) => {
        if (response && response.data && response.data.isSyncing === false) {
          setSyncingIntegration(false)
          window.location.reload()
        }
      })
    }, 3000)
  }

  const onFailure = (error: any) => {
    setSyncingIntegration(false)
    displayErrorMessage(error)
  }

  const handleFailureMessage = (error: string, tableId?: string, tableViewId?: string) => {
    setSnackbarMessage({
      status: 'error',
      message: error,
      action:
        tableId && tableViewId ? (
          <a
            style={{ color: 'inherit !important', border: '1px solid white', padding: '5px', borderRadius: '2px' }}
            href={`${HUB_BASE}${project.publicId}/table/${tableId}/view/${tableViewId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Open Table
          </a>
        ) : (
          <div />
        )
    })
  }

  const deleteIntegration = () => {
    const confirmation = window.confirm('Are you sure you want to delete this integration?')
    if (confirmation) {
      setDeletingIntegration(true)
      api
        .deleteIntegration(spreadsheetData.tableDetails.publicId, provider)
        .then(() => {
          window.location.reload()
        })
        .catch((e) => {
          setDeletingIntegration(false)
          displayErrorMessage(e)
          const error = e as APIError
          if (error.errors) {
            const errorEntry = error.errors[0]
            const attributes = errorEntry.attribute
            if (attributes && typeof attributes !== 'string') {
              const tableId = attributes.tableId
              const tableViewId = attributes.tableViewId
              handleFailureMessage(errorEntry.messages[0], tableId, tableViewId)
            } else {
              handleFailureMessage(errorEntry.messages[0])
            }
          } else {
            setSnackbarMessage({ status: 'error', message: 'There was an error deleting the integration.' })
          }
        })
    }
  }

  return (
    <Modal
      id={id}
      open={open}
      setOpen={setOpen}
      title={
        isIntegrationSynced && updateOrDeleteSync === 'update'
          ? `Update Sync From ${integrationName}`
          : isIntegrationSynced && updateOrDeleteSync === 'delete'
          ? `Delete Sync From ${integrationName}`
          : isIntegrationSynced && updateOrDeleteSync === undefined
          ? `Update or Delete Sync From ${integrationName}`
          : `Sync ${dataType} Data From ${integrationName}`
      }
      warningBanner={
        isIntegrationSynced && (updateOrDeleteSync === undefined || updateOrDeleteSync === 'update') ? false : true
      }
      warningBannerText={
        isIntegrationSynced
          ? 'This will delete all data currently in the table. Please download any data you wish to keep as CSV.'
          : syncingIntegration
          ? 'Your integration is currently being synced. This may take some time and you will be emailed when it is complete.'
          : 'You need to delete all rows in the table before setting up the sync.'
      }
    >
      {!syncingIntegration && (
        <img src={providerLogo} alt={`${provider} logo`} style={{ width: '300px', marginBottom: '30px' }} />
      )}
      {syncingIntegration && (
        <div className="flex items-center justify-center">
          <img src="/assets/images/connecting.gif" alt="loading" style={{ width: '400px' }} />
        </div>
      )}
      {!syncingIntegration && (!isIntegrationSynced || (isIntegrationSynced && updateOrDeleteSync === 'update')) && (
        <div>
          <div>
            You are {isIntegrationSynced ? 'updating' : 'setting up'} a sync with {integrationName}. This will copy data
            from your {integrationName} account into the Morta table.
          </div>

          {isIntegrationSynced && updateOrDeleteSync === 'update' && (
            <div style={{ marginTop: '20px' }}>
              No data will be deleted from the table. All new data from the updated sync will be combined with the
              existing data.
            </div>
          )}

          <Integration
            provider={provider}
            type={dataType}
            buttonLabel={isIntegrationSynced ? 'Update Sync' : 'Sync Data'}
            buttonLoading={syncingIntegration}
            buttonFunction={(props) =>
              syncIntegration(
                user,
                provider,
                props,
                dataType,
                syncingIntegration,
                setSyncingIntegration,
                spreadsheetData.tableDetails.publicId,
                onSuccess,
                onFailure,
                isIntegrationSynced
              )
            }
          />
        </div>
      )}

      {isIntegrationSynced && updateOrDeleteSync === undefined && (
        <div>
          <div style={{ marginBottom: '20px' }}>
            Please select whether you would like to update the details of the sync, or delete it.
          </div>
          <div className="flex items-center" style={{ marginBottom: '20px' }}>
            <Button
              className="text-base ml-auto"
              onClick={() => setUpdateOrDeleteSync('update')}
              internalType="accept"
              style={{ marginRight: '10px' }}
            >
              Update Sync
            </Button>
            <Button onClick={() => setUpdateOrDeleteSync('delete')} className="text-base " internalType="danger">
              Delete Sync
            </Button>
          </div>
        </div>
      )}

      {isIntegrationSynced && updateOrDeleteSync === 'delete' && (
        <div>
          <div style={{ marginBottom: '20px' }}>
            The data in this table is being synced from {integrationName} <b>once per day</b>.
          </div>
          <div>
            Are you sure you would like to delete the sync with {integrationName}? This will remove all data from this
            table and delete all columns related to your {integrationName} account.
          </div>
          <div className="flex" style={{ marginTop: '50px' }}>
            <Button
              className="text-base ml-auto"
              style={{ marginRight: '10px' }}
              internalType="accept"
              onClick={() => setUpdateOrDeleteSync(undefined)}
            >
              Go Back
            </Button>
            <Button
              className="text-base"
              internalType="danger"
              onClick={() => deleteIntegration()}
              isLoading={deletingIntegration}
            >
              I understand the consequences, delete the {integrationName} integration
            </Button>
          </div>
        </div>
      )}
    </Modal>
  )
}
export default IntegrationModal
