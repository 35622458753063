import React, { useState, useEffect } from 'react'
import { useApplicationStore } from 'hooks/application'
import { useProject } from 'hooks/project'
import { ISpreadsheetData, SpreadsheetReducerActions } from 'components/spreadsheet/types'
import { ITableViewColumn, ITableJoin, ITable, ITableView, ITableViewWithColumns, IMapJoinColumn } from 'types'
import {
  ALLOWED_VIEW_TYPES_ON_SELECT_OPTIONS,
  BLOCK_SELECT_OPTION_TYPES,
  FilterVariables
} from 'components/spreadsheet/constants/const'
import { saveCurrentView, updateTableJoin, createTableJoin } from 'components/spreadsheet/contexts/data/actions'
import Button from 'components/button'
import { Delete, Plus } from 'components/icons'
import Select from 'components/select'
import constants from 'style/constants.module.scss'
import { Table } from 'components/table'
import api from 'helpers/api'
import { ICreateTableJoin } from 'types'

export interface TableJoinEditProps {
  spreadsheetData: ISpreadsheetData
  selected: boolean
  joinTable?: ITableJoin
  setSpreadsheetData: React.Dispatch<SpreadsheetReducerActions>
  setOpen: (open: boolean) => void
  tables: ITable[]
  setScreen: (screen: number) => void
  setEditingJoin: (join: ITableJoin | undefined) => void
}

const TableJoinEdit: React.FC<TableJoinEditProps> = ({
  spreadsheetData,
  joinTable,
  selected,
  setSpreadsheetData,
  setOpen,
  tables,
  setScreen,
  setEditingJoin
}) => {
  const { project } = useProject()
  const { displayErrorMessage, setSnackbarMessage } = useApplicationStore()

  // Join state properties
  const [joinOptions, setJoinOptions] = useState<ICreateTableJoin>(
    joinTable
      ? {
          joinTableId: joinTable.joinTableId,
          joinViewId: joinTable.joinViewId,
          joinColumns: joinTable.joinColumns,
          dataColumns: joinTable.dataColumns.map((entry) => entry.sourceColumnId),
          isOneToMany: joinTable.isOneToMany
        }
      : {
          joinTableId: '',
          joinViewId: '',
          joinColumns: [],
          dataColumns: [],
          isOneToMany: false
        }
  )

  const [joinSelected, setJoinSelected] = useState<IMapJoinColumn>({ sourceColumnId: '', targetColumnId: '' })

  const [showDataSourceJoin, setShowDataSourceJoin] = useState<boolean>(false)
  const [addJoin, setAddJoin] = useState<boolean>(false)

  const [views, setViews] = useState<ITableView[]>()
  const [viewColumns, setViewColumns] = useState<ITableViewColumn[]>([])

  // other states
  const [processing, setProcessing] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)
  const [canEdit, setCanEdit] = useState<boolean>(true)
  const [userCheckboxSelection, setUserCheckboxSelection] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const [errorMessageView, setErrorMessageView] = useState<string>()

  // effects
  useEffect(() => {
    if (joinTable && selected) {
      api
        .getTableViews(joinTable.joinTableId!, { ignoreColumns: true })
        .then((response) => {
          setViews(getAllowedViews(response.data))
        })
        .catch((error) => {
          setCanEdit(false)
          handleErrorMessage(error)
        })
      api
        .getTableView(joinTable.joinViewId, { ignoreCachedOptions: true })
        .then((response) => {
          const view = response.data
          setViewColumns(view.columns)
        })
        .catch((error) => {
          setCanEdit(false)
          handleErrorMessage(error)
        })
      setUserCheckboxSelection(joinTable.isOneToMany)
      setJoinOptions({
        joinTableId: joinTable.joinTableId,
        joinViewId: joinTable.joinViewId,
        joinColumns: joinTable.joinColumns,
        dataColumns: joinTable.dataColumns.map((entry) => entry.sourceColumnId),
        isOneToMany: joinTable.isOneToMany
      })
    }
  }, [joinTable, selected])

  useEffect(() => {
    if (success) {
      saveCurrentView(project.publicId, spreadsheetData, setSpreadsheetData, handleOnUpdateSuccess, handleOnError)
    }
  }, [success])

  const handleErrorMessage = (error: any) => {
    if (error.status == 403) setErrorMessage('You do not have owner access to the underlying table')
  }

  const handleOnTableChange = (tableId: string) => {
    setViews(undefined)
    setJoinOptions({ joinTableId: tableId, joinViewId: '', joinColumns: [], dataColumns: [], isOneToMany: false })
    setErrorMessageView('')
    api
      .getTableViews(tableId, { ignoreColumns: true })
      .then((response) => {
        setJoinSelected({ targetColumnId: '', sourceColumnId: '' })
        setAddJoin(false)
        setShowDataSourceJoin(false)
        setViews(getAllowedViews(response.data))
      })
      .catch(() => {
        setJoinOptions({ joinTableId: '', joinViewId: '', joinColumns: [], dataColumns: [], isOneToMany: false })
      })
  }

  const getAllowedViews = (views: ITableViewWithColumns[]) => {
    return views.filter((view) => ALLOWED_VIEW_TYPES_ON_SELECT_OPTIONS.includes(view.type))
  }

  const handleOnViewChange = (viewId: string) => {
    const view = views!.find((view) => view.publicId === viewId)
    const hasFilterVariables = view?.filterSettings?.some((filter) => {
      const checkInMultiple = filter.multipleValues?.some((value) => FilterVariables.includes(value))
      return FilterVariables.includes(filter.value) || checkInMultiple
    })

    if (hasFilterVariables) {
      setErrorMessageView('The selected view has filter variables. Please select a view without filter variables.')
      setJoinOptions({ ...joinOptions, joinViewId: viewId })

      return
    }
    setErrorMessageView('')
    api
      .getTableView(viewId, { ignoreCachedOptions: true })
      .then((response) => {
        const view = response.data as ITableViewWithColumns
        setViewColumns(view.columns)
        setJoinOptions({
          ...joinOptions,
          joinViewId: viewId,
          joinColumns: [],
          dataColumns: []
        })
        setJoinSelected({ targetColumnId: '', sourceColumnId: '' })
        setAddJoin(false)
        setShowDataSourceJoin(false)
      })
      .catch(() => {
        setJoinOptions({
          joinTableId: '',
          joinViewId: '',
          joinColumns: [],
          dataColumns: [],
          isOneToMany: false
        })
        setJoinSelected({ targetColumnId: '', sourceColumnId: '' })
        setViewColumns([])
      })
  }

  const handleOnDataTargetJoinColumnChange = (columnId: string) => {
    if (!canEdit) return

    const foundColumn = spreadsheetData.viewDetails.columns.find((col) => col.publicId === columnId)
    if (foundColumn?.isJoined) {
      setSnackbarMessage({
        status: 'error',
        message: `Column ${foundColumn.name} is a joined column and cannot be selected.`
      })
      return
    }

    if (foundColumn && BLOCK_SELECT_OPTION_TYPES.includes(foundColumn.kind)) {
      setJoinSelected({ targetColumnId: '', sourceColumnId: '' })
      setAddJoin(false)
      setSnackbarMessage({
        status: 'error',
        message: `${foundColumn.kind} options are not applicable for joins`
      })
      return
    }

    let sourceColumnId = ''
    if (foundColumn && foundColumn.kind == 'select') {
      const needsJoin =
        foundColumn.kindOptions?.tableOptions?.tableId !== joinOptions.joinTableId ||
        foundColumn.kindOptions?.tableOptions?.viewId !== joinOptions.joinViewId
      setShowDataSourceJoin(needsJoin)
      setAddJoin(!needsJoin)
      if (!needsJoin) {
        sourceColumnId = foundColumn.kindOptions?.tableOptions?.columnId || ''
      }
    } else {
      setShowDataSourceJoin(true)
      setAddJoin(false)
    }

    setJoinSelected({ targetColumnId: columnId, sourceColumnId })
  }

  const handleOnDataSourceJoinColumnChange = (columnId: string) => {
    const foundColumn = viewColumns.find((column) => column.publicId === columnId)
    if (foundColumn?.isJoined) {
      setSnackbarMessage({
        status: 'error',
        message: `Column ${foundColumn.name} is a joined column and cannot be selected.`
      })
      return
    }
    if (foundColumn && BLOCK_SELECT_OPTION_TYPES.includes(foundColumn.kind)) {
      setAddJoin(false)
      setSnackbarMessage({
        status: 'error',
        message: `${foundColumn.kind} options are not applicable for joins`
      })
      return
    }

    setJoinSelected({ ...joinSelected, sourceColumnId: columnId })
    setAddJoin(true)
  }

  const handleAddJoinColumn = () => {
    if (!canEdit) return

    const newJoins = [...joinOptions.joinColumns]
    newJoins.push(joinSelected)
    const newJoinOptions = {
      ...joinOptions,
      joinColumns: newJoins
    }

    const blockCheck = newJoins.map((joinCondition) => allowOnlyIsOneToMany(joinCondition)).some((value) => value)

    if (!blockCheck) {
      newJoinOptions.isOneToMany = userCheckboxSelection
    } else {
      newJoinOptions.isOneToMany = true
    }
    setJoinOptions(newJoinOptions)
    setAddJoin(false)
    setShowDataSourceJoin(false)
    setJoinSelected({ targetColumnId: '', sourceColumnId: '' })
  }

  const handleDeleteJoinColumn = (index: number) => {
    if (processing || !canEdit || viewColumns.length === 0) return

    const newJoins = [...joinOptions.joinColumns]
    newJoins.splice(index, 1)

    const blockCheck = joinOptions.joinColumns
      .map((joinCondition) => allowOnlyIsOneToMany(joinCondition))
      .some((value) => value)

    const isOneToMany = blockCheck ? true : userCheckboxSelection

    const newJoinOptions = {
      ...joinOptions,
      joinColumns: newJoins,
      isOneToMany
    }
    setJoinOptions(newJoinOptions)
  }

  const handleOnClickColumn = (columnId: string) => {
    const column = viewColumns.find((column) => column.publicId === columnId)
    if (!column || processing || !canEdit) return

    if (column.isJoined) {
      setSnackbarMessage({
        status: 'error',
        message: `Column ${column.name} is a joined column and cannot be selected.`
      })
      return
    }

    let newImportedColumns = [...joinOptions.dataColumns]
    if (newImportedColumns.includes(columnId)) {
      newImportedColumns = joinOptions.dataColumns.filter((colId) => colId !== columnId)
    } else {
      newImportedColumns.push(columnId)
    }
    setJoinOptions({ ...joinOptions, dataColumns: newImportedColumns })
  }

  const handleAddTableJoin = () => {
    if (processing || !canEdit) return

    setProcessing(true)
    const newJoinOptions = { ...joinOptions }
    delete newJoinOptions['joinTableId']

    createTableJoin(
      spreadsheetData,
      setSpreadsheetData,
      newJoinOptions,
      project.publicId,
      () => setSuccess(true),
      handleOnError
    )
  }

  const handleUpdateTableJoin = () => {
    if (processing || !canEdit) return
    setProcessing(true)
    const newJoinOptions = { ...joinOptions }
    delete newJoinOptions['joinTableId']
    updateTableJoin(
      spreadsheetData,
      setSpreadsheetData,
      joinTable!.publicId,
      newJoinOptions,
      project.publicId,
      () => setSuccess(true),
      handleOnError
    )
  }

  const handleOnUpdateSuccess = async () => {
    setSuccess(false)
    setProcessing(false)
    setSnackbarMessage({ status: 'success', message: joinTable ? 'Updated table look up' : 'Created table look up' })
    setOpen(false)
  }

  const handleOnError = (error: any) => {
    setSuccess(false)
    setProcessing(false)
    displayErrorMessage(error)
  }

  const handleCheckOneToMany = (isOneToMany: boolean) => {
    if (!canEdit || viewColumns.length === 0) return

    const blockCheck = joinOptions.joinColumns
      .map((joinCondition) => allowOnlyIsOneToMany(joinCondition))
      .some((value) => value)

    if (blockCheck) {
      isOneToMany = true
      setSnackbarMessage({
        status: 'warning',
        message: `You are not allowed uncheck the 'Is one to many' box while you have a plain target column joined with a multi-select source column.`
      })
    }
    const newJoinOptions = {
      ...joinOptions,
      isOneToMany: isOneToMany
    }
    setJoinOptions(newJoinOptions)
  }

  const allowOnlyIsOneToMany = (joinCondition: { targetColumnId: string; sourceColumnId: string }) => {
    const targetColumn = spreadsheetData.tableDetails.columns.find(
      (col) => col.publicId === joinCondition.targetColumnId
    )
    const sourceColumn = viewColumns.find((col) => col.publicId === joinCondition.sourceColumnId)

    if (!targetColumn || !sourceColumn) {
      displayErrorMessage('The target column or source column cannot be found. Please refresh and try again.')
      return false
    }

    const multiselect_options = ['multiselect', 'multilink']
    return !multiselect_options.includes(targetColumn.kind) && multiselect_options.includes(sourceColumn.kind)
  }
  const joinTableName = tables.find((table: ITable) => table.publicId === joinOptions.joinTableId)?.name

  const updateTableJoinButton =
    joinTable &&
    (joinTable.joinViewId !== joinOptions.joinViewId ||
      joinTable.isOneToMany !== joinOptions.isOneToMany ||
      JSON.stringify(joinTable.joinColumns) !== JSON.stringify(joinOptions.joinColumns) ||
      JSON.stringify(joinTable.dataColumns.map((entry) => entry.sourceColumnId)) !==
        JSON.stringify(joinOptions.dataColumns))
  const addNewTableJoin =
    joinOptions.joinViewId && joinOptions.joinColumns.length > 0 && joinOptions.dataColumns.length > 0

  const enableButton = canEdit ? (joinTable ? updateTableJoinButton : addNewTableJoin) && !errorMessageView : false

  return (
    <div>
      {/* Lookup Source Configurations */}
      <div className="flex flex-row items-center w-full font-bold mb-30px py-10px border-b-2px border-solid border-grey">
        Look Up Source Configuration
      </div>
      <p style={{ marginBottom: '20px' }}>
        This is where you select the table and table view that contains the information you want to import into the
        current table.
      </p>
      <Select
        options={
          tables
            ? tables
                .map((table: ITable) => {
                  return { label: table.name, value: table.publicId }
                })
                .sort((a, b) => (a.label > b.label ? 1 : a.label < b.label ? -1 : 0))
            : []
        }
        optionsSelected={joinOptions.joinTableId ? [joinOptions.joinTableId] : []}
        onOptionClick={(option) => handleOnTableChange(option)}
        disabled={!canEdit}
        label="Select Look Up Source Table"
        error={errorMessage}
      />
      <div style={{ marginBottom: '20px' }} />
      <Select
        options={
          views
            ? views
                .map((view: ITableView) => {
                  return { label: view.name, value: view.publicId }
                })
                .sort((a, b) => (a.label > b.label ? 1 : a.label < b.label ? -1 : 0))
            : []
        }
        loading={canEdit && joinOptions.joinTableId && views === undefined ? true : false}
        disabled={!canEdit || views === undefined || !joinOptions.joinTableId}
        onOptionClick={(option) => handleOnViewChange(option)}
        optionsSelected={joinOptions.joinViewId ? [joinOptions.joinViewId] : []}
        label="Select Look Up Source Table View"
        error={errorMessageView}
      />

      {/* Lookup Matching Conditions */}
      {joinOptions.joinTableId && joinOptions.joinViewId && !errorMessageView && (
        <div>
          <div
            className="flex flex-row items-center w-full font-bold mb-30px py-10px border-b-2px border-solid border-grey"
            style={{ marginTop: '30px' }}
          >
            Look Up Matching Conditions
          </div>
          <p style={{ marginBottom: '20px' }}>
            This is where you define the matching conditions between the current table and the look up/source table.
            When values in the source table column match the values in the current/target table column, the data from
            the look up table will be imported into the current table.
          </p>

          <div style={{ marginBottom: '20px' }}>
            <Table
              include={[
                {
                  header: `Source Column (In Table '${joinTableName}')`,
                  id: 'sourceColumnName'
                },
                {
                  header: 'Source Column Kind',
                  id: 'sourceColumnMatchKind'
                },
                {
                  header: `Target Column (In Current Table '${spreadsheetData.tableDetails.name}')`,
                  id: 'targetColumnName'
                },
                {
                  header: 'Target Column Kind',
                  id: 'targetColumnMatchKind'
                },
                {
                  header: 'Delete Match Condition',
                  id: 'deleteMatchCondition'
                }
              ]}
              data={joinOptions.joinColumns.map((joinOption, index) => {
                const foundDataTargetJoinColumn = spreadsheetData.tableDetails.columns.find(
                  (col) => col.publicId === joinOption.targetColumnId
                )
                const sourceColumnJoinId =
                  joinOption.sourceColumnId || foundDataTargetJoinColumn?.kindOptions?.tableOptions?.columnId
                const sourceColumnMatch = viewColumns.find((col) => col.publicId === sourceColumnJoinId)
                const sourceColumnMatchName = sourceColumnMatch?.name
                const sourceColumnMatchKind = sourceColumnMatch?.kind

                return {
                  index: {
                    label: index,
                    value: index
                  },
                  sourceColumnName: {
                    label: sourceColumnMatchName ? sourceColumnMatchName : '',
                    value: sourceColumnMatchName ? sourceColumnMatchName : ''
                  },
                  sourceColumnMatchKind: {
                    label: sourceColumnMatchKind ? sourceColumnMatchKind : '',
                    value: sourceColumnMatchKind ? sourceColumnMatchKind : ''
                  },
                  targetColumnName: {
                    label: foundDataTargetJoinColumn?.name ? foundDataTargetJoinColumn?.name : '',
                    value: foundDataTargetJoinColumn?.name ? foundDataTargetJoinColumn?.name : ''
                  },
                  targetColumnMatchKind: {
                    label: foundDataTargetJoinColumn?.kind ? foundDataTargetJoinColumn?.kind : '',
                    value: foundDataTargetJoinColumn?.kind ? foundDataTargetJoinColumn?.kind : ''
                  },
                  deleteMatchCondition: {
                    label: (
                      <div className="flex items-center justify-center">
                        <Button
                          onClick={() => handleDeleteJoinColumn(index)}
                          internalType="danger"
                          style={{ color: 'white' }}
                        >
                          <Delete />
                        </Button>
                      </div>
                    ),
                    value: 'Delete'
                  }
                }
              })}
              defaultSort="sourceColumnName"
              defaultSortAscending={true}
            />
          </div>

          <div className="border-1px border-solid" style={{ marginBottom: '30px' }}>
            <div
              className="border-b-1px border-solid font-bold"
              style={{ background: constants.lightGreen, padding: '10px' }}
            >
              Add New Matching Condition
            </div>
            <div style={{ padding: '10px' }}>
              <Select
                options={spreadsheetData.viewDetails.columns
                  .map((column) => {
                    return { label: `${column.name} (type: ${column.kind})`, value: column.publicId }
                  })
                  .sort((a, b) => (a.label > b.label ? 1 : a.label < b.label ? -1 : 0))}
                onOptionClick={(option) => handleOnDataTargetJoinColumnChange(option)}
                optionsSelected={joinSelected.targetColumnId ? [joinSelected.targetColumnId] : []}
                disabled={!canEdit}
                label="Select A Column From the Current Table (Target Column)"
              />
              {showDataSourceJoin && (
                <div style={{ marginTop: '20px' }}>
                  <Select
                    options={viewColumns
                      .map((column) => {
                        return { label: `${column.name} (type: ${column.kind})`, value: column.publicId }
                      })
                      .sort((a, b) => (a.label > b.label ? 1 : a.label < b.label ? -1 : 0))}
                    onOptionClick={(option) => handleOnDataSourceJoinColumnChange(option)}
                    optionsSelected={joinSelected.sourceColumnId ? [joinSelected.sourceColumnId] : []}
                    label="Select A Column From the Look Up Table (Source Column)"
                  />
                </div>
              )}
              <div className="flex items-center justify-end" style={{ marginTop: '10px' }}>
                <Button internalType="accept" onClick={() => handleAddJoinColumn()} disabled={!addJoin || !canEdit}>
                  <Plus />
                  Add New Condition
                </Button>
              </div>
            </div>
          </div>
          <Select
            options={[
              { value: 'Yes', label: 'Yes' },
              { value: 'No', label: 'No' }
            ]}
            optionsSelected={joinOptions.isOneToMany ? ['Yes'] : ['No']}
            onOptionClick={(option) => handleCheckOneToMany(option === 'Yes')}
            label="One-To-Many Configuration"
            info="A one-to-one relationship will match the first row in the source table that meets that match conditions. A one-to-many relationship will match all rows in the source that meet the match conditions and group the result."
          />
        </div>
      )}

      {/* Lookup Imported Columns */}
      {joinOptions.joinTableId && joinOptions.joinViewId && !errorMessageView && (
        <div>
          <div
            className="flex flex-row items-center w-full font-bold mb-30px py-10px border-b-2px border-solid border-grey"
            style={{ marginTop: '30px' }}
          >
            Look Up Imported Columns
          </div>
          <p style={{ marginBottom: '20px' }}>
            These are the columns you want to import from the look up table into the current table. You can select
            multiple columns to import.
          </p>

          <Select
            options={viewColumns
              .map((column) => {
                const isSelected = joinOptions.dataColumns.includes(column.publicId)
                const mapsTo =
                  isSelected &&
                  joinTable &&
                  joinTable.dataColumns.find((entry) => entry.sourceColumnId === column.publicId)?.targetColumnId
                const columnMapsTo =
                  mapsTo && spreadsheetData.viewDetails.columns.find((col) => col.publicId === mapsTo)
                return {
                  value: column.publicId,
                  label: selected && columnMapsTo ? `${column.name} maps to ${columnMapsTo.name}` : column.name
                }
              })
              .sort((a, b) => (a.label > b.label ? 1 : a.label < b.label ? -1 : 0))}
            optionsSelected={viewColumns
              .filter((column) => joinOptions.dataColumns.includes(column.publicId))
              .map((column) => column.publicId)}
            onOptionClick={(option) => handleOnClickColumn(option)}
            multiselect={true}
            disabled={!canEdit}
            label={'Select Imported Columns'}
          />
        </div>
      )}

      <div className="flex items-center justify-end" style={{ marginTop: '30px' }}>
        <Button
          internalType="danger"
          onClick={() => {
            setScreen(0)
            setEditingJoin(undefined)
          }}
          style={{ width: '175px' }}
        >
          Cancel
        </Button>
        <Button
          internalType="accept"
          onClick={() => {
            if (joinTable) {
              handleUpdateTableJoin()
            } else {
              handleAddTableJoin()
            }
          }}
          style={{ width: '175px', marginLeft: '5px' }}
          disabled={!enableButton}
        >
          {joinTable ? 'Update Look Up' : 'Save New Look Up'}
        </Button>
      </div>
    </div>
  )
}

export default React.memo(TableJoinEdit)
