import React, { useEffect, useState } from 'react'
import Menu, { MenuProps } from 'components/menu'
import { useApplicationStore } from 'hooks/application'
import Select from 'components/select'
import { useDataContext } from 'components/spreadsheet/contexts/data'
import { BLOCK_GROUP_BY, CHART_TYPES } from 'components/spreadsheet/constants/const'
import { ITableViewChart, IChartType } from 'types'
import Toggle from 'components/toggle'
import Button from 'components/button'
import Flag from 'components/flag'
import constants from 'style/constants.module.scss'

const ChartMenu: React.FC<MenuProps> = ({ id, menuState, setMenuState, width }) => {
  const { spreadsheetData, setSpreadsheetData } = useDataContext()
  const [chart, setChart] = useState<ITableViewChart>(
    spreadsheetData.userConfiguration.chartSettings === null
      ? spreadsheetData.viewDetails.chartSettings === null
        ? { chartType: null, showLegend: true, showValues: false, columnLabelId: '', columnValueId: '' }
        : spreadsheetData.viewDetails.chartSettings
      : spreadsheetData.userConfiguration.chartSettings
  )

  const { setSnackbarMessage } = useApplicationStore()

  useEffect(() => {
    if (
      chart &&
      (chart.chartType !== spreadsheetData.userConfiguration.chartSettings?.chartType ||
        chart.columnLabelId !== spreadsheetData.userConfiguration.chartSettings?.columnLabelId ||
        chart.columnValueId !== spreadsheetData.userConfiguration.chartSettings?.columnValueId ||
        chart.showLegend !== spreadsheetData.userConfiguration.chartSettings?.showLegend ||
        chart.showValues !== spreadsheetData.userConfiguration.chartSettings?.showValues)
    ) {
      setSpreadsheetData({
        type: 'ADD_CHART',
        chart: chart
      })
    }
  }, [chart])

  const handleSelectChartType = (chartType: IChartType) => {
    if (chart) {
      setChart({ ...chart, chartType: chartType })
    }
  }
  const handleAddLabelColumn = (columnId: string) => {
    if (chart) {
      const column = spreadsheetData.viewDetails.columns.find((column) => column.publicId === columnId)
      if (column) {
        const columnKind = column.kind
        if (BLOCK_GROUP_BY.includes(columnKind)) {
          setSnackbarMessage({ status: 'error', message: `Charts for columns of type ${columnKind} are not allowed` })
          return
        }
      }
      setChart({ ...chart, columnLabelId: columnId })
    }
  }

  const handleAddValueColumn = (columnId: string) => {
    if (chart) {
      const column = spreadsheetData.viewDetails.columns.find((column) => column.publicId === columnId)
      if (column) {
        const columnKind = column.kind
        if (BLOCK_GROUP_BY.includes(columnKind)) {
          setSnackbarMessage({ status: 'error', message: `Charts for columns of type ${columnKind} are not allowed` })
          return
        }
      }
      setChart({ ...chart, columnValueId: columnId })
    }
  }

  const handleShowLegend = (showLegend: boolean) => {
    if (chart) {
      setChart({ ...chart, showLegend: showLegend })
    }
  }

  const handleShowValues = (showValues: boolean) => {
    if (chart) {
      setChart({ ...chart, showValues: showValues })
    }
  }

  return (
    <Menu id={id} menuState={menuState} setMenuState={setMenuState} width={width}>
      <div style={{ maxHeight: 500, overflowY: 'auto' }}>
        <div className="w-full bg-light-grey font-bold p-10px text-base">Chart Settings</div>

        <div className="w-full" style={{ padding: '10px' }}>
          <Select
            label="Chart Type"
            options={CHART_TYPES}
            onOptionClick={(option) => handleSelectChartType(option as IChartType)}
            optionsSelected={!chart || chart.chartType === null ? [] : [chart.chartType]}
          />
        </div>
        <div className="w-full" style={{ padding: '10px' }}>
          <Select
            label="Column Containing Labels"
            options={spreadsheetData.viewDetails.columns.map((column) => {
              return { value: column.publicId, label: column.name }
            })}
            onOptionClick={(option) => handleAddLabelColumn(option)}
            disabled={!chart || chart.chartType === null}
            optionsSelected={!chart || chart.columnLabelId === '' ? [] : [chart.columnLabelId]}
          />
        </div>

        <div className="w-full" style={{ padding: '10px' }}>
          <Select
            label="Columns Containing Values"
            options={spreadsheetData.viewDetails.columns.map((column) => {
              return { value: column.publicId, label: column.name }
            })}
            onOptionClick={(option) => handleAddValueColumn(option)}
            disabled={!chart || chart.chartType === null}
            optionsSelected={!chart || chart.columnValueId === '' ? [] : [chart.columnValueId]}
          />
        </div>

        <div
          className="relative flex items-center rounded text-primary select-none truncate "
          style={{ padding: '10px', height: '50px' }}
        >
          <span>Show Legend</span>
          <Toggle
            toggled={chart && chart.showLegend}
            onToggle={(toggle) => {
              handleShowLegend(toggle)
            }}
          />
        </div>

        <div
          className="relative flex items-center rounded text-primary select-none truncate"
          style={{ padding: '10px', height: '50px' }}
        >
          <span>Show Values</span>
          <Toggle
            toggled={chart && chart.showValues}
            onToggle={(toggle) => {
              handleShowValues(toggle)
            }}
          />
        </div>

        <div className="flex items-center w-full bg-light-grey font-bold p-10px text-base mt-4">
          <span>Personalised Views</span>
          <Flag text="Coming Soon" color={constants.orange} />
        </div>
        <div
          className="relative flex items-center rounded text-primary select-none truncate"
          style={{ padding: '10px', height: '50px' }}
        >
          <span>Save Personalised View</span>
          <Button internalType="accept" disabled={true} className="ml-auto">
            Save
          </Button>
        </div>
      </div>
    </Menu>
  )
}

export default React.memo(ChartMenu)
