import React, { useState, useRef, useEffect } from 'react'
import Modal, { ModalProps } from 'components/modal'
import Button from 'components/button'
import useApplicationStore from 'hooks/application'
import Checkbox from 'components/checkbox'
import { ITableData, Table } from 'components/table'
import { useDataContext } from 'components/spreadsheet/contexts/data'
import ErrorBoundary from 'components/error'
import useProject from 'hooks/project'
import {
  transformStringToCorrectFormat,
  detectLikelyDateFormat,
  detectLikelyDatetimeFormat,
  DATE_FORMAT_CANDIDATES,
  DATETIME_FORMAT_CANDIDATES
} from 'components/spreadsheet/helpers/paste'
import { convertRowDataToIdValueMap } from 'components/spreadsheet/helpers/functions'
import { AllowedDateFormats, AllowedDatetimeFormats, ITableRow } from 'types'
import Select from 'components/select'
import api from 'helpers/api'

const AppendCSVModal: React.FC<ModalProps> = ({ id, open, setOpen }) => {
  const { spreadsheetData, setSpreadsheetData } = useDataContext()
  const { project } = useProject()
  const { displayErrorMessage, setSnackbarMessage } = useApplicationStore()

  const inputRef = useRef<HTMLInputElement>(null)

  const [rawCsvData, setRawCsvData] = useState<string>()
  const [insertMethod, setInsertMethod] = useState<string>('upsert')
  const [mergingColumn, setMergingColumn] = useState<string>()
  const [parsedCsvData, setParsedCsvData] = useState<Array<Record<string, string>>>()
  const [excludeFirstRow, setExcludeFirstRow] = useState<boolean>(true)
  const [excludeBlankRows, setExcludeBlankRows] = useState<boolean>(true)
  const [fileName, setFileName] = useState<string>()
  const [loading, setLoading] = useState<boolean>(false)
  const [showAdditionalOptions, setShowAdditionalOptions] = useState<boolean>(false)
  // Defaults will be updated when file is loaded
  const [dateFormat, setDateFormat] = useState<AllowedDateFormats>('DD/MM/YYYY')
  const [datetimeFormat, setDatetimeFormat] = useState<AllowedDatetimeFormats>('DD/MM/YYYY HH:mm')

  const headers = spreadsheetData.viewDetails.columns
    .map((column) => {
      return {
        id: column.publicId,
        header:
          !column.isJoined &&
          !column.locked &&
          ((insertMethod === 'upsert' &&
            (column.scriptEnabled || column.formulaEnabled) &&
            column.name === mergingColumn) ||
            (!column.scriptEnabled && !column.formulaEnabled))
            ? column.name
            : `${column.name} 🔒`
      }
    })
    .filter((header) => !spreadsheetData.userConfiguration.hiddenColumns.includes(header.id))

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return false
    }

    const file = e.target.files[e.target.files.length - 1]
    setFileName(file.name)
    const reader = new FileReader()

    reader.onload = (e) => {
      if (e.target && e.target.result) {
        const csvData = e.target.result.toString()
        setRawCsvData(csvData)
      } else {
        setSnackbarMessage({
          status: 'error',
          message: 'There was an error reading your CSV file. Please try again.'
        })
      }
    }

    reader.readAsText(file)
  }

  useEffect(() => {
    if (rawCsvData && spreadsheetData.viewDetails.columns) {
      let errorMessage = ''
      const data: Array<Record<string, string>> = []

      try {
        errorMessage =
          'Unable to split the CSV data into rows. Please check each line in your CSV file is split using a new line character (\\r\\n).'
        const csvRows = rawCsvData.split('\r\n')

        errorMessage =
          'Unable to split the CSV data into columns. Please check each line in your CSV file is split using a comma (,).'

        const start = excludeFirstRow ? 1 : 0
        for (let i = start; i < csvRows.length; i++) {
          const rowData = csvRows[i].split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/)
          if (
            !excludeBlankRows ||
            (excludeBlankRows && rowData && (rowData.length > 1 || (rowData.length === 1 && rowData[0] !== '')))
          ) {
            const dataObject: Record<string, string> = {}
            for (let j = 0; j < headers.length; j++) {
              let value = rowData && rowData[j] && rowData[j] !== 'null' ? rowData[j].replaceAll('""', '"') : ''
              if (value.charAt(0) === '"' && value.charAt(value.length - 1) === '"') {
                value = value.substring(1, value.length - 1)
              }
              dataObject[headers[j].id] = value
            }
            data.push(dataObject)
          }
        }
        setParsedCsvData(data)

        // --- Format detection happens here, immediately after the CSV is parsed ---
        if (data.length > 0) {
          const allDateValues: string[] = []
          const allDatetimeValues: string[] = []
          spreadsheetData.viewDetails.columns.forEach((column) => {
            if (column.kind === 'date') {
              data.forEach((row) => {
                const val = row[column.publicId]
                if (val && val.trim() !== '') {
                  allDateValues.push(val)
                }
              })
            } else if (column.kind === 'datetime') {
              data.forEach((row) => {
                const val = row[column.publicId]
                if (val && val.trim() !== '') {
                  allDatetimeValues.push(val)
                }
              })
            }
          })

          if (allDateValues.length > 0) {
            const likelyDateFormat = detectLikelyDateFormat(allDateValues)
            setDateFormat(likelyDateFormat)
          }
          if (allDatetimeValues.length > 0) {
            const likelyDatetimeFormat = detectLikelyDatetimeFormat(allDatetimeValues)
            setDatetimeFormat(likelyDatetimeFormat)
          }
        }
      } catch {
        setSnackbarMessage({
          status: 'error',
          message: errorMessage
        })
      }
    }
  }, [
    excludeFirstRow,
    rawCsvData,
    spreadsheetData.viewDetails.columns,
    excludeBlankRows,
    setSnackbarMessage,
    spreadsheetData
  ])

  // Note: We removed the per‑row detection from handleImportData.
  const handleImportData = async () => {
    if (parsedCsvData) {
      try {
        const processContext = spreadsheetData.processId
          ? {
              processId: spreadsheetData.processId,
              processSectionId: spreadsheetData.processSectionId!,
              processResponseId: spreadsheetData.processResponseId!
            }
          : undefined

        const rawRows: Array<{ rowData: Record<string, unknown>; sortOrder?: number }> = parsedCsvData.map((data) => {
          return {
            rowData: cleanRow(data, insertMethod)
          }
        })

        setLoading(true)

        const ROW_LIMIT = 2500

        if (insertMethod === 'append') {
          const newRows: ITableRow[] = []
          for (let i = 0; i < rawRows.length; i += ROW_LIMIT) {
            const rows = rawRows.slice(i, i + ROW_LIMIT)
            const response = await api.createNewRows(
              project.publicId,
              spreadsheetData.viewDetails.publicId,
              rows,
              processContext
            )
            for (let i = 0; i < response.data.length; i++) {
              const responseRow = response.data[i]
              const createdRow = {
                ...responseRow,
                rowData: convertRowDataToIdValueMap(spreadsheetData.viewDetails.columns, responseRow.rowData)
              }
              newRows.push(createdRow)
            }
          }
          setSpreadsheetData({ type: 'APPEND_ROWS', rows: newRows })
          setLoading(false)
          setSnackbarMessage({ status: 'success' })
          setOpen(false)
        } else if (insertMethod === 'upsert' && mergingColumn) {
          for (let i = 0; i < rawRows.length; i += ROW_LIMIT) {
            const rows = rawRows.slice(i, i + ROW_LIMIT)
            await api.upsertRows(spreadsheetData.viewDetails.publicId, rows, mergingColumn)
          }
          window.location.reload()
        }
      } catch (e) {
        setLoading(false)
        displayErrorMessage(e)
      }
    }
  }

  const cleanRow = (row: Record<string, string>, insertMethod: string) => {
    const columns = Object.keys(row)
    const newRow: Record<string, unknown> = {}
    for (let i = 0; i < columns.length; i++) {
      const column = spreadsheetData.viewDetails.columns.find((column) => column.publicId === columns[i])
      const value = row[columns[i]]
      if (column && !spreadsheetData.userConfiguration.hiddenColumns.includes(column.publicId)) {
        if (
          value &&
          !column.isJoined &&
          !column.locked &&
          ((insertMethod === 'upsert' &&
            (column.scriptEnabled || column.formulaEnabled) &&
            column.name === mergingColumn) ||
            (!column.scriptEnabled && !column.formulaEnabled))
        ) {
          if (column.kind === 'date' || column.kind === 'datetime') {
            newRow[column.name] = transformStringToCorrectFormat(value, column.kind, dateFormat, datetimeFormat)
          } else {
            newRow[column.name] = transformStringToCorrectFormat(value, column.kind)
          }
        }
      }
    }
    return newRow
  }

  const getTableData = () => {
    const data: Array<Record<string, ITableData>> = []
    if (parsedCsvData) {
      for (let i = 0; i < parsedCsvData.length; i++) {
        const dataObject: Record<string, ITableData> = {}
        const rowData = parsedCsvData[i]
        const rowKeys = Object.keys(rowData)
        for (let j = 0; j < rowKeys.length; j++) {
          const key = rowKeys[j]
          const column = spreadsheetData.viewDetails.columns.find((column) => column.publicId === key)
          if (column && !spreadsheetData.userConfiguration.hiddenColumns.includes(column.publicId)) {
            const label =
              !column.isJoined &&
              !column.locked &&
              ((insertMethod === 'upsert' &&
                (column.scriptEnabled || column.formulaEnabled) &&
                column.name === mergingColumn) ||
                (!column.scriptEnabled && !column.formulaEnabled))
                ? rowData[key]
                : ''
            dataObject[key] = {
              value: j,
              label
            }
          }
        }
        data.push(dataObject)
      }
    }
    return data
  }

  return (
    <Modal id={id} open={open} setOpen={setOpen} title="Import Data From CSV File">
      {!fileName ? (
        <div>
          <div style={{ marginBottom: '20px ' }}>
            Please select the CSV file you wish to upload - for the moment we only allow you to upload CSV files that
            are delimited with a comma.
          </div>
          <Button
            onClick={(event) => {
              event.stopPropagation()
              inputRef.current!.click()
            }}
            style={{ width: '150px' }}
          >
            Select File
          </Button>
        </div>
      ) : (
        <span className="italic" style={{ marginBottom: '10px' }}>{`${fileName}${
          parsedCsvData && ` (${parsedCsvData.length} rows)`
        }`}</span>
      )}
      {fileName && parsedCsvData && (
        <div>
          <div style={{ marginTop: '10px', marginBottom: '10px' }}>
            <div style={{ marginBottom: '20px' }}>
              Would you like to append these rows to the end of the file or insert and merge these rows? Inserting and
              merging means that an existing row will be updated if a specified value already exists in a table (using
              the merge column) and inserted if a new row if the specified value does not exist.
            </div>
            <Select
              options={[
                {
                  label: 'Append',
                  value: 'append'
                },
                {
                  label: 'Insert and Merge',
                  value: 'upsert'
                }
              ]}
              optionsSelected={insertMethod ? [insertMethod] : []}
              onOptionClick={(option) => setInsertMethod(option)}
            />
            <div style={{ marginBottom: '20px' }} />
            {insertMethod === 'upsert' && (
              <Select
                options={spreadsheetData.viewDetails.columns.map((column) => {
                  return {
                    label: column.name,
                    value: column.name
                  }
                })}
                optionsSelected={mergingColumn ? [mergingColumn] : []}
                onOptionClick={(option) => setMergingColumn(option)}
              />
            )}
          </div>

          <Button
            internalType="outline"
            onClick={() => setShowAdditionalOptions(!showAdditionalOptions)}
            style={{ width: '200px', marginTop: '10px' }}
          >
            {showAdditionalOptions ? 'Hide' : 'Show'} Additional Options
          </Button>

          {showAdditionalOptions && (
            <div
              className="text-sm flex flex-column bg-light-grey rounded"
              style={{ gap: '10px', padding: '10px 20px', marginTop: '10px' }}
            >
              <Checkbox checked={excludeFirstRow} onChange={(event) => setExcludeFirstRow(event.target.checked)}>
                Exclude First Row
              </Checkbox>

              <Checkbox checked={excludeBlankRows} onChange={(event) => setExcludeBlankRows(event.target.checked)}>
                Exclude Blank Rows
              </Checkbox>

              <div className="flex items-center">
                <span className="mr-2" style={{ width: '200px' }}>
                  Date Format:
                </span>
                <select
                  value={dateFormat}
                  onChange={(event) => {
                    setDateFormat(event.target.value as AllowedDateFormats)
                  }}
                >
                  {DATE_FORMAT_CANDIDATES.map((format) => (
                    <option key={format} value={format}>
                      {format}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex items-center">
                <span className="mr-2" style={{ width: '200px' }}>
                  Datetime Format:
                </span>
                <select
                  value={datetimeFormat}
                  onChange={(event) => {
                    setDatetimeFormat(event.target.value as AllowedDatetimeFormats)
                  }}
                >
                  {DATETIME_FORMAT_CANDIDATES.map((format) => (
                    <option key={format} value={format}>
                      {format}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}

          <ErrorBoundary>
            <div style={{ height: '400px', marginTop: '20px' }}>
              <Table
                data={getTableData()}
                include={headers}
                sort={false}
                defaultSort={headers[0].id}
                defaultSortAscending={true}
              />
            </div>
          </ErrorBoundary>
          <div className="flex" style={{ marginTop: '20px' }}>
            <Button
              className="ml-auto"
              internalType="danger"
              style={{ width: '150px', marginRight: '10px' }}
              onClick={() => setOpen(false)}
              isLoading={loading}
            >
              Cancel Import
            </Button>
            <Button
              internalType="accept"
              style={{ width: '150px' }}
              onClick={() => handleImportData()}
              isLoading={loading}
              disabled={insertMethod === 'upsert' && !mergingColumn}
            >
              Import Data
            </Button>
          </div>
        </div>
      )}
      <input
        type="file"
        id="file"
        ref={inputRef}
        accept=".csv"
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
    </Modal>
  )
}

export default AppendCSVModal
