import React, { useState, useEffect } from 'react'
import api from 'helpers/api'
import Button from 'components/button'
import { ITableData, Table } from 'components/table'
import useApplicationStore from 'hooks/application'
import Modal from 'components/modal'

const AdminSubscription: React.FC = () => {
  document.title = 'Morta | Subscription Quota Panel'

  const { displayErrorMessage, setSnackbarMessage } = useApplicationStore()
  const [allocateSubscriptionModal, setAllocateSubscriptionModal] = useState<boolean>(false)
  const [newSubscriptionManager, setNewSubscriptionManager] = useState<string>('')
  const [newQuota, setNewQuota] = useState<string>('')

  const [pendingSubscriptionQuotaForManagers, setPendingSubscriptionQuotaForManagers] = useState<
    Array<Record<string, ITableData>>
  >()

  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    setLoading(true)
    api
      .getPendingSubscriptionsAdmin()
      .then((response) => {
        const tableData = response.data.map((entry: { publicId: string; email: string; subscriptionQuota: number }) => {
          return {
            email: { label: entry.email, value: entry.email },
            quota: { label: entry.subscriptionQuota, value: entry.subscriptionQuota },
            delete: {
              label: (
                <Button internalType="danger" onClick={() => handleDeletePendingSubscription(entry)}>
                  Remove
                </Button>
              ),
              value: entry.subscriptionQuota
            }
          }
        })
        setPendingSubscriptionQuotaForManagers(tableData)
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
        displayErrorMessage(e)
      })
  }, [])

  const handleCreatePendingSubscription = () => {
    if (Number.isNaN(newQuota) || parseInt(newQuota) <= 0 || newSubscriptionManager === '') {
      setSnackbarMessage({ message: 'Please enter a valid email and quota', status: 'error' })
      return
    }
    api
      .allocateSubscriptionQuota(newSubscriptionManager, parseInt(newQuota))
      .then(() => {
        window.location.reload()
      })
      .catch((e) => {
        displayErrorMessage(e)
      })
  }
  const handleDeletePendingSubscription = (entry: { publicId: string; email: string; subscriptionQuota: number }) => {
    const response = window.confirm(`Are you sure you want to remove ${entry.email}?`)

    if (response) {
      api
        .deleteSubscriptionQuotaAdmin(entry.publicId)
        .then(() => {
          window.location.reload()
        })
        .catch((e) => {
          displayErrorMessage(e)
        })
    }
  }

  return (
    <div style={{ padding: '20px' }}>
      <div className="flex flex-column" style={{ marginLeft: '20px', maxWidth: '250px' }}>
        <Button internalType="accept" onClick={() => setAllocateSubscriptionModal(true)}>
          Allocate Subscription Quota
        </Button>
      </div>

      {pendingSubscriptionQuotaForManagers && (
        <div className="w-full h-full flex flex-column select-none" style={{ padding: '20px' }}>
          <Table
            data={pendingSubscriptionQuotaForManagers}
            include={[
              { header: 'Email', id: 'email' },
              { header: 'Subscription Quota', id: 'quota' },
              { header: 'Delete', id: 'delete' }
            ]}
            defaultSort={'email'}
            defaultSortAscending={false}
            loading={loading}
          />
        </div>
      )}
      {allocateSubscriptionModal && (
        <Modal
          title="Allocate Subscription Quota"
          id="allocate-subscription_quota"
          open={allocateSubscriptionModal}
          setOpen={setAllocateSubscriptionModal}
        >
          <input
            type="text"
            placeholder="Email Address"
            className="w-full mb-8"
            onChange={(event) => setNewSubscriptionManager(event.target.value)}
          />
          <input
            type="number"
            placeholder="Subscription Quota"
            className="w-full mb-8"
            onChange={(event) => setNewQuota(event.target.value)}
          />

          <div className="flex flex-row items-center w-full">
            <Button
              className="ml-auto"
              internalType="accept"
              disabled={newSubscriptionManager === ''}
              onClick={() => handleCreatePendingSubscription()}
            >
              Allocate License
            </Button>
          </div>
        </Modal>
      )}
    </div>
  )
}

export default AdminSubscription
