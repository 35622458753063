export const formatPercentDisplayValue = (value: number, locale = 'en-GB', decimalPlaces = 0): string => {
  return Intl.NumberFormat(locale, {
    style: 'percent',
    minimumFractionDigits: decimalPlaces < 0 ? 0 : decimalPlaces,
    maximumFractionDigits: decimalPlaces < 0 ? 0 : decimalPlaces
  }).format(value)
}

export const numberWithCommas = (value: number | null): string => {
  if (value !== null) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  } else {
    return ''
  }
}

export const calculatePercentage = (decimal: number): number => {
  return Math.round(decimal * 100)
}

export const formatPercentage = (pct: number | null, decimalPlaces?: number): string => {
  if (pct !== null) {
    return pct.toFixed(decimalPlaces ? decimalPlaces : 0) + '%'
  } else {
    return ''
  }
}

export const neatRound = (number: number): number => {
  return Math.round((number + Number.EPSILON) * 100) / 100
}

export const formatDate = (dateInput: string, format: string): string => {
  const components = dateInput.split('-')
  const year = parseInt(components[0])
  const month = parseInt(components[1])
  const day = parseInt(components[2])

  // Year
  format = format.replace('YYYY', year.toString())
  format = format.replace('YY', year.toString().substr(2, 2))
  format = format.replace('Y', year.toString())

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
  const shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

  // Month of the year
  format = format.replace('MMMM', months[month - 1])
  format = format.replace('MMM', shortMonths[month - 1])
  format = format.replace('MM', month.toString().padStart(2, '0'))

  // Day of the month
  format = format.replace('DDDD', new Date(year, month - 1, day).toLocaleString('default', { weekday: 'long' }))
  format = format.replace('DDD', new Date(year, month - 1, day).toLocaleString('default', { weekday: 'short' }))
  format = format.replace('DD', day.toString().padStart(2, '0'))

  return format
}

export const formatDateTime = (dateTimeInput: string | null, format: string): string => {
  const date = dateTimeInput || dateTimeInput !== null ? new Date(dateTimeInput) : new Date()
  if (!(date instanceof Date)) return ''

  // Year
  format = format.replace('YYYY', date.getFullYear().toString())
  format = format.replace('YY', date.getFullYear().toString().substr(2, 2))
  format = format.replace('Y', date.getFullYear().toString())

  // Month of the year
  format = format.replace('MMMM', date.toLocaleString('default', { month: 'long' }))
  format = format.replace('MMM', date.toLocaleString('default', { month: 'short' }))
  format = format.replace('MM', (date.getMonth() + 1).toString().padStart(2, '0'))

  // Day of the month
  format = format.replace('DDDD', date.toLocaleString('default', { weekday: 'long' }))
  format = format.replace('DDD', date.toLocaleString('default', { weekday: 'short' }))
  format = format.replace('DD', date.getDate().toString().padStart(2, '0'))

  // Hours
  format = format.replace('HH', date.getHours().toString().padStart(2, '0'))
  format = format.replace('hh', (date.getHours() % 12).toString().padStart(2, '0'))

  // Minutes
  format = format.replace('mm', date.getMinutes().toString().padStart(2, '0'))

  // Seconds
  format = format.replace('ss', date.getSeconds().toString().padStart(2, '0'))

  return format
}

export const toUTCString = (localDateTime: string, kind?: string): string => {
  if (!localDateTime) return ''
  // Create a Date object from the input value
  try {
    const localDate = new Date(localDateTime)
    // Convert to UTC
    const utcDateString = localDate.toISOString().slice(0, 16)
    if (kind === 'date') return utcDateString.split('T')[0]

    return utcDateString
  } catch (e) {
    return ''
  }
}
