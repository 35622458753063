import React, { useEffect } from 'react'
import Menu, { MenuProps } from 'components/menu'
import { Expand, Delete, Arrow, Copy, Paste, Plus, Audit, CopyLink } from 'components/icons'
import { ISelectedCellRange } from 'components/spreadsheet/types'
import { findRowAndIndex, canRearrangeRows, createCellId } from 'components/spreadsheet/helpers/functions'
import { useApplicationStore } from 'hooks/application'
import { ICellValue, ITableRow, ITableViewGroup } from 'types'
import { history } from 'helpers/history'
import { useDataContext } from 'components/spreadsheet/contexts/data'
import { INITIAL_CONTEXT_MENU_STATE } from 'app-constants'
import constants from 'style/constants.module.scss'

interface CellMenuProps extends MenuProps {
  onCreateRow: (values: Record<string, ICellValue>, position: number) => void
  onCreateBlankRows: (noRows: number, values?: Record<string, ICellValue>) => void
  uniqueNumber: number
  setExpandModal: (expandModal: boolean, focusInput: boolean, rowId: string, rowNumber: number) => void
  setRowAuditModal: (open: boolean, rowId: string) => void
  setDeleteRowModal: (deleteRowModal: boolean) => void
  selectedCellRange: ISelectedCellRange
}

const CellMenu: React.FC<CellMenuProps> = ({
  id,
  menuState,
  setMenuState,
  width,
  onCreateRow,
  onCreateBlankRows,
  uniqueNumber,
  setExpandModal,
  setRowAuditModal,
  setDeleteRowModal,
  selectedCellRange
}) => {
  const { spreadsheetData } = useDataContext()

  useEffect(() => {
    if (!spreadsheetData.loading && !spreadsheetData.streaming) {
      const currentUrlParams = new URLSearchParams(window.location.search)
      const expandedRowId = currentUrlParams.get('row')
      if (expandedRowId) {
        const foundRowIndex = spreadsheetData.rows.findIndex((row: ITableRow) => row.publicId === expandedRowId)
        if (foundRowIndex !== -1) {
          setExpandModal!(true, false, expandedRowId, foundRowIndex)
        } else {
          const currentUrlParams = new URLSearchParams(window.location.search)
          currentUrlParams.delete('row')
          const newUrl = window.location.pathname + '?' + currentUrlParams.toString()
          history.replace(newUrl)
        }
      }
    }
  }, [spreadsheetData.loading, spreadsheetData.streaming])

  const selectedCellRangeActive = selectedCellRange.endRowIndex !== -1 && selectedCellRange.endColumnIndex !== -1

  const { setSnackbarMessage } = useApplicationStore()

  // If we're in grouped mode, the rowNumber doesn't correspond to the row's position
  // in the spreadsheetData.rows[] array, so we manually search the rows array for
  // the row's position.
  // If we're inserting a new row in a group we also need to know the values for the
  // columns that are being grouped, so when we create the row it's placed in the
  // correct group.
  const getRowIndexAndValues = (useColumnName?: boolean) => {
    if (spreadsheetData.userConfiguration.groupSettings.length > 0 && menuState.rowId !== undefined) {
      const { row, index } = findRowAndIndex(menuState.rowId, spreadsheetData.rows)!
      const values: Record<string, ICellValue> = {}
      for (const group of spreadsheetData.userConfiguration.groupSettings) {
        values[useColumnName ? group.columnName : group.columnId] = row.rowData[group.columnId]
      }

      return { values, index }
    } else {
      return { values: {}, index: menuState.rowNumber }
    }
  }

  const handleInsertRowAbove = (evt: React.MouseEvent<HTMLDivElement>) => {
    evt.preventDefault()
    const { values, index } = getRowIndexAndValues()
    if (index !== undefined) {
      onCreateRow(values, index)
    }
  }

  const handleBulkInsertRows = (evt: React.MouseEvent<HTMLDivElement>, numberRows: number) => {
    evt.preventDefault()
    const groupSettings: ITableViewGroup[] =
      spreadsheetData.userConfiguration.groupSettings.length > 0
        ? spreadsheetData.userConfiguration.groupSettings
        : spreadsheetData.viewDetails.groupSettings
    if (groupSettings.length > 0 && menuState.rowId !== undefined) {
      const { values } = getRowIndexAndValues(true)
      onCreateBlankRows(numberRows, values)
    } else {
      onCreateBlankRows(numberRows)
    }
    setMenuState(INITIAL_CONTEXT_MENU_STATE)
  }

  const groupHasReadOnlyColumns = () => {
    const groupSettings: ITableViewGroup[] =
      spreadsheetData.userConfiguration.groupSettings.length > 0
        ? spreadsheetData.userConfiguration.groupSettings
        : spreadsheetData.viewDetails.groupSettings

    if (groupSettings) return false

    const goupColumnIds = (groupSettings as ITableViewGroup[]).map((group) => group.columnId)

    return spreadsheetData.viewDetails.columns
      .map(
        (column) =>
          goupColumnIds.includes(column.publicId) && (column.isJoined || column.scriptEnabled || column.formulaEnabled)
      )
      .some((readOnly) => readOnly === true)
  }

  const cellId =
    menuState.columnId === undefined
      ? `numbercell/${menuState.rowId}`
      : createCellId(
          menuState.rowId !== undefined ? menuState.rowId : 'undefined',
          menuState.columnId !== undefined ? menuState.columnId : 'undefined',
          uniqueNumber
        )

  const allowInsert = spreadsheetData.isContributor && canRearrangeRows(spreadsheetData)
  const allowBulkInsert = spreadsheetData.isContributor && !groupHasReadOnlyColumns()

  const isGroupingEnabled = spreadsheetData.userConfiguration.groupSettings.length > 0

  return (
    <Menu id={id} menuState={menuState} setMenuState={setMenuState} width={width}>
      <div
        style={{ listStyle: 'none', padding: '0px', margin: '10px' }}
        onMouseDown={(event) => {
          event.preventDefault()
          event.stopPropagation()
        }}
      >
        <div>
          <div
            id={`${id}-copy`}
            className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
            style={{
              padding: '0 10px',
              height: '32px',
              lineHeight: '32px'
            }}
            onClick={() => {
              document.execCommand('copy')
              setMenuState({ ...menuState, open: false })
            }}
            onMouseDown={(event) => event.stopPropagation()}
          >
            <Copy />
            <span className="ml-10px">Copy Value{selectedCellRangeActive && 's'}</span>
          </div>
        </div>
        <div
          style={{ cursor: !allowInsert && !allowBulkInsert ? 'not-allowed' : 'default' }}
          title={!allowInsert && !allowBulkInsert ? 'You need to have contributor permissions to paste values.' : ''}
        >
          <div
            id={`${id}-paste`}
            className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
            style={{
              padding: '0 10px',
              height: '32px',
              lineHeight: '32px',
              pointerEvents: !allowInsert && !allowBulkInsert ? 'none' : 'all'
            }}
            onClick={() => {
              // Fire manually a Clipboard event paste
              const pasteEvent = new ClipboardEvent('paste', {
                bubbles: true,
                cancelable: true
              })

              document.getElementById(`${cellId}`)?.dispatchEvent(pasteEvent)
              setMenuState({ ...menuState, open: false })
            }}
            onMouseDown={(event) => event.stopPropagation()}
          >
            <Paste />
            <span className="ml-10px">Paste Value{selectedCellRangeActive && 's'}</span>
          </div>
        </div>

        {!selectedCellRangeActive && (
          <div>
            <hr style={{ marginTop: '5px', marginBottom: '5px', border: `1px solid ${constants.grey}` }} />

            <div
              className="relative flex items-center rounded text-primary select-none transition-all cursor-pointer truncate hover-bg-light-grey"
              style={{
                padding: '0 10px',
                height: '32px',
                lineHeight: '32px'
              }}
              onClick={() => {
                if (menuState.rowId !== undefined && menuState.rowId !== '' && menuState.rowNumber !== undefined) {
                  const currentUrlParams = new URLSearchParams(window.location.search)
                  currentUrlParams.set('row', menuState.rowId)
                  const newUrl = window.location.pathname + '?' + currentUrlParams.toString()
                  window.history.replaceState({}, '', newUrl)
                  setExpandModal!(true, false, menuState.rowId, menuState.rowNumber)
                }
                setMenuState(INITIAL_CONTEXT_MENU_STATE)
              }}
            >
              <Expand />
              <span className="ml-10px">
                {spreadsheetData.tableDetails.allowComments ? 'Add Comment / Expand Row' : 'Expand Row'}
              </span>
            </div>

            {!selectedCellRangeActive && (
              <div>
                <div
                  className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                  style={{ padding: '0 10px', height: '32px', lineHeight: '32px' }}
                  onClick={() => {
                    const cellId =
                      '{{table/' +
                      spreadsheetData.tableDetails.publicId +
                      '/' +
                      menuState.columnId +
                      '/' +
                      menuState.rowId +
                      '}}'
                    navigator.clipboard.writeText(cellId)
                    setSnackbarMessage({ status: 'success', message: 'Cell ID succesfully copied.' })
                    setMenuState(INITIAL_CONTEXT_MENU_STATE)
                  }}
                >
                  <CopyLink />
                  <span className="ml-10px">Copy Cell ID</span>
                </div>
              </div>
            )}

            <div
              style={{ cursor: spreadsheetData.isAdmin ? 'pointer' : 'not-allowed' }}
              title={!spreadsheetData.isAdmin ? 'You need to have admin permissions to view the row audit trail.' : ''}
            >
              <div
                className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                style={{
                  padding: '0 10px',
                  height: '32px',
                  lineHeight: '32px',
                  pointerEvents: spreadsheetData.isAdmin ? 'all' : 'none'
                }}
                onClick={() => {
                  if (menuState.rowId !== undefined && spreadsheetData.isAdmin) {
                    setRowAuditModal(true, menuState.rowId)
                  }
                }}
              >
                <Audit />
                <span className="ml-10px">View Row Audit Trail</span>
              </div>
            </div>

            <hr style={{ marginTop: '5px', marginBottom: '5px', border: `1px solid ${constants.grey}` }} />
            <div
              style={{ cursor: !allowInsert ? 'not-allowed' : 'default' }}
              title={!allowInsert ? 'Make sure view is saved with no sorts or filters to enable insert.' : ''}
            >
              <div
                className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                style={{
                  padding: '0 10px',
                  height: '32px',
                  lineHeight: '32px',
                  pointerEvents: !allowInsert ? 'none' : 'all'
                }}
                onClick={(event) => {
                  if (!allowInsert) return
                  handleInsertRowAbove(event)
                }}
              >
                <Arrow type="up" />
                <span className="ml-10px">Insert Row (Above)</span>
              </div>
            </div>

            {[10, 100].map((value: number) => {
              return (
                <div
                  key={value}
                  style={{ cursor: !allowBulkInsert ? 'not-allowed' : 'default' }}
                  title={!allowBulkInsert ? 'Adding multiple rows is disabled' : ''}
                >
                  <div
                    className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                    style={{
                      padding: '0 10px',
                      height: '32px',
                      lineHeight: '32px',
                      pointerEvents: !allowBulkInsert ? 'none' : 'all'
                    }}
                    onClick={(event) => {
                      if (!allowBulkInsert) return
                      handleBulkInsertRows(event, value)
                    }}
                  >
                    <Plus />
                    <span className="ml-10px">Add {value} Rows To End</span>
                  </div>
                </div>
              )
            })}
          </div>
        )}

        {(spreadsheetData.isAdmin ||
          (spreadsheetData.isContributor && spreadsheetData.viewDetails.allowContributorDelete)) && (
          <div>
            <hr style={{ marginTop: '5px', marginBottom: '5px', border: `1px solid ${constants.grey}` }} />

            <div
              className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
              style={{
                padding: '0 10px',
                height: '32px',
                lineHeight: '32px'
              }}
              onMouseDown={(event) => event.stopPropagation()}
              onClick={() => {
                if (!selectedCellRangeActive) {
                  setDeleteRowModal(true)
                } else {
                  if (isGroupingEnabled) {
                    setSnackbarMessage({
                      status: 'error',
                      message: 'Cannot delete multiple rows in grouped mode.'
                    })
                  } else {
                    setDeleteRowModal(true)
                  }
                }
              }}
            >
              <Delete />
              <span className="ml-10px" style={{ color: constants.red }}>
                Delete Row{selectedCellRangeActive ? 's' : ''}
              </span>
            </div>
          </div>
        )}
      </div>
    </Menu>
  )
}

export default React.memo(CellMenu)
