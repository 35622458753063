import React from 'react'
import { Link } from 'react-router-dom'
import Menu, { MenuProps } from 'components/menu'
import { useAuth } from 'hooks/auth'
import { ADMIN_HOME, INTEGRATIONS, SECURITY, SIGN_IN, SUBSCRIPTION, ADMIN_SUBSCRIPTIONS } from 'routes'
import { INITIAL_CONTEXT_MENU_STATE, PROJECT } from 'app-constants'
import { AdminPanel, Integrations, Profile, Projects, Security, Subscription, Template } from 'components/icons'
import { history } from 'helpers/history'
import { capitaliseFirstLetter } from 'helpers/utils'
import useApplicationStore from 'hooks/application'
interface UserMenuProps extends MenuProps {
  setTemplatesModal: (value: boolean) => void
}

const UserMenu: React.FC<UserMenuProps> = ({ setTemplatesModal, id, menuState, setMenuState, width }) => {
  const { user, logout } = useAuth()
  const { displayErrorMessage } = useApplicationStore()

  const signOut = async () => {
    setMenuState(INITIAL_CONTEXT_MENU_STATE)
    try {
      await logout()
      history.push(SIGN_IN)
    } catch (error) {
      displayErrorMessage(error)
    }
  }

  return (
    <Menu id={id} menuState={menuState} setMenuState={setMenuState} width={width}>
      <Link
        className="w-full flex items-center hover-bg-light-grey text-primary transition-all"
        style={{ padding: '10px' }}
        data-cy="home-button"
        to={`/home`}
        onClick={() => setMenuState(INITIAL_CONTEXT_MENU_STATE)}
      >
        <Projects style={{ marginRight: '5px' }} />
        {capitaliseFirstLetter(PROJECT)}s
      </Link>
      <div
        className="w-full flex items-center hover-bg-light-grey text-primary cursor-pointer transition-all"
        style={{ padding: '10px', marginBottom: '5px' }}
        data-cy="templates-button"
        onClick={() => {
          setMenuState(INITIAL_CONTEXT_MENU_STATE)
          setTemplatesModal(true)
        }}
      >
        <Template style={{ marginRight: '5px' }} />
        Templates
      </div>

      <Link
        className="w-full flex items-center hover-bg-light-grey text-primary transition-all"
        style={{ padding: '10px' }}
        data-cy="profile-button"
        to={`/profile/${user?.firebaseUserId}/information`}
        onClick={() => setMenuState(INITIAL_CONTEXT_MENU_STATE)}
      >
        <Profile style={{ marginRight: '5px' }} />
        Profile
      </Link>

      <Link
        className={`w-full flex items-center ${
          user && user.isOnFreeTrial ? 'pulsate-border bg-alert bold' : 'hover-bg-light-grey'
        } text-primary transition-all`}
        style={{ padding: '10px' }}
        data-cy="subscription-button"
        to={SUBSCRIPTION}
        onClick={() => setMenuState(INITIAL_CONTEXT_MENU_STATE)}
      >
        <Subscription style={{ marginRight: '5px' }} />
        {user && user.isOnFreeTrial ? 'Upgrade Subscription' : 'Subscription'}
      </Link>

      <Link
        className="w-full flex items-center hover-bg-light-grey text-primary transition-all"
        style={{ padding: '10px' }}
        data-cy="profile-button"
        to={INTEGRATIONS}
        onClick={() => setMenuState(INITIAL_CONTEXT_MENU_STATE)}
      >
        <Integrations style={{ marginRight: '5px' }} />
        Integrations
      </Link>

      <Link
        className="w-full flex items-center hover-bg-light-grey text-primary transition-all"
        style={{ padding: '10px' }}
        data-cy="profile-button"
        to={SECURITY}
        onClick={() => setMenuState(INITIAL_CONTEXT_MENU_STATE)}
      >
        <Security style={{ marginRight: '5px' }} />
        Account Security
      </Link>

      <hr style={{ marginTop: '5px', marginBottom: '5px' }} />

      {user?.isSuperAdmin && (
        <div>
          <Link
            className="w-full flex items-center hover-bg-light-grey text-primary transition-all"
            style={{ padding: '10px' }}
            to={ADMIN_HOME}
            onClick={() => setMenuState(INITIAL_CONTEXT_MENU_STATE)}
          >
            <AdminPanel style={{ marginRight: '5px' }} />
            Admin Panel
          </Link>

          <Link
            className="w-full flex items-center hover-bg-light-grey text-primary transition-all"
            style={{ padding: '10px' }}
            to={ADMIN_SUBSCRIPTIONS}
            onClick={() => setMenuState(INITIAL_CONTEXT_MENU_STATE)}
          >
            <Subscription style={{ marginRight: '5px' }} />
            Subscription Quotas
          </Link>
          <hr style={{ marginTop: '5px', marginBottom: '5px' }} />
        </div>
      )}

      <div
        className="w-full flex items-center hover-bg-light-grey text-primary cursor-pointer transition-all"
        style={{ padding: '10px', marginBottom: '5px' }}
        data-cy="signout-button"
        onClick={() => signOut()}
      >
        Sign Out
      </div>
    </Menu>
  )
}

export default UserMenu
