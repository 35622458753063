import React from 'react'
import DocumentComponent from 'components/document'
import ErrorBoundary from 'components/error'
import { useParams } from 'react-router'

const Document: React.FC = () => {
  const params: { projectId?: string; processId?: string } = useParams()

  return (
    <ErrorBoundary>
      {params && params.processId && <DocumentComponent processPublicId={params.processId} />}
    </ErrorBoundary>
  )
}

export default Document
