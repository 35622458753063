import React, { useEffect, useState } from 'react'
import Modal, { ModalProps } from 'components/modal'
import { deleteTableRowAction } from 'components/spreadsheet/contexts/data/actions'
import { useApplicationStore } from 'hooks/application'
import { useProject } from 'hooks/project'
import Button from 'components/button'
import { useDataContext } from 'components/spreadsheet/contexts/data'
import { INITIAL_SELECTED_CELL, INITIAL_SELECTED_CELL_RANGE } from 'components/spreadsheet/constants/const'

interface DeleteRowModalProps extends ModalProps {
  initialRowId: string | undefined
}

const DeleteRowModal: React.FC<DeleteRowModalProps> = ({ id, open, setOpen, initialRowId }) => {
  const [processing, setProcessing] = useState<boolean>(false)
  const [rowId, setRowId] = useState<string>()

  useEffect(() => {
    setRowId(initialRowId)
  }, [])

  const { setSnackbarMessage } = useApplicationStore()
  const { project } = useProject()
  const { spreadsheetData, setSpreadsheetData, selectedCell, selectedCellRange } = useDataContext()

  const selectedCellRangeActive = selectedCellRange.endRowIndex !== -1 && selectedCellRange.endColumnIndex !== -1

  const getSelectedRange = () => {
    if (selectedCell !== INITIAL_SELECTED_CELL && selectedCellRange === INITIAL_SELECTED_CELL_RANGE) {
      const cellRange = { ...INITIAL_SELECTED_CELL_RANGE }
      cellRange['endRowIndex'] = selectedCell.rowNumber
      cellRange['endColumnIndex'] = selectedCell.columnNumber
      return cellRange
    } else {
      return selectedCellRange
    }
  }

  const onSuccess = () => {
    setOpen(false)
    setProcessing(false)
    setSnackbarMessage({
      status: 'success',
      message: 'Row successfully deleted.'
    })
  }

  const onFailure = () => {
    setSnackbarMessage({
      status: 'error',
      message: 'Something went wrong deleting the row. Please refresh and try again.'
    })
  }

  const handleDeleteRow = () => {
    if (rowId) {
      setProcessing(true)
      deleteTableRowAction([rowId], project.publicId, spreadsheetData, setSpreadsheetData, onSuccess, onFailure)
    }
  }

  const handleDeleteRows = () => {
    if (selectedCell.rowNumber === undefined || selectedCell.columnNumber === undefined) {
      return
    }

    setProcessing(true)
    const rowIds: string[] = []

    const rows = spreadsheetData.rows
    const cellRange = getSelectedRange()
    const startRowIndex = Math.min(selectedCell.rowNumber, cellRange.endRowIndex)
    const endRowIndex = Math.max(selectedCell.rowNumber, cellRange.endRowIndex)

    if (startRowIndex >= 0 && endRowIndex >= 0) {
      for (let i = startRowIndex; i < endRowIndex + 1; i++) {
        const row = rows[i]
        if (row) {
          rowIds.push(row.publicId)
        }
      }
      deleteTableRowAction(rowIds, project.publicId, spreadsheetData, setSpreadsheetData, onSuccess, onFailure)
    }
  }

  return (
    <Modal id={id} open={open} setOpen={setOpen} title="Confirm Deletion">
      {`Are you sure you want to delete the row${selectedCellRangeActive ? 's' : ''}?`}

      <div className="flex justify-end" style={{ marginTop: '30px' }}>
        <Button
          internalType="secondary"
          style={{ marginRight: '15px' }}
          onClick={() => {
            if (!processing) setOpen(false)
          }}
        >
          Keep Row{`${selectedCellRangeActive ? 's' : ''}`}
        </Button>
        <Button
          internalType="danger"
          onClick={() => {
            if (!processing) {
              if (selectedCellRangeActive) {
                handleDeleteRows()
              } else {
                handleDeleteRow()
              }
            }
          }}
          isLoading={processing}
          disabled={processing}
        >
          Delete Row{`${selectedCellRangeActive ? 's' : ''}`}
        </Button>
      </div>
    </Modal>
  )
}

export default DeleteRowModal
