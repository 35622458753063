import React, { useState } from 'react'
import Modal, { ModalProps } from 'components/modal'
import { useDataContext } from 'components/spreadsheet/contexts/data'
import useApplicationStore from 'hooks/application'
import Button from 'components/button'
import { Csv } from 'components/icons'
import api from 'helpers/api'
import Subscription from 'components/subscription'
import useAuth from 'hooks/auth'

const BackupModal: React.FC<ModalProps> = ({ id, open, setOpen }) => {
  const { spreadsheetData } = useDataContext()
  const { displayErrorMessage } = useApplicationStore()
  const { user } = useAuth()

  const [selectedDate, setSelectedDate] = useState<string>('')
  const [downloadingCsv, setDownloadingCsv] = useState<boolean>(false)

  const downloadCsv = () => {
    setDownloadingCsv(true)

    api
      .generateCsvBackup(
        spreadsheetData.tableDetails.publicId,
        selectedDate,
        `${spreadsheetData.tableDetails.name}-${selectedDate}.csv`
      )
      .then(() => {
        setDownloadingCsv(false)
        setOpen(false)
      })
      .catch((error) => {
        setDownloadingCsv(false)
        displayErrorMessage(error)
      })
  }

  const allowedDays = user && user.onScalePlan ? 90 : 30

  return (
    <Modal id={id} open={open} setOpen={setOpen} title="Table Backups">
      <div className="flex flex-column">
        <Subscription type="scale" message="access backups older than 30 days" />
        <div className="mb-8">
          Select a date and download a CSV file of the table at that point in time. This will include all user changes
          to rows and columns but will not include changes to formulas, joins or scripts. To restore a backup, delete
          your row data and then import the CSV file using the import button.
        </div>
        <input
          className="mb-8"
          type="datetime-local"
          min={
            new Date(new Date().getTime() - allowedDays * 24 * 60 * 60 * 1000).toISOString().split('T')[0] + 'T00:00'
          }
          onChange={(event) => setSelectedDate(event.target.value)}
        />
        <Button
          className="ml-auto"
          internalType="accept"
          isLoading={downloadingCsv}
          onClick={() => downloadCsv()}
          disabled={selectedDate === ''}
        >
          <Csv />
          <span style={{ marginLeft: '7px' }}>Download CSV</span>
        </Button>
      </div>
    </Modal>
  )
}

export default BackupModal
