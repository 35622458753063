import React, { useState } from 'react'
import {
  Dots,
  Visible,
  Filter,
  Colour,
  Sort,
  Group,
  Lock,
  Asterisk,
  Expand,
  Save,
  Search,
  Freeze,
  Cross,
  SmallRowHeight,
  MediumRowHeight,
  TallRowHeight,
  ExtraTallRowHeight,
  Plus,
  Chart,
  MoveColumn
} from 'components/icons'
import { rowHeights, IViewPortColumn } from 'components/spreadsheet/types'
import { iconHeight } from 'components/icons'
import ViewMenu from 'components/spreadsheet/components/menu/views/view'
import FilterMenu from 'components/spreadsheet/components/menu/views/filter'
import ColourMenu from 'components/spreadsheet/components/menu/views/colour'
import SortMenu from 'components/spreadsheet/components/menu/views/sort'
import GroupMenu from 'components/spreadsheet/components/menu/views/group'
import ChartMenu from 'components/spreadsheet/components/menu/views/chart'
import FreezeMenu from 'components/spreadsheet/components/menu/views/freeze'
import ColumnOrderMenu from 'components/spreadsheet/components/menu/views/order'
import RowHeightMenu from 'components/spreadsheet/components/menu/views/rowheight'
import SaveMenu from 'components/spreadsheet/components/menu/views/save'
import SearchMenu from 'components/spreadsheet/components/menu/views/search'
import SettingsMenu from 'components/spreadsheet/components/menu/views/settings'
import ImportMenu from 'components/spreadsheet/components/menu/views/import'
import AuditModal from 'components/spreadsheet/components/modal/views/audit'
import PermissionsModal from 'components/spreadsheet/components/modal/views/permissions'
import Duplicate from 'components/duplicate'
import { CommentDisplayTypes, ValidationDisplayTypes, ViewTypes } from 'components/spreadsheet/constants/const'
import { INITIAL_CONTEXT_MENU_STATE, IntegrationProviders } from 'app-constants'
import { IContextMenuState, ITableViewColumn, MortaSyncTypes } from 'types'
import { useDataContext } from 'components/spreadsheet/contexts/data'
import SelectMenu from 'components/select/components/menu'
import { getColumnsPerUserRole } from 'components/spreadsheet/helpers/functions'
import useApplicationStore from 'hooks/application'
import constants from 'style/constants.module.scss'
import { deleteTable, deleteView } from 'components/spreadsheet/contexts/data/actions'
import { history } from 'helpers/history'
import { useProject } from 'hooks/project'
import DeleteResource from 'components/delete'
import TableJoinsModal from 'components/spreadsheet/components/modal/views/tablejoins'
import AppendCSVModal from 'components/spreadsheet/components/modal/views/appendcsv'
import IntegrationModal from 'components/spreadsheet/components/modal/views/integration'
import Related from 'components/related'
import { Link } from 'react-router-dom'
import DuplicateChildren from 'components/duplicateChildren'
import { PERMISSIONS } from 'helpers/auth'
import { HUB_BASE } from 'routes'
import BackupModal from '../modal/views/backups'

interface ToolbarProps {
  uniqueNumber: number
  fullScreen: boolean
  setFullScreen: (fullScreen: boolean) => void
  handleToggleCollapseAll: () => void
  columns: IViewPortColumn[]
}

const Toolbar: React.FC<ToolbarProps> = ({
  uniqueNumber,
  fullScreen,
  setFullScreen,
  handleToggleCollapseAll,
  columns
}) => {
  const { spreadsheetData, setSpreadsheetData, filterMenu, setFilterMenu, colourMenu, setColourMenu } = useDataContext()
  const { project } = useProject()
  const { sidebarWidth } = useApplicationStore()

  const [viewMenu, setViewMenu] = useState<IContextMenuState>(INITIAL_CONTEXT_MENU_STATE)
  const [sortMenu, setSortMenu] = useState<IContextMenuState>(INITIAL_CONTEXT_MENU_STATE)
  const [groupMenu, setGroupMenu] = useState<IContextMenuState>(INITIAL_CONTEXT_MENU_STATE)
  const [hideMenu, setHideMenu] = useState<IContextMenuState>(INITIAL_CONTEXT_MENU_STATE)
  const [lockMenu, setLockMenu] = useState<IContextMenuState>(INITIAL_CONTEXT_MENU_STATE)
  const [requiredMenu, setRequiredMenu] = useState<IContextMenuState>(INITIAL_CONTEXT_MENU_STATE)
  const [columnOrderMenu, setColumnOrderMenu] = useState<IContextMenuState>(INITIAL_CONTEXT_MENU_STATE)
  const [freezeMenu, setFreezeMenu] = useState<IContextMenuState>(INITIAL_CONTEXT_MENU_STATE)
  const [rowHeightMenu, setRowHeightMenu] = useState<IContextMenuState>(INITIAL_CONTEXT_MENU_STATE)
  const [saveMenu, setSaveMenu] = useState<IContextMenuState>(INITIAL_CONTEXT_MENU_STATE)
  const [searchMenu, setSearchMenu] = useState<IContextMenuState>(INITIAL_CONTEXT_MENU_STATE)
  const [importMenu, setImportMenu] = useState<IContextMenuState>(INITIAL_CONTEXT_MENU_STATE)
  const [settingsMenu, setSettingsMenu] = useState<IContextMenuState>(INITIAL_CONTEXT_MENU_STATE)
  const [chartMenu, setChartMenu] = useState<IContextMenuState>(INITIAL_CONTEXT_MENU_STATE)

  const [auditModal, setAuditModal] = useState<boolean>(false)
  const [deleteTableModal, setDeleteTableModal] = useState<boolean>(false)
  const [joinsModal, setJoinsModal] = useState<boolean>(false)
  const [relatedModal, setRelatedModal] = useState<boolean>(false)
  const [viewDuplicatesModal, setViewDuplicatesModal] = useState<boolean>(false)
  const [backupModal, setBackupModal] = useState<boolean>(false)
  const [appendCSVModal, setAppendCSVModal] = useState<boolean>(false)
  const [viewpointModal, setViewpointModal] = useState<boolean>(false)
  const [viewpointRfisModal, setViewpointRfisModal] = useState<boolean>(false)
  const [bim360Modal, setBIM360Modal] = useState<boolean>(false)
  const [bim360ChecklistsModal, setBIM360ChecklistsModal] = useState<boolean>(false)
  const [bim360IssuesModal, setBIM360IssuesModal] = useState<boolean>(false)
  const [bim360ModelsModal, setBIM360ModelsModal] = useState<boolean>(false)
  const [bim360UsersModal, setBIM360UsersModal] = useState<boolean>(false)
  const [procoreModal, setProcoreModal] = useState<boolean>(false)
  const [aconexModal, setAconexModal] = useState<boolean>(false)
  const [aconexWorkflowsModal, setAconexWorkflowsModal] = useState<boolean>(false)
  const [mortaModal, setMortaModal] = useState<boolean>(false)
  const [reviztoModal, setReviztoModal] = useState<boolean>(false)
  const [asiteDocumentsModal, setAsiteDocumentsModal] = useState<boolean>(false)
  const [asiteFormsModal, setAsiteFormsModal] = useState<boolean>(false)
  const [mortaSyncType, setMortaSyncType] = useState<MortaSyncTypes>()
  const [duplicateTableModal, setDuplicateTableModal] = useState<boolean>(false)
  const [duplicateViewModal, setDuplicateViewModal] = useState<boolean>(false)
  const [deleteViewModal, setDeleteViewModal] = useState<boolean>(false)
  const [permissionsModal, setPermissionsModal] = useState<boolean>(false)

  const noOfColours = spreadsheetData.userConfiguration.colourSettings.length
  const noOfSorts = spreadsheetData.userConfiguration.sortSettings.length
  const noOfFilters = spreadsheetData.userConfiguration.filterSettings.length
  const noOfHidden = spreadsheetData.userConfiguration.hiddenColumns.length
  const noOfLocked = spreadsheetData.viewDetails.columns
    .filter((column: ITableViewColumn) => !spreadsheetData.userConfiguration.hiddenColumns.includes(column.publicId))
    .filter((column: ITableViewColumn) => column.locked || column.isJoined).length
  const noOfRequired = spreadsheetData.viewDetails.columns.filter(
    (column: ITableViewColumn) =>
      column.required && !spreadsheetData.userConfiguration.hiddenColumns.includes(column.publicId)
  ).length
  const noOfGroups = spreadsheetData.userConfiguration.groupSettings.length
  const chartSettings = spreadsheetData.userConfiguration.chartSettings
  const isStreaming = spreadsheetData.streaming

  const viewWidth = 400
  const filterWidth = 900
  const colourWidth = 1000
  const sortWidth = 600
  const groupWidth = 600
  const hideWidth = 350
  const lockWidth = 350
  const requiredWidth = 350
  const columnOrderWidth = 400
  const freezeWidth = 250
  const rowHeightWidth = 200
  const saveWidth = 200
  const searchWidth = 400
  const importWidth = 400
  const settingsWidth = 400
  const chartWidth = 350

  const offset = 80

  const { displayErrorMessage, setSnackbarMessage } = useApplicationStore()

  const handleDeleteTableSuccess = () => {
    window.location.reload()
  }

  const openMortaModal = (open: boolean, type: MortaSyncTypes) => {
    setMortaModal(open)
    setMortaSyncType(type)
  }

  const handleDeleteTableFailure = (error: any) => {
    displayErrorMessage(error)
  }

  const handleDeleteTable = () => {
    deleteTable(project.publicId, spreadsheetData, handleDeleteTableSuccess, handleDeleteTableFailure)
  }

  const handleDeleteViewSuccess = () => {
    setDeleteViewModal(false)
    setSnackbarMessage({ status: 'success', message: 'The view was deleted successfully!' })
    const tableId = spreadsheetData.tableDetails.publicId
    const defaultViewId = spreadsheetData.tableDetails.defaultViewId
    const redirectPath = `${HUB_BASE}${project.publicId}/table/${tableId}/view/${defaultViewId}`
    history.push(redirectPath)
  }

  const handleDeleteViewFailure = (error: any) => {
    displayErrorMessage(error)
  }

  const handleDeleteView = () => {
    deleteView(
      setSpreadsheetData,
      project.publicId,
      spreadsheetData.viewDetails.publicId,
      handleDeleteViewSuccess,
      handleDeleteViewFailure
    )
  }

  const showColumn = (columnId: string) => {
    setSpreadsheetData({ type: 'SHOW_COLUMN', columnId })
  }

  const hideColumn = (columnId: string) => {
    const column = columns.find((column: IViewPortColumn) => column.column.publicId === columnId)
    if (column) {
      if (column.isGrouped) {
        setSnackbarMessage({ status: 'error', message: 'You cannot hide a grouped column from the view.' })
      } else {
        setSpreadsheetData({ type: 'HIDE_COLUMN', columnId })
      }
    }
  }

  const unlockColumn = (columnId: string) => {
    setSpreadsheetData({ type: 'UNLOCK_COLUMN', columnId })
  }

  const lockColumn = (columnId: string) => {
    setSpreadsheetData({ type: 'LOCK_COLUMN', columnId })
  }

  const unrequireColumn = (columnId: string) => {
    setSpreadsheetData({ type: 'UNREQUIRE_COLUMN', columnId })
  }

  const requireColumn = (columnId: string) => {
    const column = spreadsheetData.viewDetails.columns.find((column) => column.publicId === columnId)
    if (column) {
      const isBlocked = column.isJoined || column.scriptEnabled || column.formulaEnabled
      if (isBlocked) {
        setSnackbarMessage({
          status: 'error',
          message: `Field ${column.name} cannot be set as required. It is a value calculated by the system.`
        })
      } else {
        setSpreadsheetData({ type: 'REQUIRE_COLUMN', columnId: column.publicId })
      }
    }
  }

  const allColumns = getColumnsPerUserRole(spreadsheetData)
  const visibleColumns = spreadsheetData.viewDetails.columns.filter(
    (column) => !spreadsheetData.userConfiguration.hiddenColumns.includes(column.publicId)
  )

  if (!spreadsheetData.loading) {
    return (
      <div
        className="flex w-full items-center box-border select-none border-b-1px border-solid border-grey overflow-x-auto overflow-y-hidden"
        style={{
          padding: sidebarWidth === 0 && spreadsheetData.processId === undefined ? '5px 5px 5px 50px' : '5px',
          height: `${Number.parseInt(constants.toolbarHeight)}px`
        }}
      >
        <div data-testid="table-dropdown-menu" className="toolbar-element">
          <span>Table: {spreadsheetData.tableDetails.name}</span>
        </div>

        <div className="border-r-2px border-solid border-grey" style={{ height: '80%' }} />
        <div
          data-testid="view-dropdown-menu"
          className={spreadsheetData.isAdmin ? 'toolbar-button' : 'toolbar-element'}
          style={{
            cursor: spreadsheetData.isAdmin ? 'pointer' : 'default',
            marginRight: 'auto'
          }}
          onClick={(event: React.MouseEvent) =>
            spreadsheetData.isAdmin
              ? setViewMenu({
                  open: true,
                  top: `${event.currentTarget.getBoundingClientRect().bottom + 10}px`,
                  left: `${event.currentTarget.getBoundingClientRect().left}px`,
                  right: 'auto',
                  bottom: 'auto'
                })
              : null
          }
        >
          {spreadsheetData.processId && spreadsheetData.tableDetails.permissionLevel === PERMISSIONS.owner ? (
            <Link
              to={`${HUB_BASE}${project.publicId}/table/${spreadsheetData.tableDetails.publicId}/view/${spreadsheetData.viewDetails.publicId}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ fontWeight: 600 }}
            >
              View: {spreadsheetData.viewDetails.name}
            </Link>
          ) : (
            `View: ${spreadsheetData.viewDetails.name}`
          )}
        </div>
        <div className="flex">
          {!spreadsheetData.tableDetails.isDeleted && spreadsheetData.viewDetails.type === ViewTypes.CHART && (
            <div
              className={`
            ${
              isStreaming
                ? 'toolbar-button-disabled'
                : chartSettings === null
                ? 'toolbar-button'
                : 'toolbar-button-active'
            }`}
              onClick={(event: React.MouseEvent) => {
                if (isStreaming) {
                  return
                }
                setChartMenu({
                  open: true,
                  top: `${event.currentTarget.getBoundingClientRect().bottom + 10}px`,
                  left: `${event.currentTarget.getBoundingClientRect().left - chartWidth + offset}px`,
                  right: 'auto',
                  bottom: 'auto'
                })
              }}
            >
              <Chart />
              <div className="toolbar-button-text">Chart</div>
            </div>
          )}
          {!spreadsheetData.tableDetails.isDeleted &&
            (spreadsheetData.viewDetails.type === ViewTypes.SPREADSHEET ||
              spreadsheetData.viewDetails.type === ViewTypes.CHART) && (
              <div
                id="colour-toolbar-button"
                className={`
                ${
                  isStreaming ? 'toolbar-button-disabled' : noOfColours > 0 ? `toolbar-button-active` : 'toolbar-button'
                }`}
                onClick={(event: React.MouseEvent) => {
                  if (isStreaming) {
                    return
                  }
                  setColourMenu({
                    open: true,
                    top: `${event.currentTarget.getBoundingClientRect().bottom + 10}px`,
                    left: `${event.currentTarget.getBoundingClientRect().left - colourWidth + offset}px`,
                    right: 'auto',
                    bottom: 'auto'
                  })
                }}
              >
                <Colour />
                <div className="toolbar-button-text">Colour</div>
              </div>
            )}

          {!spreadsheetData.tableDetails.isDeleted && spreadsheetData.viewDetails.type !== ViewTypes.FORM && (
            <div
              id="filter-toolbar-button"
              className={`
            ${
              isStreaming
                ? 'toolbar-button-disabled'
                : noOfFilters > 0 ||
                  spreadsheetData.viewDetails.displayValidationErrorRows !== ValidationDisplayTypes.SHOW_VALIDATION ||
                  spreadsheetData.viewDetails.displayCommentRows !== CommentDisplayTypes.SHOW_ALL
                ? `toolbar-button-active`
                : 'toolbar-button'
            }`}
              onClick={(event: React.MouseEvent) => {
                if (isStreaming) {
                  return
                }
                setFilterMenu({
                  open: true,
                  top: `${event.currentTarget.getBoundingClientRect().bottom + 10}px`,
                  left: `${event.currentTarget.getBoundingClientRect().left - filterWidth + offset}px`,
                  right: 'auto',
                  bottom: 'auto'
                })
              }}
            >
              <Filter />
              <div className="toolbar-button-text">Filter</div>
            </div>
          )}

          {!spreadsheetData.tableDetails.isDeleted && spreadsheetData.viewDetails.type !== ViewTypes.FORM && (
            <div
              className={`
            ${isStreaming ? 'toolbar-button-disabled' : noOfSorts > 0 ? `toolbar-button-active` : 'toolbar-button'}`}
              onClick={(event: React.MouseEvent) => {
                if (isStreaming) {
                  return
                }
                setSortMenu({
                  open: true,
                  top: `${event.currentTarget.getBoundingClientRect().bottom + 10}px`,
                  left: `${event.currentTarget.getBoundingClientRect().left - sortWidth + offset}px`,
                  right: 'auto',
                  bottom: 'auto'
                })
              }}
            >
              <Sort />
              <div className="toolbar-button-text">Sort</div>
            </div>
          )}

          {!spreadsheetData.tableDetails.isDeleted && spreadsheetData.viewDetails.type === ViewTypes.SPREADSHEET && (
            <div
              className={`
            ${isStreaming ? 'toolbar-button-disabled' : noOfGroups > 0 ? `toolbar-button-active` : 'toolbar-button'}`}
              onClick={(event: React.MouseEvent) => {
                if (isStreaming) {
                  return
                }
                setGroupMenu({
                  open: true,
                  top: `${event.currentTarget.getBoundingClientRect().bottom + 10}px`,
                  left: `${event.currentTarget.getBoundingClientRect().left - groupWidth + offset}px`,
                  right: 'auto',
                  bottom: 'auto'
                })
              }}
            >
              <Group />
              <div className="toolbar-button-text">Group</div>
            </div>
          )}

          {!spreadsheetData.tableDetails.isDeleted &&
            spreadsheetData.viewDetails.type !== ViewTypes.CHART &&
            (spreadsheetData.viewDetails.type !== ViewTypes.FORM ||
              (spreadsheetData.viewDetails.type === ViewTypes.FORM && spreadsheetData.isAdmin)) && (
              <div
                className={`
              ${noOfHidden > 0 ? `toolbar-button-active` : 'toolbar-button'}`}
                onClick={(event: React.MouseEvent) =>
                  setHideMenu({
                    open: true,
                    top: `${event.currentTarget.getBoundingClientRect().bottom + 10}px`,
                    left: `${event.currentTarget.getBoundingClientRect().left - hideWidth + offset}px`,
                    right: 'auto',
                    bottom: 'auto'
                  })
                }
                style={{ marginLeft: spreadsheetData.viewDetails.type === ViewTypes.FORM ? 'auto' : '' }}
              >
                <Visible />
                <div className="toolbar-button-text">Hide</div>
              </div>
            )}

          {spreadsheetData.isAdmin && spreadsheetData.viewDetails.type !== ViewTypes.CHART && (
            <div
              data-testid="toolbar-button-lock"
              className={`
            ${noOfLocked > 0 ? `toolbar-button-active` : 'toolbar-button'}`}
              onClick={(event: React.MouseEvent) =>
                setLockMenu({
                  open: true,
                  top: `${event.currentTarget.getBoundingClientRect().bottom + 10}px`,
                  left: `${event.currentTarget.getBoundingClientRect().left - lockWidth + offset}px`,
                  right: 'auto',
                  bottom: 'auto'
                })
              }
            >
              <Lock />
              <div className="toolbar-button-text">Lock</div>
            </div>
          )}

          {spreadsheetData.isContributor && spreadsheetData.viewDetails.type === ViewTypes.SPREADSHEET && (
            <div
              className={`
            ${
              spreadsheetData.tableDetails.isSynced && spreadsheetData.tableDetails.failedSyncAttempts > 0
                ? 'toolbar-button-error'
                : spreadsheetData.tableDetails.isSynced
                ? `toolbar-button-active`
                : 'toolbar-button'
            }`}
              title="Sync Data"
              onClick={(event: React.MouseEvent) =>
                setImportMenu({
                  open: true,
                  top: `${event.currentTarget.getBoundingClientRect().bottom + 10}px`,
                  left: `${event.currentTarget.getBoundingClientRect().left - importWidth + 110}px`,
                  right: 'auto',
                  bottom: 'auto'
                })
              }
            >
              <Plus />
              <div className="toolbar-button-text">Sync Data</div>
            </div>
          )}

          {spreadsheetData.isAdmin && spreadsheetData.viewDetails.type === ViewTypes.FORM && (
            <div
              data-testid="required-menu"
              className={`
            ${noOfRequired > 0 ? `toolbar-button-active` : 'toolbar-button'}`}
              onClick={(event: React.MouseEvent) =>
                setRequiredMenu({
                  open: true,
                  top: `${event.currentTarget.getBoundingClientRect().bottom + 10}px`,
                  left: `${event.currentTarget.getBoundingClientRect().left - requiredWidth + offset}px`,
                  right: 'auto',
                  bottom: 'auto'
                })
              }
            >
              <Asterisk />
              <div className="toolbar-button-text">Required</div>
            </div>
          )}

          {!spreadsheetData.tableDetails.isDeleted && spreadsheetData.viewDetails.type === ViewTypes.SPREADSHEET && (
            <div
              className="toolbar-button"
              title="Change Column Order"
              onClick={(event: React.MouseEvent) =>
                setColumnOrderMenu({
                  open: true,
                  top: `${event.currentTarget.getBoundingClientRect().bottom + 10}px`,
                  left: `${event.currentTarget.getBoundingClientRect().left - columnOrderWidth + 20}px`,
                  right: 'auto',
                  bottom: 'auto'
                })
              }
            >
              <MoveColumn />
            </div>
          )}

          {!spreadsheetData.tableDetails.isDeleted && spreadsheetData.viewDetails.type === ViewTypes.SPREADSHEET && (
            <div
              title="Freeze Columns"
              className={`
            ${spreadsheetData.viewDetails.frozenIndex > 0 ? `toolbar-button-active` : 'toolbar-button'}`}
              onClick={(event: React.MouseEvent) =>
                setFreezeMenu({
                  open: true,
                  top: `${event.currentTarget.getBoundingClientRect().bottom + 10}px`,
                  left: `${event.currentTarget.getBoundingClientRect().left - freezeWidth + 20}px`,
                  right: 'auto',
                  bottom: 'auto'
                })
              }
            >
              <Freeze />
            </div>
          )}

          {!spreadsheetData.tableDetails.isDeleted && spreadsheetData.viewDetails.type !== ViewTypes.CHART && (
            <div
              className={'toolbar-button'}
              title="Row Height"
              onClick={(event: React.MouseEvent) =>
                setRowHeightMenu({
                  open: true,
                  top: `${event.currentTarget.getBoundingClientRect().bottom + 10}px`,
                  left: `${event.currentTarget.getBoundingClientRect().left - rowHeightWidth + 23}px`,
                  right: 'auto',
                  bottom: 'auto'
                })
              }
            >
              {spreadsheetData.viewDetails.rowHeight === rowHeights.Small && <SmallRowHeight />}
              {spreadsheetData.viewDetails.rowHeight === rowHeights.Medium && <MediumRowHeight />}
              {spreadsheetData.viewDetails.rowHeight === rowHeights.Tall && <TallRowHeight />}
              {spreadsheetData.viewDetails.rowHeight === rowHeights['Extra Tall'] && <ExtraTallRowHeight />}
            </div>
          )}

          {spreadsheetData.viewDetails.type === ViewTypes.SPREADSHEET && (
            <div
              data-testid="search-menu"
              title="Search"
              className={
                isStreaming
                  ? 'toolbar-button-disabled'
                  : spreadsheetData.searchTerm !== ''
                  ? 'toolbar-button-active'
                  : 'toolbar-button'
              }
              onClick={(event: React.MouseEvent) => {
                if (isStreaming) {
                  return
                }
                setSearchMenu({
                  open: true,
                  top: `${event.currentTarget.getBoundingClientRect().bottom + 10}px`,
                  left: `${event.currentTarget.getBoundingClientRect().left - searchWidth + 20}px`,
                  right: 'auto',
                  bottom: 'auto'
                })
              }}
            >
              <Search />
            </div>
          )}

          {!fullScreen && spreadsheetData.processId && (
            <div className="toolbar-button" onClick={() => setFullScreen(true)}>
              <Expand />
            </div>
          )}

          {fullScreen && (
            <div className="toolbar-button" onClick={() => setFullScreen(false)}>
              <Cross />
            </div>
          )}

          {spreadsheetData.isAdmin && (
            <div
              data-testid="save-menu"
              title="Save"
              className={
                spreadsheetData.userConfiguration.unsavedChanges
                  ? 'toolbar-button-pulsate-background'
                  : 'toolbar-button'
              }
              onClick={(event: React.MouseEvent) =>
                setSaveMenu({
                  open: true,
                  top: `${event.currentTarget.getBoundingClientRect().bottom + 10}px`,
                  left: `${event.currentTarget.getBoundingClientRect().left - saveWidth + 20}px`,
                  right: 'auto',
                  bottom: 'auto'
                })
              }
            >
              <Save />
            </div>
          )}

          <div
            className="toolbar-button"
            title="Settings"
            onClick={(event: React.MouseEvent) =>
              setSettingsMenu({
                open: true,
                top: `${event.currentTarget.getBoundingClientRect().bottom + 10}px`,
                left: `${event.currentTarget.getBoundingClientRect().left - settingsWidth + 20}px`,
                right: 'auto',
                bottom: 'auto'
              })
            }
          >
            <Dots />
          </div>
        </div>

        <ViewMenu
          id={`view-context-menu-${uniqueNumber}`}
          menuState={viewMenu}
          setMenuState={setViewMenu}
          width={viewWidth}
        />

        {colourMenu.open && (
          <ColourMenu
            id={`colour-context-menu-${uniqueNumber}`}
            menuState={colourMenu}
            setMenuState={setColourMenu}
            width={colourWidth}
          />
        )}

        {filterMenu.open && (
          <FilterMenu
            id={`filter-context-menu-${uniqueNumber}`}
            menuState={filterMenu}
            setMenuState={setFilterMenu}
            width={filterWidth}
          />
        )}

        {sortMenu.open && (
          <SortMenu
            id={`sort-context-menu-${uniqueNumber}`}
            menuState={sortMenu}
            setMenuState={setSortMenu}
            width={sortWidth}
          />
        )}

        {groupMenu.open && (
          <GroupMenu
            id={`group-context-menu-${uniqueNumber}`}
            menuState={groupMenu}
            setMenuState={setGroupMenu}
            width={groupWidth}
            handleToggleCollapseAll={handleToggleCollapseAll}
          />
        )}

        {hideMenu.open && (
          <SelectMenu
            id={`hide-context-menu-${uniqueNumber}`}
            menuState={hideMenu}
            setMenuState={setHideMenu}
            width={hideWidth}
            options={allColumns.map((column) => {
              return { value: column.publicId, label: column.name }
            })}
            onOptionClick={(option) => {
              const isHidden = spreadsheetData.userConfiguration.hiddenColumns.includes(option)
              if (!isHidden) {
                hideColumn(option)
              } else {
                showColumn(option)
              }
            }}
            multiselect={true}
            optionsSelected={allColumns
              .filter((column) => spreadsheetData.userConfiguration.hiddenColumns.includes(column.publicId))
              .map((column) => column.publicId)}
            setOptionsSelected={(options) => {
              for (const column of allColumns) {
                if (options.includes(column.publicId)) {
                  hideColumn(column.publicId)
                } else {
                  showColumn(column.publicId)
                }
              }
            }}
            customAddText={'Hide'}
            customRemoveText={'Show'}
            switchColours={true}
          />
        )}

        {lockMenu.open && (
          <SelectMenu
            id={`lock-context-menu-${uniqueNumber}`}
            menuState={lockMenu}
            setMenuState={setLockMenu}
            width={lockWidth}
            options={visibleColumns.map((column) => {
              return { value: column.publicId, label: column.name }
            })}
            onOptionClick={(option) => {
              const column = spreadsheetData.viewDetails.columns.find((column) => column.publicId === option)
              if (column) {
                if (column.locked || column.isJoined) {
                  unlockColumn(option)
                } else {
                  lockColumn(option)
                }
              }
            }}
            multiselect={true}
            optionsSelected={spreadsheetData.viewDetails.columns
              .filter((column) => column.isJoined || column.locked)
              .map((column) => column.publicId)}
            setOptionsSelected={(options) => {
              for (const column of spreadsheetData.viewDetails.columns) {
                if (options.includes(column.publicId)) {
                  lockColumn(column.publicId)
                } else {
                  unlockColumn(column.publicId)
                }
              }
            }}
            customAddText={'Lock'}
            customRemoveText={'Unlock'}
          />
        )}

        {requiredMenu.open && (
          <SelectMenu
            id={`required-context-menu-${uniqueNumber}`}
            menuState={requiredMenu}
            setMenuState={setRequiredMenu}
            width={requiredWidth}
            options={visibleColumns.map((column) => {
              return { value: column.publicId, label: column.name }
            })}
            onOptionClick={(option) => {
              const column = spreadsheetData.viewDetails.columns.find((column) => column.publicId === option)
              if (column) {
                if (column.required) {
                  unrequireColumn(option)
                } else {
                  requireColumn(option)
                }
              }
            }}
            multiselect={true}
            optionsSelected={spreadsheetData.viewDetails.columns
              .filter((column) => column.required)
              .map((column) => column.publicId)}
            setOptionsSelected={(options) => {
              if (options.length !== 0) {
                setSpreadsheetData({ type: 'REQUIRE_ALL_COLUMNS' })
              } else {
                setSpreadsheetData({ type: 'UNREQUIRE_ALL_COLUMNS' })
              }
            }}
            customAddText={'Require'}
            customRemoveText={'Unrequire'}
          />
        )}

        {columnOrderMenu.open && (
          <ColumnOrderMenu
            id={`column-order-context-menu-${uniqueNumber}`}
            menuState={columnOrderMenu}
            setMenuState={setColumnOrderMenu}
            width={columnOrderWidth}
          />
        )}

        {freezeMenu.open && (
          <FreezeMenu
            id={`freeze-context-menu-${uniqueNumber}`}
            menuState={freezeMenu}
            setMenuState={setFreezeMenu}
            width={freezeWidth}
          />
        )}

        {rowHeightMenu.open && (
          <RowHeightMenu
            id={`row-height-context-menu-${uniqueNumber}`}
            menuState={rowHeightMenu}
            setMenuState={setRowHeightMenu}
            width={rowHeightWidth}
          />
        )}

        {saveMenu.open && (
          <SaveMenu
            id={`save-context-menu-${uniqueNumber}`}
            menuState={saveMenu}
            setMenuState={setSaveMenu}
            width={saveWidth}
            setDuplicateViewModal={(open: boolean) => {
              setDuplicateViewModal(open)
              if (open) setSettingsMenu(INITIAL_CONTEXT_MENU_STATE)
            }}
          />
        )}

        {searchMenu.open && (
          <SearchMenu
            id={`search-context-menu-${uniqueNumber}`}
            menuState={searchMenu}
            setMenuState={setSearchMenu}
            width={searchWidth}
            columns={columns}
          />
        )}

        {importMenu.open && (
          <ImportMenu
            id={`import-context-menu-${uniqueNumber}`}
            menuState={importMenu}
            setMenuState={setImportMenu}
            width={importWidth}
            setAppendCSVModal={setAppendCSVModal}
            setViewpointModal={setViewpointModal}
            setViewpointRfisModal={setViewpointRfisModal}
            setBIM360Modal={setBIM360Modal}
            setBIM360ChecklistsModal={setBIM360ChecklistsModal}
            setBIM360IssuesModal={setBIM360IssuesModal}
            setBIM360ModelsModal={setBIM360ModelsModal}
            setBIM360UsersModal={setBIM360UsersModal}
            setProcoreModal={setProcoreModal}
            setAconexModal={setAconexModal}
            setAconexWorkflowsModal={setAconexWorkflowsModal}
            setMortaModal={openMortaModal}
            setReviztoModal={setReviztoModal}
            setAsiteDocumentsModal={setAsiteDocumentsModal}
            setAsiteFormsModal={setAsiteFormsModal}
          />
        )}

        {permissionsModal && (
          <PermissionsModal
            id="spreadsheet-permissions-modal"
            open={permissionsModal}
            setOpen={setPermissionsModal}
            onClose={() => setSettingsMenu(INITIAL_CONTEXT_MENU_STATE)}
          />
        )}

        {settingsMenu.open && (
          <SettingsMenu
            id={`settings-context-menu-${uniqueNumber}`}
            menuState={settingsMenu}
            setMenuState={setSettingsMenu}
            width={settingsWidth}
            setDeleteTableModal={(open: boolean) => {
              setDeleteTableModal(open)
              if (open) setSettingsMenu(INITIAL_CONTEXT_MENU_STATE)
            }}
            setDeleteViewModal={(open: boolean) => {
              setDeleteViewModal(open)
              if (open) setSettingsMenu(INITIAL_CONTEXT_MENU_STATE)
            }}
            setJoinsModal={(open: boolean) => {
              setJoinsModal(open)
              if (open) setSettingsMenu(INITIAL_CONTEXT_MENU_STATE)
            }}
            setDuplicateTableModal={(open: boolean) => {
              setDuplicateTableModal(open)
              if (open) setSettingsMenu(INITIAL_CONTEXT_MENU_STATE)
            }}
            setAuditModal={(open: boolean) => {
              setAuditModal(open)
              if (open) setSettingsMenu(INITIAL_CONTEXT_MENU_STATE)
            }}
            setPermissionsModal={(open: boolean) => {
              setPermissionsModal(open)
              if (open) setSettingsMenu(INITIAL_CONTEXT_MENU_STATE)
            }}
            setRelatedModal={(open: boolean) => {
              setRelatedModal(open)
              if (open) setSettingsMenu(INITIAL_CONTEXT_MENU_STATE)
            }}
            setViewDuplicatesModal={(open: boolean) => {
              setViewDuplicatesModal(open)
              if (open) setSettingsMenu(INITIAL_CONTEXT_MENU_STATE)
            }}
            setBackupModal={(open: boolean) => {
              setBackupModal(open)
              if (open) setSettingsMenu(INITIAL_CONTEXT_MENU_STATE)
            }}
          />
        )}

        {chartMenu.open && (
          <ChartMenu
            id={`chart-context-menu-${uniqueNumber}`}
            menuState={chartMenu}
            setMenuState={setChartMenu}
            width={chartWidth}
          />
        )}

        {auditModal && (
          <AuditModal
            id="spreadsheet-audit-modal"
            open={auditModal}
            setOpen={setAuditModal}
            onClose={() => {
              setSettingsMenu(INITIAL_CONTEXT_MENU_STATE)
            }}
          />
        )}
        {deleteTableModal && (
          <DeleteResource
            id="delete-table-modal"
            open={deleteTableModal}
            setOpen={setDeleteTableModal}
            resourceName={spreadsheetData.tableDetails.name}
            onClose={() => {
              setSettingsMenu(INITIAL_CONTEXT_MENU_STATE)
            }}
            deleteResource={() => handleDeleteTable()}
            otherContent={
              <div style={{ marginTop: '20px' }}>
                Any select columns within{' '}
                <span className="font-semibold">{`${spreadsheetData.tableDetails.name}`}</span>{' '}
                <span>will be changed to</span> <span className="font-semibold">Text</span> <span>type</span>. All{' '}
                <span className="font-semibold">join columns will also be removed</span>. If the table is restored, you
                will have to configure these columns again.
              </div>
            }
          />
        )}
        {deleteViewModal && (
          <DeleteResource
            id="delete-view-modal"
            open={deleteViewModal}
            setOpen={setDeleteViewModal}
            resourceName={spreadsheetData.viewDetails.name}
            onClose={() => {
              setSettingsMenu(INITIAL_CONTEXT_MENU_STATE)
            }}
            deleteResource={() => handleDeleteView()}
            otherContent={
              <div style={{ marginTop: '20px' }}>
                If you delete this view, the table data will be preserved, but all view information (filters, sortings,
                groupings etc.) will be deleted.
              </div>
            }
          />
        )}

        {joinsModal && <TableJoinsModal id="table-joins-modal" open={joinsModal} setOpen={setJoinsModal} />}
        {relatedModal && (
          <Related
            open={relatedModal}
            setOpen={setRelatedModal}
            type="table"
            publicId={spreadsheetData.tableDetails.publicId}
          />
        )}
        {viewDuplicatesModal && (
          <DuplicateChildren
            id="view-duplicates-modal"
            open={viewDuplicatesModal}
            setOpen={setViewDuplicatesModal}
            publicId={spreadsheetData.tableDetails.publicId}
            type="table"
          />
        )}
        {backupModal && <BackupModal id="backup-modal" open={backupModal} setOpen={setBackupModal} />}
        {appendCSVModal && <AppendCSVModal id="append-csv-modal" open={appendCSVModal} setOpen={setAppendCSVModal} />}
        {viewpointModal && (
          <IntegrationModal
            id="viewpoint-modal"
            open={viewpointModal}
            setOpen={setViewpointModal}
            provider={IntegrationProviders.VIEWPOINT}
            integrationName="Viewpoint"
            dataType="Documents"
          />
        )}
        {viewpointRfisModal && (
          <IntegrationModal
            id="viewpoint-rfis-modal"
            open={viewpointRfisModal}
            setOpen={setViewpointRfisModal}
            provider={IntegrationProviders.VIEWPOINT}
            integrationName="Viewpoint"
            dataType="RFIs"
          />
        )}
        {bim360Modal && (
          <IntegrationModal
            id="bim360-modal"
            open={bim360Modal}
            setOpen={setBIM360Modal}
            provider={IntegrationProviders.AUTODESK_BIM360}
            integrationName="ACC/BIM 360"
            dataType="Documents"
          />
        )}
        {bim360ChecklistsModal && (
          <IntegrationModal
            id="bim360-checklists-modal"
            open={bim360ChecklistsModal}
            setOpen={setBIM360ChecklistsModal}
            provider={IntegrationProviders.AUTODESK_BIM360}
            integrationName="ACC/BIM 360"
            dataType="Checklists"
          />
        )}
        {bim360IssuesModal && (
          <IntegrationModal
            id="bim360-issues-modal"
            open={bim360IssuesModal}
            setOpen={setBIM360IssuesModal}
            provider={IntegrationProviders.AUTODESK_BIM360}
            integrationName="ACC/BIM 360"
            dataType="Issues"
          />
        )}
        {bim360ModelsModal && (
          <IntegrationModal
            id="bim360-models-modal"
            open={bim360ModelsModal}
            setOpen={setBIM360ModelsModal}
            provider={IntegrationProviders.AUTODESK_BIM360}
            integrationName="ACC/BIM 360"
            dataType="AEC Data Model"
          />
        )}
        {bim360UsersModal && (
          <IntegrationModal
            id="bim360-models-modal"
            open={bim360UsersModal}
            setOpen={setBIM360UsersModal}
            provider={IntegrationProviders.AUTODESK_BIM360}
            integrationName="ACC/BIM 360"
            dataType="Users"
          />
        )}
        {procoreModal && (
          <IntegrationModal
            id="procore-modal"
            open={procoreModal}
            setOpen={setProcoreModal}
            provider={IntegrationProviders.PROCORE}
            integrationName="Procore"
            dataType="Documents"
          />
        )}
        {aconexModal && (
          <IntegrationModal
            id="aconex-modal"
            open={aconexModal}
            setOpen={setAconexModal}
            provider={IntegrationProviders.ACONEX}
            integrationName="Aconex"
            dataType="Documents"
          />
        )}
        {aconexWorkflowsModal && (
          <IntegrationModal
            id="aconex-workflows-modal"
            open={aconexWorkflowsModal}
            setOpen={setAconexWorkflowsModal}
            provider={IntegrationProviders.ACONEX}
            integrationName="Aconex Workflows"
            dataType="Workflows"
          />
        )}
        {reviztoModal && (
          <IntegrationModal
            id="revizto-modal"
            open={reviztoModal}
            setOpen={setReviztoModal}
            provider={IntegrationProviders.REVIZTO}
            integrationName="Revizto"
            dataType="Issues"
          />
        )}
        {asiteDocumentsModal && (
          <IntegrationModal
            id="asite-modal"
            open={asiteDocumentsModal}
            setOpen={setAsiteDocumentsModal}
            provider={IntegrationProviders.ASITE}
            integrationName="Asite"
            dataType="Documents"
          />
        )}
        {asiteFormsModal && (
          <IntegrationModal
            id="asite-modal"
            open={asiteFormsModal}
            setOpen={setAsiteFormsModal}
            provider={IntegrationProviders.ASITE}
            integrationName="Asite"
            dataType="Forms"
          />
        )}
        {mortaModal && mortaSyncType && (
          <IntegrationModal
            id="morta-modal"
            open={mortaModal}
            setOpen={setMortaModal}
            provider={IntegrationProviders.MORTA}
            integrationName="Morta"
            dataType={mortaSyncType}
          />
        )}
        {duplicateTableModal && (
          <Duplicate
            id={`duplicate-table-${uniqueNumber}`}
            open={duplicateTableModal}
            setOpen={setDuplicateTableModal}
            type="table"
            publicId={spreadsheetData.tableDetails.publicId}
          />
        )}
        {duplicateViewModal && (
          <Duplicate
            id={`duplicate-view-${uniqueNumber}`}
            open={duplicateViewModal}
            setOpen={setDuplicateViewModal}
            type="view"
            publicId={spreadsheetData.viewDetails.publicId}
            parentPublicId={spreadsheetData.tableDetails.publicId}
            spreadsheetData={spreadsheetData}
            setSpreadsheetData={setSpreadsheetData}
          />
        )}
      </div>
    )
  } else {
    return (
      <div
        className="flex w-full items-center box-border select-none border-b-1px border-solid border-grey overflow-x-auto overflow-y-hidden"
        style={{ padding: '5px', height: `${Number.parseInt(constants.toolbarHeight)}px` }}
      >
        <div className="toolbar-button">
          <div className="spin" style={{ height: iconHeight, width: iconHeight }} />
        </div>

        <div className="toolbar-button">Loading data</div>
      </div>
    )
  }
}

export default React.memo(Toolbar)
