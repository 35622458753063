import { IUserObject } from 'types'

const ALLOWED_SCRIPT_USERS = [
  'macegroup.com',
  'digital-guerrilla.scot',
  'mclh.co.uk',
  'mottmac.com',
  'johnpaul.ie',
  'archirodon.net',
  'uk.rlb.com',
  'bouygues-uk.com',
  'bouyguestp.co.uk',
  'tetratech.com',
  'riesen-ing.ch',
  'kier.co.uk',
  'morta.io',
  'okana.global',
  'alec.ae',
  'kanegroup.co.uk',
  'graham.co.uk',
  'srm.com',
  'operance.app',
  'morgansindall.com'
]

export const userAllowedScripts = (user?: IUserObject) => {
  if (!user) return false

  for (let i = 0; i < ALLOWED_SCRIPT_USERS.length; i++) {
    if (user.email.includes(ALLOWED_SCRIPT_USERS[i])) {
      return true
    }
  }

  return false
}
